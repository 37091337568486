/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ActionIcon, Autocomplete, Box, Button, Divider, Group, Select, Text, TextInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { createReference, normalizeErrorString } from '@medplum/core';
import { Appointment, Practitioner, Schedule, Slot } from '@medplum/fhirtypes';
import { useMedplum, useMedplumProfile } from '@medplum/react';
import {
  IconChevronLeft,
  IconChevronRight,
  IconCircleCheck,
  IconCircleOff,
  IconEdit,
  IconTrash,
} from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { Event } from 'react-big-calendar';
import { CreateUpdateSlot } from './CreateUpdateSlot';
import PatientPractitionerSelect from '../../../components/PatientPractitionerSelect';
import { AppointmentTypes } from '../../../utils/constant';
import { bookSlot } from '../../../utils/CustomAPI';
import { DateInput } from '@mantine/dates';
import Slots from '../../../components/Slots';
import { SelectedSlot } from '../../../utils/interface';
import dayjs from 'dayjs';

interface CreateAppointmentProps {
  event: Event | undefined;
  readonly onAppointmentsUpdated?: () => void;
  readonly handlers?: {
    readonly open: () => void;
    readonly close: () => void;
    readonly toggle: () => void;
  };
  schedule: Schedule;
  onSlotsUpdated?: () => void;
  closeDialog?: () => void;
}

interface AppointmentFormData {
  patientRef: { value: string; label: string } | undefined;
  appointmentType: string;
  serviceType: string;
  comment: string;
  startDate: string;
}

export function CreateAppointment(props: CreateAppointmentProps): JSX.Element | null {
  const { event, handlers, onAppointmentsUpdated = () => {}, schedule, onSlotsUpdated, closeDialog } = props;
  const slot: Slot | undefined = event?.resource;
  const [updateSlotOpened, updateSlotHandlers] = useDisclosure(false, { onClose: handlers?.close });
  const medplum = useMedplum();
  const profile = useMedplumProfile() as Practitioner;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedSlot, setSelectedSlot] = useState<SelectedSlot | undefined>();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  useEffect(() => {
    if (slot?.id) {
      setSelectedSlot({
        id: slot.id,
        time: `${slot.start} - ${slot.end}`,
        startTime: slot.start,
        endTime: slot.end,
      });
    }
  }, [slot]);

  // State for form data
  const [formData, setFormData] = useState<AppointmentFormData>({
    patientRef: undefined,
    appointmentType: 'ROUTINE',
    serviceType: 'Telehealth',
    comment: '',
    startDate: slot?.start ?? '', // Set default start date from slot
  });

  const handleResourceSelect = (resource: { value: string; label: string } | undefined): void => {
    setFormData((prev) => ({ ...prev, patientRef: resource }));
  };

  async function handleDeleteSlot(slotId: string): Promise<void> {
    try {
      setIsDeleting(true);
      await medplum.deleteResource('Slot', slotId);
      onAppointmentsUpdated?.();
      showNotification({
        title: 'Success',
        message: 'Slot deleted',
      });
      setIsDeleting(false);

      if (onSlotsUpdated && closeDialog){
        onSlotsUpdated();
        closeDialog();
      }

      handlers?.close();
    } catch (err) {
      showNotification({
        color: 'red',
        title: 'Error',
        message: normalizeErrorString(err),
      });
      setIsDeleting(false);
    }
  }

  const onSelectSlot = (slot: SelectedSlot) => {
    setSelectedSlot({
      ...slot,
    });
  };

  async function handleSubmit(): Promise<void> {
    if (!formData.patientRef) {
      showNotification({
        color: 'red',
        message: 'Please select a patient',
      });
      return;
    }

    if (!formData.appointmentType || !formData.serviceType || !formData.startDate) {
      showNotification({
        color: 'red',
        message: 'Please fill in all required fields',
      });
      return;
    }

    if (!selectedSlot) {
      showNotification({
        color: 'red',
        message: 'Please select a slot',
      });
      return;
    }

    if (selectedSlot && new Date(selectedSlot.startTime) < new Date()) {
      showNotification({
        color: 'red',
        message: 'Cannot book appointments in the past',
      });
      return;
    }

    try {
      setIsLoading(true);

      const appointment: Appointment = {
        resourceType: 'Appointment',
        status: 'booked',
        start: selectedSlot.startTime,
        end: selectedSlot.endTime,
        slot: [
          {
            reference: `Slot/${selectedSlot.id}`,
          },
        ],
        description: formData.comment,
        appointmentType: {
          coding: [
            {
              system: 'http://terminology.hl7.org/CodeSystem/v2-0276',
              code: formData.appointmentType,
              display: AppointmentTypes.find((t) => t.value === formData.appointmentType)?.label,
            },
          ],
        },
        serviceType: [
          {
            coding: [
              {
                system: 'http://hl7.org/fhir/ValueSet/service-type',
                code: formData.serviceType,
                display: formData.serviceType,
              },
            ],
          },
        ],
        participant: [
          {
            actor: {
              reference: `Patient/${formData.patientRef.value}`,
              display: formData.patientRef.label,
            },
            status: 'accepted',
          },
          {
            actor: createReference(profile),
            status: 'accepted',
          },
        ],
        comment: formData.comment,
      };

      // Call bot to create the appointment
      // appointment = await medplum.executeBot({ system: 'http://example.com', value: 'book-appointment' }, appointment);
      const payload = { appointment };
      await bookSlot(medplum, payload);
      onAppointmentsUpdated();
      setIsLoading(false);

      if (onSlotsUpdated && closeDialog){
        onSlotsUpdated();
        closeDialog();
      }

      showNotification({
        icon: <IconCircleCheck />,
        title: 'Success',
        message: 'Appointment created',
      });
    } catch (err) {
      setIsLoading(false);
      showNotification({
        color: 'red',
        icon: <IconCircleOff />,
        message: normalizeErrorString(err),
      });
      setIsLoading(false);
    }

    handlers?.close();
  }

  // Inside CreateAppointment component:
  const handleDateChange = (direction: 'prev' | 'next'): void => {
    const currentDate = formData.startDate ? new Date(formData.startDate) : new Date();
    const newDate = dayjs(currentDate)
      .add(direction === 'next' ? 1 : -1, 'day')
      .toDate();
    setFormData((prev) => ({
      ...prev,
      startDate: newDate.toISOString(),
    }));
  };

  return (
    <>
      {event && (
        <Group justify="flex-end" mb={'1rem'}>
          <ActionIcon
            variant="outline"
            size={33}
            className="button-icon"
            onClick={() => {
              handlers?.close();
              updateSlotHandlers.open();
            }}
          >
            <IconEdit size={20} stroke={2.2} />
          </ActionIcon>
          <ActionIcon
            variant="subtle"
            size={33}
            color="red"
            className="button-icon"
            loading={isDeleting}
            onClick={() => handleDeleteSlot(slot?.id as string)}
          >
            <IconTrash size={20} stroke={2.2} />
          </ActionIcon>
        </Group>
      )}

      <Box>
        <Box mb={20}>
          <PatientPractitionerSelect onResourceSelect={handleResourceSelect} />
        </Box>

        <Box className="flex-container">
          <Text span w={'35%'} className="title-txt">
            Title (Optional)
          </Text>
          <TextInput
            placeholder="Enter a title"
            className="meeting-title-input-box"
            value={formData.comment}
            onChange={(e) => setFormData((prev) => ({ ...prev, comment: e.currentTarget.value }))}
            mb={12}
          />
        </Box>

        <Box className="flex-container">
          <Text span w={'35%'} className="title-txt">
            Appointment Type
            <Text span c={'red'}>
              {' '}
              *
            </Text>
          </Text>
          <Select
            placeholder="Select an appointment type"
            className="meeting-title-input-box"
            defaultValue="routine appointment"
            defaultChecked
            data={AppointmentTypes}
            value={formData.appointmentType}
            onChange={(value) => setFormData((prev) => ({ ...prev, appointmentType: value || '' }))}
            mb={12}
          />
        </Box>

        <Box className="flex-container">
          <Text span w={'35%'} className="title-txt">
            Appointment Service Type
            <Text span c={'red'}>
              {' '}
              *
            </Text>
          </Text>
          <Autocomplete
            placeholder="Select an appointment service type"
            className="meeting-title-input-box"
            data={['Telehealth']}
            value={formData.serviceType || ''}
            onChange={(value) => {
              setFormData((prev) => ({
                ...prev,
                serviceType: value === null ? '' : value,
              }));
            }}
            mb={12}
          />
        </Box>

        <Box className="flex-container date-picker-container">
          <Text span w={'35%'} className="title-txt">
            Date Of Appointment{' '}
            <Text span c={'red'}>
              {' '}
              *
            </Text>
          </Text>
          <DateInput
            leftSection={
              <Button bg={'#e0f5f5'} p={0} h={'1.7rem'} w={'1.7rem'} title="Previous Day">
                <ActionIcon variant="transparent" onClick={() => handleDateChange('prev')}>
                  <IconChevronLeft size={15} stroke={2.2} color="var(--btn-color)" />
                </ActionIcon>
              </Button>
            }
            placeholder="Date of appointment"
            className="meeting-title-input-box"
            mb={12}
            value={formData.startDate ? new Date(formData.startDate) : undefined}
            defaultValue={slot?.start ? new Date(slot.start) : undefined}
            onChange={(value) =>
              setFormData((prev) => ({
                ...prev,
                startDate: value ? value.toISOString() : '',
              }))
            }
            minDate={new Date()}
            rightSection={
              <Button bg={'#e0f5f5'} p={0} h={'1.7rem'} w={'1.7rem'} title="Next Day">
                <ActionIcon variant="transparent" onClick={() => handleDateChange('next')}>
                  <IconChevronRight size={15} stroke={2.2} color="var(--btn-color)" />
                </ActionIcon>
              </Button>
            }
          />
        </Box>

        {(selectedSlot?.id || formData.startDate) && (
          <Slots
            onSelectSlot={onSelectSlot}
            startDate={formData?.startDate || slot?.start || ''}
            formStartDate={formData.startDate}
            slotError={false}
            currentSlotId={selectedSlot?.id}
          />
        )}
      </Box>
      <Divider my={'2rem'} />
      <Group justify="flex-end" mt={20}>
        <Button
          size="md"
          loading={isLoading}
          onClick={handleSubmit}
          bg="var(--btn-color)"
          radius={'8px'}
          variant="filled"
          w={'40%'}
        >
          Book Appointment
        </Button>
      </Group>
      <CreateUpdateSlot
        event={event}
        opened={updateSlotOpened}
        handlers={updateSlotHandlers}
        onSlotsUpdated={onAppointmentsUpdated}
        schedule={schedule}
      />
    </>
  );
}
