/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Badge, Skeleton, Text } from '@mantine/core';
import { IconCalendar, IconMessageCircleQuestion } from '@tabler/icons-react';
import { QuestionnaireResponse } from '@medplum/fhirtypes';
import { formatDate } from '../../utils/util';

interface AssessmentQuestionsProps {
  questionnaireResponse: QuestionnaireResponse | undefined;
  loading?: boolean;
  assessmentName?: string;
}

const AssessmentPreview = (props: AssessmentQuestionsProps) => {
  const {questionnaireResponse, loading } = props;
  return (
    <div>
      {questionnaireResponse ? (
        <>
          <div className="tw-mt-3 tw-p-2 tw-rounded-lg tw-py-3 tw-flex tw-gap-3 tw-opacity-[1] tw-justify-between">
            <div className="tw-flex tw-gap-3">
              <div>
                <img src="/img/assessment.svg" alt="assessment" className="tw-w-16 tw-h-16" />
              </div>
              <div>
                <Text className="tw-text-[17px]" c="#334054" fw={600} mb={2}>
                  {props.assessmentName || 'Assessment'}
                </Text>
                <Text className="tw-flex tw-item-center tw-gap-1 tw-text-[12px]" span c="#475467">
                  <IconCalendar size={15} />
                  {questionnaireResponse?.authored ? formatDate(new Date(questionnaireResponse.authored)) : '-'}
                </Text>
              </div>
            </div>
            <div className="tw-bg-[#fff] tw-p-2 tw-px-5 tw-rounded-lg">
              <Text ta="center" c="#475467" className="tw-text-[13px] tw-mb-1">
                Status
              </Text>
              <Badge variant="light" color="#067647" className="tw-p-2 tw-py-3 tw-font-medium">
                <span
                  style={{
                    display: 'inline-block',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: '#17B26A',
                    marginRight: '8px',
                  }}
                ></span>
                {questionnaireResponse?.status}
              </Badge>
            </div>
          </div>
          <div className="tw-p-2 tw-mt-3">
            <div className="tw-flex tw-gap-3 tw-items-center">
              <div className=" tw-p-2 tw-rounded-full">
                <IconMessageCircleQuestion color='#2aaaaf' />
              </div>
              <Text
                className="tw-font-bold tw-text-base"
                style={{
                  color: '#2aaaaf',
                }}
              >
                Questions
              </Text>

              <div className="tw-border tw-border-[#D0D5DD] tw-rounded-md tw-px-2 tw-py-1 tw-text-xs tw-text-[#344054] tw-font-medium">
                Total { questionnaireResponse?.item?.length || 0}
              </div>
            </div>
          </div>
          <div>
            {questionnaireResponse?.item?.map((item, index) => (
              <div key={index} className="tw-border tw-border-[#D0D5DD] tw-p-4 tw-rounded-lg tw-mt-3">
                <div className="tw-mb-3 tw-flex tw-gap-4">
                  <span className="tw-relative">
                    <span style={{ backgroundColor: '#4db5ba' }} className="tw-flex tw-items-center tw-place-content-center tw-rounded-full tw-text-[#fff] tw-w-8 tw-h-8">
                      {index + 1}
                    </span>
                  </span>
                  <span>{item.text}</span>
                </div>
                <div className="tw-px-12">
                  <span className="tw-text-[#485467] tw-text-[13px]">{item?.answer?.[0].valueString}</span>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : loading ? (
        <>
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
        </>
      ) : (
        <Text ta="center" size="lg" mt={20}>
          {' '}
          No Assessment{' '}
        </Text>
      )}
    </div>
  );
};

export default AssessmentPreview;
