/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ActionIcon, Group, Tooltip } from '@mantine/core';
import { IconCheckbox, IconCopy, IconRotateClockwise, IconThumbDown, IconThumbDownFilled, IconThumbUp, IconThumbUpFilled, IconVolume } from '@tabler/icons-react';
import React from 'react'

interface ActionButtonsProps {
  item: any;
  likedMessages: Set<string>;
  dislikedMessages: Set<string>;
  copiedMessages: Set<string>;
  isSpeaking: boolean;
  handleLike: (questionId: string, sessionId: string, selectedPatient: string) => void;
  handleDislike: (questionId: string, sessionId: string, selectedPatient: string) => void;
  handleClick: (description: string, questionId: string) => void;
  handleSpeak: (item: any) => void;
  getPDFHTMLData: (e: any, title: string) => void;
}

const ActionButtons = (props: ActionButtonsProps) => {
  const { item, likedMessages, dislikedMessages, copiedMessages, isSpeaking, handleLike, handleDislike, handleClick, handleSpeak } = props;
  return (
    <Group
    mt="md"
    style={{
      display: 'flex',
      justifyContent: 'center',
      border: '1px solid #D5D8DE',
      borderRadius: '6px',
      width: 'fit-content',
      gap: '1px',
      marginLeft: '42px',
      marginBottom: '30px',
    }}
  >
    <Tooltip label={isSpeaking ? 'Stop' : 'Speak'} position="bottom">
      <ActionIcon style={{ backgroundColor: 'white' }} onClick={() => handleSpeak(item)}>
        <IconVolume size={17} color={isSpeaking ? '#2F67AD' : '#7D7D7D'} />
      </ActionIcon>
    </Tooltip>
    <Tooltip label={likedMessages.has(item.questionId || '') ? 'Unlike' : 'Like'} position="bottom">
      <ActionIcon
        style={{ backgroundColor: 'white' }}
        onClick={() =>
          handleLike(item.questionId ?? '', item.sessionId ?? '', item.selectedPatient ?? '')
        }
      >
        {likedMessages.has(item.questionId ?? '') ? (
          <IconThumbUpFilled size={17} color={'var(--primary-color)'} />
        ) : (
          <IconThumbUp size={17} color="#7D7D7D" />
        )}
      </ActionIcon>
    </Tooltip>
    <Tooltip
      label={dislikedMessages.has(item.questionId || '') ? 'Remove Dislike' : 'Dislike'}
      position="bottom"
    >
      <ActionIcon
        style={{ backgroundColor: 'white' }}
        onClick={() =>
          handleDislike(item.questionId ?? '', item.sessionId ?? '', item.selectedPatient ?? '')
        }
      >
        {dislikedMessages.has(item.questionId || '') ? (
          <IconThumbDownFilled size={17} color={'var(--primary-color)'} />
        ) : (
          <IconThumbDown size={17} color="#7D7D7D" />
        )}
      </ActionIcon>
    </Tooltip>
    <Tooltip
      label={copiedMessages.has(item.questionId || '') ? 'Copied' : 'Copy'}
      position="bottom"
    >
      <ActionIcon
        style={{ backgroundColor: 'white' }}
        onClick={() => {
          if (item.description) {
            handleClick(item.description, item.questionId ?? '');
          }
        }}
      >
        {copiedMessages.has(item.questionId ?? '') ? (
          <IconCheckbox size={17} color="#2F67AD" />
        ) : (
          <IconCopy size={17} color="#7D7D7D" />
        )}
      </ActionIcon>
    </Tooltip>
    <Tooltip label="Retry" position="bottom">
      <ActionIcon style={{ backgroundColor: 'white' }}>
        <IconRotateClockwise
          onClick={(e) => props.getPDFHTMLData(e, item.title)}
          size={17}
          color="#7D7D7D"
        />
      </ActionIcon>
    </Tooltip>
  </Group>
  )
}

export default ActionButtons
