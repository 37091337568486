import { Group, Button, Title, Stack, Tabs } from '@mantine/core';
import { ToolbarProps, View } from 'react-big-calendar';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { EventLegend } from './EventLegend';
import { formatDate } from '../../utils/util';
import '../pages/Calendar.css';

export function CustomToolbar({ date, view, onView, onNavigate }: ToolbarProps): JSX.Element {

  const getViewTitle = (): string => {
    const formatOptions: Record<View, string> = {
      month: 'MMMM yyyy',
      week: "'Week of' MMM d, yyyy",
      day: 'EEEE, MMM d, yyyy',
      agenda: 'MMM d, yyyy',
      work_week: "'Week of' MMM d, yyyy",
    };

    return formatDate(date, formatOptions[view]);
  };

  console.log(date, 'date');

  return (
    <Stack pb={7}>
      <Group justify="space-between">
        <Tabs mt="md" variant="pills" value={view} className="tabs" onChange={(value) => onView(value as View)}>
          <Tabs.List w="min-content" mt={'0px'} className="tab-list">
            {['month', 'week', 'day', 'agenda'].map((viewType) => (
              <Tabs.Tab key={viewType} value={viewType} className="tab-inline-style">
                {viewType.charAt(0).toUpperCase() + viewType.slice(1)}
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </Tabs>

        <Group gap="sm">
          <Title order={2} size="sm">
            {getViewTitle()}
          </Title>
          <Group gap={4}>
            <Button variant="subtle" size="sm" p={6} onClick={() => onNavigate('PREV')}>
              <IconChevronLeft color="var(--btn-color)" size={16} />
            </Button>
            <Button variant="subtle" size="sm" p={6} onClick={() => onNavigate('NEXT')}>
              <IconChevronRight color="var(--btn-color)" size={16} />
            </Button>
          </Group>
          <Button variant="filled" bd=' var(--btn-color)' color='var(--btn-color)' c='var( --main-white)' size="sm" onClick={() => onNavigate('TODAY')}>
            Today
          </Button>{' '}
        </Group>
      </Group>
      <EventLegend />
    </Stack>
  );
}
