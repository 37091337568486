/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { createContext, useContext, ReactNode, useState } from 'react';
import { FhirBotDetail } from './dashboard/AIAssistant/interfaces';
import { Notification } from './utils/interface';
import { Appointment } from '@medplum/fhirtypes';

interface AppContextProps {
  transcript: string;
  setTranscript: (value: any) => void;
  isSessionOn: boolean;
  setIsSessionOn: (value: boolean) => void;
  setIsConfirmOpen: (value: boolean) => void;
  isConfirmOpen: boolean;
  setRedirectionUrl: (value: string) => void;
  redirectionUrl: string;
  setHostParticipants: (value: { isHost: boolean; hostName: string }) => void;
  hostParticipants: { isHost: boolean; hostName: string };
  setJoinUser: (value: string) => void;
  joinUser: string;
  setScheduleId: (value: string) => void;
  scheduleId: string;
  setfhirBotDetailsUpdatedUpdated: (value: FhirBotDetail[]) => void;
  fhirBotDetailsUpdated: FhirBotDetail[];
  setSessionId: (value: string) => void;
  sessionId: string;
  notifications: Notification[];
  setNotifications: (value: Notification[]) => void;
  setUnreadNotifications: (value: number) => void;
  unreadNotifications: number;
  setTotalNotifications: (value: number) => void;
  totalNotifications: number;
  isJoinCallDrawerOpen: boolean;
  setIsJoinCallDrawerOpen: (value: boolean) => void;
  selectedAppointment: Appointment | undefined;
  setSelectedAppointment: (value: Appointment | undefined) => void;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [transcript, setTranscript] = useState('');
  const [isSessionOn, setIsSessionOn] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [redirectionUrl, setRedirectionUrl] = useState('');
  const [hostParticipants, setHostParticipants] = useState<{ isHost: boolean; hostName: string }>({
    isHost: false,
    hostName: '',
  });
  const [joinUser, setJoinUser] = useState('');
  const [scheduleId, setScheduleId] = useState('');
  const [fhirBotDetailsUpdated, setfhirBotDetailsUpdatedUpdated] = useState<FhirBotDetail[]>([]);
  const [sessionId, setSessionId] = useState('');
  const [notifications, setNotifications] = useState<any[]>([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [isJoinCallDrawerOpen, setIsJoinCallDrawerOpen] = useState<boolean>(false);
  const [selectedAppointment, setSelectedAppointment] = useState<Appointment | undefined>();

  return (
    <AppContext.Provider
      value={React.useMemo(
        () => ({
          transcript,
          setTranscript,
          isSessionOn,
          setIsSessionOn,
          isConfirmOpen,
          setIsConfirmOpen,
          redirectionUrl,
          setRedirectionUrl,
          hostParticipants,
          setHostParticipants,
          joinUser,
          setJoinUser,
          scheduleId,
          setScheduleId,
          fhirBotDetailsUpdated,
          setfhirBotDetailsUpdatedUpdated,
          sessionId,
          setSessionId,
          notifications,
          setNotifications,
          setUnreadNotifications,
          unreadNotifications,
          setTotalNotifications,
          totalNotifications,
          isJoinCallDrawerOpen,
          setIsJoinCallDrawerOpen,
          selectedAppointment,
          setSelectedAppointment,
        }),
        [
          transcript,
          setTranscript,
          isSessionOn,
          setIsSessionOn,
          isConfirmOpen,
          setIsConfirmOpen,
          redirectionUrl,
          setRedirectionUrl,
          hostParticipants,
          setHostParticipants,
          joinUser,
          setJoinUser,
          scheduleId,
          setScheduleId,
          fhirBotDetailsUpdated,
          setfhirBotDetailsUpdatedUpdated,
          sessionId,
          setSessionId,
          notifications,
          setNotifications,
          setUnreadNotifications,
          unreadNotifications,
          setTotalNotifications,
          totalNotifications,
          isJoinCallDrawerOpen,
          setIsJoinCallDrawerOpen,
          selectedAppointment,
          setSelectedAppointment,
        ]
      )}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
