import { Text } from '@mantine/core';
import { Chat, Identifier, FhirBotDetail } from './interfaces';
import '../AIAssistant/ChatHistory.css';
import { useMedplum } from '@medplum/react';
import { useAppContext } from '../../AppProvider';

interface ChatHistoryProps {
  converstionHistoryList: Chat[];
  selectedSessionId: string | null;
  setSelectedSessionId: (id: string) => void;
  setIsSessionLoading: (loading: boolean) => void;
  setLikeUnlike: (sessionId: string) => void;
  setFhirBotDetails: (details: FhirBotDetail[]) => void;
  setHistoryData: any;
}

const ChatHistory: React.FC<ChatHistoryProps> = ({
  converstionHistoryList,
  selectedSessionId,
  setSelectedSessionId,
  setIsSessionLoading,
  setLikeUnlike,
  setFhirBotDetails,
  setHistoryData,
}) => {
  const medplum = useMedplum();
  const profileId = medplum.getProfile()?.id;
  const { setfhirBotDetailsUpdatedUpdated, setSessionId } = useAppContext();

  function groupedConverstionHistoryList(historyList: Chat[]): { dateLabel: string; records: Chat[] }[] {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const groups: { dateLabel: string; records: Chat[] }[] = [];

    const sortedHistoryList = [...historyList].sort(
      (a, b) => new Date(b.resource.sent).getTime() - new Date(a.resource.sent).getTime()
    );

    sortedHistoryList.forEach((chat) => {
      const sentDate = new Date(chat.resource.sent);
      let dateLabel = sentDate.toLocaleDateString();

      if (sentDate.toDateString() === today.toDateString()) {
        dateLabel = 'Today';
      } else if (sentDate.toDateString() === yesterday.toDateString()) {
        dateLabel = 'Yesterday';
      }

      let group = groups.find((g) => g.dateLabel === dateLabel);
      if (!group) {
        group = { dateLabel, records: [] };
        groups.push(group);
      }
      group.records.push(chat);
    });

    groups.forEach((group) => {
      group.records.sort((a, b) => new Date(b.resource.sent).getTime() - new Date(a.resource.sent).getTime());
    });

    return groups;
  }

  function isJSON(str: string): boolean {
    try {
      JSON.parse(str);
      return true;
    } catch {
      return false;
    }
  }
  
  return (
    <div className="scrollable-container">
      <div className="history-container" style={{ backgroundColor: '#eaf6f791' }}>
        <Text className="history-title">History</Text>
        <div className="subtitle-border">
          <Text className="history-subtitle">Explore your recent history</Text>
        </div>
      </div>

      {groupedConverstionHistoryList(converstionHistoryList).map((group, index) => (
        <div key={index} style={{ width: '100%' }}>
          <div className="date-divider-container">
            <div className="date-divider-left"></div>
            <Text className="date-label">{group.dateLabel}</Text>
            <div className="date-divider-right"></div>
          </div>

          {group.records.map((chat: Chat, index: number) => {
            const sessionId = chat.resource.identifier
              .find((id: Identifier) => id.system === 'http://chatgpt-session-id')
              ?.value.replace(/"/g, '');

            const handleClick = async (): Promise<void> => {
              setIsSessionLoading(true);
              const sessionId = chat.resource.identifier
                .find((id: Identifier) => id.system === 'http://chatgpt-session-id')
                ?.value.replace(/"/g, '');
              setSelectedSessionId(sessionId ?? '');
              setLikeUnlike(sessionId ?? '');

              // Find the matched resource
              const matchedResource = converstionHistoryList.find((chat: any) =>
                chat.resource.identifier.some(
                  (id: any) => id.system === 'http://chatgpt-session-id' && id.value === sessionId
                )
              );

              if (matchedResource) {
                const payloadData = matchedResource.resource.payload || [];
                const updatedData: any[] = [];

                if (Array.isArray(payloadData)) {
                  payloadData.forEach((payloadItem: any) => {
                    if (payloadItem.contentString !== 'NA') {
                      try {
                        let contentData: any = payloadItem; // Default to the original structure
                        let patientName = '';
                        let patientId = profileId;
                        let questionId = payloadItem.id;
                        let question = payloadItem.question;
                        let answer = payloadItem.answer;
                        let detectedIntent = payloadItem.detectedIntent;

                        // Check if `contentString` is a valid JSON string and parse it
                        if (payloadItem.contentString && isJSON(payloadItem.contentString)) {
                          contentData = JSON.parse(payloadItem.contentString);
                          question = contentData.question || question;
                          answer = contentData.answer || answer;
                          questionId = contentData.id || questionId;
                          detectedIntent = contentData.detectedIntent || detectedIntent;

                          // If `answer` is also a JSON string, parse it
                          if (answer && isJSON(answer)) {
                            const parsedAnswer = JSON.parse(answer);
                            patientName = parsedAnswer.patientName || '';
                            patientId = parsedAnswer.patientId || patientId;
                            questionId = parsedAnswer.questionId || questionId;
                          }
                        }

                        updatedData.push({
                          title: question,
                          description: answer,
                          loading: false,
                          detectedIntent: detectedIntent,
                          patientName: patientName,
                          selectedPatient: patientId,
                          questionId: questionId,
                          sessionId: sessionId,
                        });
                      } catch (error) {
                        console.error('Error parsing contentString:', error);
                      }
                    } else {
                      console.log('No valid contentString to parse');
                    }
                  });
                }

                setHistoryData(updatedData);
                setFhirBotDetails(updatedData);
                setfhirBotDetailsUpdatedUpdated(updatedData);
                setSessionId(sessionId ?? '');
                setIsSessionLoading(false);
              }
            };

            return (
              <button
                key={index}
                className={`chat-session-item ${selectedSessionId === sessionId ? 'selected' : ''}`}
                onClick={handleClick}
              >
                <div className="icon-wrapper">
                  <img src="../img/message-dots-circle.svg" alt="icon" />
                </div>
                <div className="text-wrapper">
                  <div className="text-header">
                    <Text className="chat-title">
                      {chat.resource.identifier
                        .find((id) => id.system === 'http://chatgpt-session-title')
                        ?.value.replace(/"/g, '')
                        .substring(0, 30)}
                    </Text>
                  </div>
                </div>
              </button>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default ChatHistory;
