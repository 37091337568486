/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { AppShell, Loading, Logo, useMedplum, useMedplumProfile } from '@medplum/react';
import {
  IconBell,
  IconBrandDatabricks,
  IconCalendar,
  IconCalendarTime,
  IconCircleCheck,
  IconHome,
  IconInvoice,
  IconMail,
  IconMessage,
  IconMicroscope,
  IconNotes,
  IconRobotFace,
  IconUsers,
  IconVideo,
} from '@tabler/icons-react';
import { Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { ResourceCreatePage } from './pages/resource/ResourceCreatePage';
import { HomePage } from './pages/HomePage';
import { OnboardingPage } from './pages/OnboardingPage';
import { SearchPage } from './pages/SearchPage';
import { SignInPage } from './pages/SignInPage';
import Index from './dashboard';
import './index.css';
import './App.css';
import '@mantine/dates/styles.css';
import { ListingPage } from './dashboard/HomePage';
import Patient from './dashboard/PatientResourcePage';
import Telehealth from './dashboard/Telehealth/Telehealth';
import { Space } from '@mantine/core';
import Meeting from './dashboard/Telehealth/Meeting';
import Feedback from './dashboard/Feedback/Feedback';
import { Practitioner, Schedule } from '@medplum/fhirtypes';
import { createReference, getReferenceString } from '@medplum/core';
import { SchedulePage } from './scheduling/pages/SchedulePage';
import { PatientSchedulePage } from './scheduling/pages/PatientSchedulePage';
import { useAppContext } from './AppProvider';
import { PatientChartingPage } from './charting/pages/PatientPage';
import Assessments from './dashboard/Assessments/Assessments';
import FhirBot from './dashboard/AIAssistant/FhirBot';
import Notifications from './dashboard/Notifications/Notifications';
import Messages from './dashboard/Messages/Messages';

interface SidebarMenuProps {
  title: string;
  links: MenuLinks;
}

interface MenuLink {
  name: string;
  target: string;
  subLinks?: MenuLink[];
}

type MenuLinks = MenuLink[];

export function App(): JSX.Element | null {
  const [searchParams] = useSearchParams();
  const [schedule, setSchedule] = useState<Schedule | undefined>();
  const medplum = useMedplum();
  const profile = useMedplumProfile() as Practitioner;
  const { setScheduleId } = useAppContext();

  useEffect(() => {
    if (medplum.isLoading() || !profile) {
      return;
    }

    // Search for a Schedule associated with the logged user,
    // create one if it doesn't exist
    medplum
      .searchOne('Schedule', { actor: getReferenceString(profile) })
      .then((foundSchedule) => {
        if (foundSchedule) {
          setScheduleId(foundSchedule.id ?? '');
          setSchedule(foundSchedule);
        } else {
          medplum
            .createResource({
              resourceType: 'Schedule',
              actor: [createReference(profile)],
              active: true,
            })
            .then((newSchedule: Schedule) => {
              setScheduleId(newSchedule.id ?? '');
              setSchedule(newSchedule);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [medplum, profile]);
  const menus = [
    {
      title: '',
      links: [
        { name: 'Dashboard', target: '/Dashboard' },
        { name: 'Waiting List', target: '/WaitingList' },
        { name: 'Patients', target: '/Patient' },
        { name: 'Messages', target: 'Communication' },
        {
          name: 'Telehealth',
          subLinks: [
            { name: 'AppointmentList', target: '/Telehealth' },
            { name: 'My Schedule', target: '/Schedule' },
            { name: 'Feedback', target: '/feedback' },
          ],
          target: '',
        },
        {name: 'Assessments', target: '/Assessments'},
        { name: 'AI Assistant', target: '/ai-assistant' },
        { name: 'Notifications', target: '/Notifications' },
        { name: 'Lab Tests', target: '#' },
        { name: 'Invoices', target: '#' },
        { name: 'Notes', target: '#' },
      ],
    },
  ];

  if (medplum.isLoading()) {
    return null;
  }

  return (
    <AppShell
      logo={<Logo size={180} />}
      pathname={location.pathname}
      searchParams={searchParams}
      menus={userConfigToMenu(menus)}
      resourceTypeSearchDisabled={true}
      notifications={
        profile && (
          <div className="tw-flex tw-gap-4">
            <button
              type="button"
              className="tw-relative tw-inline-flex tw-items-center tw-p-1 tw-text-sm tw-font-medium tw-text-center tw-text-white tw-rounded-lg"
            >
              <IconBell color="#667085" />
            </button>
          </div>
        )
      }
    >
      <Suspense fallback={<Loading />}>
        <Routes>
          {profile ? (
            <>
              <Route path="/" element={<HomePage />} />
              <Route path="/onboarding" element={<OnboardingPage />} />
              <Route path="/:resourceType" element={<SearchPage />} />
              <Route path="/:resourceType/new" element={<ResourceCreatePage />} />
              <Route path="/Dashboard" element={<Index />} />
              <Route path="/WaitingList" element={<ListingPage />} />
              <Route path="/Patient" element={<ListingPage />} />
              <Route path="/Patient/:id/details" element={<Patient />} />
              <Route path="/Telehealth" element={<Telehealth />} />
              <Route path="Telehealth/:id/meeting" element={<Meeting />} />

              <Route path="/Schedule" element={schedule ? <Navigate to={`/Schedule/${schedule.id}`} /> : <Loading />} />
              <Route path="/Schedule/:id" element={schedule ? <SchedulePage /> : <Loading />} />
              <Route
                path="/Patient/:patientId/Schedule/:scheduleId"
                element={schedule ? <PatientSchedulePage /> : <Loading />}
              />
              <Route path="/feedback" element={<Feedback />} />
              <Route path="/Assessments" element={<Assessments/>} />
              <Route path="/Notifications" element={<Notifications />} />
              <Route path="/Patient/:id/*" element={<PatientChartingPage />} />
              <Route path="/ai-assistant" element={<FhirBot />} />
              <Route path="/Communication" element={<ListingPage />} />
              <Route path="/Communication/:id/communications" element={<Messages />} />
            </>
          ) : (
            <>
              <Route path="/signin" element={<SignInPage />} />
              <Route path="*" element={<Navigate to="/signin" replace />} />
            </>
          )}
        </Routes>
      </Suspense>
    </AppShell>
  );
}

function userConfigToMenu(config: SidebarMenuProps[]) {
  return (
    config?.map((menu) => {
      return {
        title: '',
        links:
          menu?.links?.map((link: MenuLink) => ({
            label: getDisplayName(link.name).displayName,
            href: link.target,
            icon: getIcon(link.name),
            subLinks: (link.subLinks || []).map((subLink) => {
              return {
                label: getDisplayName(subLink.name).displayName,
                href: subLink.target || '#',
                icon: getIcon(subLink.name) || <span />,
              };
            }),
          })) || [],
      };
    }) || []
  );
}

const resourceTypeToIcon: Record<string, any> = {
  Dashboard: IconHome,
  Appointments: IconCalendarTime,
  'Waiting List': IconBrandDatabricks,
  Patients: IconUsers,
  Messages: IconMail,
  Telehealth: IconVideo,
  'Lab Tests': IconMicroscope,
  Invoices: IconInvoice,
  Notes: IconNotes,
  Feedback: IconMessage,
  AppointmentList: IconCalendarTime,
  'My Schedule': IconCalendar,
  'Assessments': IconCircleCheck,
  'AI Assistant': IconRobotFace,
  Notifications: IconBell,
};

function getIcon(name: string): JSX.Element | undefined {
  try {
    if (name in resourceTypeToIcon) {
      const Icon = resourceTypeToIcon[name];
      return <Icon />;
    }
  } catch (e) {
    // Ignore
  }
  return <Space w={30} />;
}

const resourceTypeToDisplayName: Record<string, string> = {
  Dashboard: 'Dashboard',
  Appointment: 'Appointments',
  WaitingList: 'Waiting List',
  Patient: 'Patients',
  Telehealth: 'Telehealth',
  Feedback: 'Feedback',
  AppointmentList: 'Appointments',
};

function getDisplayName(name: string) {
  let displayName = name;
  if (name in resourceTypeToDisplayName) {
    displayName = resourceTypeToDisplayName[name];
  }
  return { displayName };
}
