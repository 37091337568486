import { AppointmentTypeOption } from './interface';

export const USA_STATES = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

export const TELEHEALTHTABS = [
  { label: 'All', value: 'all' },
  { label: 'Pre-Booked', value: 'pending' },
  { label: 'Completed', value: 'fulfilled' },
  { label: 'Cancelled', value: 'cancelled' },
];

export const STATUS_COLORS = {
  noshow: '#FF6347',
  proposed: '#17B26A',
  active: '#17B26A',
  rejected: '#EF6820',
  inactive: '#EF6820',
  'entered-in-error': '#EF6820',
  pending: '#FFD274',
  cancelled: '#EF6820',
  booked: '#007bff', // updated color
  fulfilled: '#6f42c1', // updated color
  arrived: '#17B26A',
  'checked-in': '#28a745', // updated color
  waitlist: '#17B26A',
  accepted: '#32CD32',
  amended: '#4BE4CF',
  balanced: '#32CD32',
  completed: '#84F384',
  current: '#4BE4CF',
  draft: '#FFA500',
  failed: '#D44B1A',
  final: '#D41AB4',
  incomplete: '#D44B1A',
  'in-progress': '#1A72D4',
  issued: '#D41AB4',
  'not-done': '#D44B1A',
  'on-hold': '#F1AB18',
  preliminary: '#F1AB18',
  preparation: '#F1AB18',
  ready: '#44EFBF',
  received: '#44EFE9',
  requested: '#EF44D1',
  stopped: '#D44B1A',
  superseded: '#D41AB4',
  unknown: '#A9A9A9',
  retired: '#4d88D6',
  'Not Started': '#4d88D6',
} as const;

export const STATUS_BACKGROUND_COLORS = {
  noshow: '#FF6347',
  proposed: '#ECFDF3',
  active: '#ECFDF3',
  rejected: '#FEF6EE',
  inactive: '#FEF6EE',
  'entered-in-error': '#FEF6EE',
  pending: '#FEF6EE',
  cancelled: '#FEF6EE',
  booked: '#c8e8ffb0',
  fulfilled: '#E8EAF6',
  'checked-in': '#ECFDF3',
  arrived: '#ECFDF3',
  waitlist: '#ECFDF3',
} as const;

export const STATUS_BORDER_COLORS = {
  noshow: '#FF6347',
  proposed: '#ABEFC6',
  active: '#ABEFC6',
  rejected: '#F9DBAF',
  inactive: '#F9DBAF',
  'entered-in-error': '#F9DBAF',
  pending: '#F9DBAF',
  cancelled: '#F9DBAF',
  booked: '#007bff40', // updated color
  fulfilled: '#6f42c140', // updated color
  arrived: '#ABEFC6',
  'checked-in': '#28a7454d', // updated color
  waitlist: '#ABEFC6',
} as const;

export const STATUS_TEXT_COLORS = {
  noshow: '#FF6347',
  proposed: '#17B26A',
  active: '#17B26A',
  rejected: '#B93815',
  inactive: '#B93815',
  'entered-in-error': '#B93815',
  pending: '#FFD274',
  cancelled: '#B93815',
  booked: '#007bff', // updated color
  fulfilled: '#6f42c1', // updated color
  arrived: '#17B26A',
  'checked-in': '#28a745', // updated color
  waitlist: '#17B26A',
} as const;

export const AppointmentTypes: AppointmentTypeOption[] = [
  { value: 'ROUTINE', label: 'Routine appointment' },
  { value: 'COMPLETE', label: 'Complete physical' },
  { value: 'FOLLOWUP', label: 'Follow-up visit' },
  { value: 'EMERGENCY', label: 'Emergency visit' },
  { value: 'WALKIN', label: 'Walk in visit' },
  { value: 'CHECKUP', label: 'Medical Check-Up' },
  { value: 'DENTAL', label: 'Dental appointment' },
  { value: 'SURGICAL', label: 'Surgical appointment' },
];

export const DaysOfWeek: { value: string; label: string }[] = [
  { value: 'sun', label: 'Sunday' },
  { value: 'mon', label: 'Monday' },
  { value: 'tue', label: 'Tuesday' },
  { value: 'wed', label: 'Wednesday' },
  { value: 'thu', label: 'Thursday' },
  { value: 'fri', label: 'Friday' },
  { value: 'sat', label: 'Saturday' },
];

export const CalendarView = [
  { value: 'month', label: 'Month' },
  { value: 'week', label: 'Week' },
  { value: 'day', label: 'Day' },
  { value: 'agenda', label: 'Agenda' },
];
