import { ActionIcon, Box, Button, Divider, Drawer, Group, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { createReference, normalizeErrorString } from '@medplum/core';
import { Reference, Schedule } from '@medplum/fhirtypes';
import { Loading, useMedplum } from '@medplum/react';
import { IconCircleCheck, IconCircleOff, IconClock } from '@tabler/icons-react';
import { blockAvailability } from '../../../utils/CustomAPI';
import { DateInput, TimeInput } from '@mantine/dates';
import { useRef, useState } from 'react';


interface BlockAvailabilityProps {
  readonly opened: boolean;
  readonly handlers: {
    readonly open: () => void;
    readonly close: () => void;
    readonly toggle: () => void;
  };
  schedule: Schedule;
}

export interface BlockAvailabilityEvent {
  schedule: Reference<Schedule>;
  start: string;
  end: string;
}

export function BlockAvailability(props: BlockAvailabilityProps): JSX.Element {
  const { opened, handlers, schedule } = props;
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const startTimeRef = useRef<HTMLInputElement>(null);
  const endTimeRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const medplum = useMedplum();

  if (!schedule) {
    return <Loading />;
  }

  const createPickerControl = (inputRef: React.RefObject<HTMLInputElement>): JSX.Element => (
    <ActionIcon variant="subtle" color="gray" onClick={() => inputRef.current?.showPicker()}>
      <IconClock size={16} stroke={1.5} />
    </ActionIcon>
  );

  async function handleSubmit(): Promise<void> {
    setIsLoading(true);
    if (!startDate || !endDate || !startTime || !endTime) {
      showNotification({
        color: 'red',
        icon: <IconCircleOff />,
        title: 'Error',
        message: 'All fields are required',
      });
      setIsLoading(false);
      return;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    start.setHours(parseInt(startTime.split(':')[0], 10), parseInt(startTime.split(':')[1], 10));
    end.setHours(parseInt(endTime.split(':')[0], 10), parseInt(endTime.split(':')[1], 10));

    const input: BlockAvailabilityEvent = {
      schedule: createReference(schedule),
      start: start.toISOString(),
      end: end.toISOString(),
    };

    try {
      await blockAvailability(medplum, input);
      window.location.reload();
      showNotification({
        icon: <IconCircleCheck />,
        title: 'Success',
        message: 'Slots created',
      });
    } catch (err) {
      showNotification({
        color: 'red',
        icon: <IconCircleOff />,
        title: 'Error',
        message: normalizeErrorString(err),
      });
      setIsLoading(false);
    }

    handlers.close();
  }

  return (
    <Drawer
      position="right"
      size="40%"
      opened={opened}
      onClose={handlers.close}
      title={
        <Box>
          <Text className="header-txt">Block Availability</Text>
          <Text className="sub-header-txt"> Block availability for a schedule</Text>
        </Box>
      }
    >
      <Divider />
      <Box className="flex-container" mt={'1.3rem'}>
        <Text span w={'35%'} className="title-txt">
          Start Date
          <Text span c={'red'}>
            {' '}
            *
          </Text>
        </Text>
        <DateInput
          clearable
          value={startDate}
          onChange={(date) => setStartDate(date)}
          placeholder="Date input"
          className="meeting-title-input-box"
          mb={12}
          minDate={new Date()}
        />
      </Box>
      <Box className="flex-container">
        <Text span w={'35%'} className="title-txt">
          End Date
          <Text span c={'red'}>
            {' '}
            *
          </Text>
        </Text>
        <DateInput
          clearable
          value={endDate}
          onChange={(date) => setEndDate(date)}
          placeholder="dd/mm/yyyy"
          className="meeting-title-input-box"
          mb={12}
          minDate={startDate || new Date()}
        />
      </Box>
      <Box className="flex-container">
        <Text span w={'35%'} className="title-txt">
          Start Time (each day)
          <Text span c={'red'}>
            {' '}
            *
          </Text>
        </Text>
        <TimeInput
          ref={startTimeRef}
          value={startTime}
          onChange={(e) => setStartTime(e.currentTarget.value || '')}
          rightSection={createPickerControl(startTimeRef)}
          className="meeting-title-input-box"
          mb={12}
        />
      </Box>
      <Box className="flex-container">
        <Text span w={'35%'} className="title-txt">
          End Time (each day)
          <Text span c={'red'}>
            {' '}
            *
          </Text>
        </Text>
        <TimeInput
          ref={endTimeRef}
          value={endTime}
          onChange={(e) => setEndTime(e.currentTarget.value || '')}
          rightSection={createPickerControl(endTimeRef)}
          placeholder="hh:mm"
          className="meeting-title-input-box"
          mb={12}
        />
      </Box>
      <Divider />
      <Group justify="flex-end" mt={20}>
        <Button bg={'#3ca5a9'} radius={4} onClick={handleSubmit} loading={isLoading}>
          Submit
        </Button>
      </Group>
    </Drawer>
  );
}
