/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { formatChatMessageLinks, LiveKitRoom, RoomAudioRenderer, VideoConference } from '@livekit/components-react';
import {
  ExternalE2EEKeyProvider,
  Room,
  RoomConnectOptions,
  RoomOptions,
  VideoPresets,
  type VideoCodec,
} from 'livekit-client';
import { decodePassphrase } from './lib/client-utils';
import { Box, Text, Image, LoadingOverlay } from '@mantine/core';
import './Livekit.css';
import { submitTranscription } from '../utils/CustomAPI';
import { showNotification } from '@mantine/notifications';
import { useMedplumContext, useMedplumNavigate } from '@medplum/react';
import { useParams } from 'react-router-dom';
import { getConfig } from '../config';
import { Socket, io } from 'socket.io-client';
import { IconCheckbox, IconCopy } from '@tabler/icons-react';
import { useAppContext } from '../AppProvider';
import { Appointment } from '@medplum/fhirtypes';
import ConfirmationModal from '../components/ConfirmationModal';

interface LiveKitMeetingProps {
  token: string;
  serverUrl: string;
  codec?: VideoCodec | undefined;
  meetingArgs: {
    setIsRightSectionVisible?: (visible: boolean) => void;
    isRightSectionVisible?: boolean;
  };
  appointment?: Appointment;
  setClinicalNotes: (notes: string) => void;
  setDocumentId: (documentId: string) => void;
  setIsSessionEnded: (isEnded: boolean) => void;
  setIsEndingSession: (isEnding: boolean) => void;
}

const LiveKitMeeting = (props: LiveKitMeetingProps): JSX.Element => {
  const { medplum } = useMedplumContext();
  const navigate = useMedplumNavigate();
  const { setTranscript, transcript, setIsSessionOn, isSessionOn, isConfirmOpen, setIsConfirmOpen, redirectionUrl, setRedirectionUrl, setHostParticipants, setJoinUser } = useAppContext();
  const { id: appointmentId } = useParams<{ id: string }>();
  const baseUrl = getConfig()?.baseUrl;
  const transcribeUrl = `${baseUrl}transcribe`;
  const {
    meetingArgs: { setIsRightSectionVisible, isRightSectionVisible },
    token,
    serverUrl,
    codec,
  } = props;
  const socketRef = useRef<Socket | null>(null);

  const [copied, setCopied] = useState(false);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const worker = typeof window !== 'undefined' && new Worker(new URL('livekit-client/e2ee-worker', import.meta.url));
  const keyProvider = new ExternalE2EEKeyProvider();
  const currentTranscription = useRef(transcript);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const mixedOutputStreamRef = useRef<MediaStream | null>(null);
  const userAudioStreamRef = useRef<MediaStream | null>(null);
  const destinationRef = useRef<MediaStreamAudioDestinationNode | null>(null);
  const remoteAudioSourcesRef = useRef<Map<string, MediaStreamAudioSourceNode>>(new Map());

  // Handle the beforeunload event to warn the user before leaving the page
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isSessionOn) {
        const message = 'Are you sure you want to leave? Your changes may not be saved.';
        event.preventDefault();
        event.returnValue = message;
        return message;
      }
    };
    if (isSessionOn) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isSessionOn]);

  const e2eePassphrase =
    typeof window !== 'undefined' ? decodePassphrase(window.location.hash.substring(1)) : undefined;
  const e2eeEnabled = !!(e2eePassphrase && worker);
  const roomOptions = useMemo((): RoomOptions => {
    return {
      publishDefaults: {
        videoSimulcastLayers: [VideoPresets.h540, VideoPresets.h216],
        red: !e2eeEnabled,
        videoCodec: codec,
      },
      adaptiveStream: { pixelDensity: 'screen' },
      dynacast: true,
      e2ee: e2eeEnabled
        ? {
            keyProvider,
            worker,
          }
        : undefined,
    };
  }, [e2eeEnabled, codec]);

  useEffect(() => {
    currentTranscription.current = transcript;
  }, [transcript]);

  const room = useMemo(() => new Room(roomOptions), [roomOptions]);
  if (e2eeEnabled) {
    keyProvider.setKey(e2eePassphrase);
    room.setE2EEEnabled(true);
  }
  const connectOptions = useMemo((): RoomConnectOptions => {
    return {
      autoSubscribe: true,
    };
  }, []);
  // session api and create the session
  const onEndSession = async (): Promise<void> => {
    props.setIsEndingSession(true);
    const body = {
      appointmentId: appointmentId,
      transcript: currentTranscription.current,
    };
    try {
      const session = await submitTranscription(medplum, body);
      props.setIsSessionEnded(true);
      props.setIsEndingSession(false);
      setIsSessionOn(false);
      setIsLoading(false);
      console.log('Transcription submitted:', session);
      if (session) {
        props.setClinicalNotes(session.clinicalNote);
        props.setDocumentId(session.documentId);
      }
    } catch (error) {
      console.error('Error submitting transcription:', error);
      props.setIsEndingSession(false);
      showNotification({ color: 'red', message: 'Error submitting transcription.', autoClose: true });
    }
  };

  useEffect(() => {
    // Handle user connection and start recording
    const handleConnected = async () => {
      setHostParticipants({isHost: true, hostName: room?.localParticipant?.name ?? ''});
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      // Stop tracks after permissions are granted (no active streams needed)
      stream.getTracks().forEach((track) => track.stop());
      if(stream) {
        console.log('User connected to the room');
        startRecording(); // Start transcription service
      }
    };

    // whene host left the call Handle user disconnection and stop recording 
    const handleDisconnected = () => {
      console.log('User has left the call');
      stopRecordingAndProcess();
      setIsLoading(true);
      onEndSession();
    };

    // Handle track subscribed 
    const handleTrackMuted = (publication: { kind: string }, participant: { identity: string; isLocal: boolean; }) => {
      if (publication.kind === 'audio') {
        const destination = destinationRef.current;
  
        if (participant.isLocal) {
          userAudioStreamRef.current?.getAudioTracks().forEach((track) => (track.enabled = false));
        } else {
          console.log(`Remote track muted for participant: ${participant.identity}`);
          const audioSource = remoteAudioSourcesRef.current.get(participant.identity);
          if (audioSource && destination) {
            audioSource.disconnect(destination);
          }
        }
      }
    }

    // Handle track unmuted and add it to the mixed audio stream
    const handleTrackUnmuted = (publication: any, participant: { identity: string; isLocal: boolean; }) => {
      if (publication.kind === 'audio') {
        const audioContext = audioContextRef.current;
        const destination = destinationRef.current;
  
        if (participant.isLocal) {
          console.log('Local track unmuted');
          userAudioStreamRef.current?.getAudioTracks().forEach((track) => (track.enabled = true));
        } else {
          console.log(`Remote track unmuted for participant: ${participant.identity}`);
          const audioStream = new MediaStream([publication.track.mediaStreamTrack]);
          const audioSource = audioContext?.createMediaStreamSource(audioStream);
  
          if (audioSource && destination) {
            audioSource.connect(destination);
            remoteAudioSourcesRef.current.set(participant.identity, audioSource);
          }
        }
      }
    }
    
    room.on('connected', handleConnected);
    room.off('trackSubscribed', handleTrackSubscribed);
    room.on('trackSubscribed', (track: any) => {handleTrackSubscribed(track)});
    room.on('trackUnmuted', handleTrackUnmuted);
    room.on('trackMuted', handleTrackMuted);
    room.on('disconnected', handleDisconnected);
  
    return () => {
      room.off('connected', handleConnected);
      room.off('trackSubscribed', handleTrackSubscribed);
      room.off('disconnected', handleDisconnected);
      room.off('trackMuted', handleTrackMuted);
      room.off('trackUnmuted', handleTrackUnmuted);
    };
  }, [room, socketRef]);
  

  // Start recording when the user unmutes their microphone using the deepgram transcription service
  const startRecording = async (retryCount = 3) => {
      console.log('Starting transcription service...');
      
      // Initialize socket connection
      let socket: Socket;
      try {
        socket = io(transcribeUrl, {
          transports: ['polling'],
          reconnection: true,
          reconnectionAttempts: 3,
          reconnectionDelay: 1000,
        });
        socketRef.current = socket;
        setSocket(socket);
        setIsSessionOn(true);
        socket.on('connect', () => {
          console.log('Connected to transcription service');
        });
    
        socket.on('error', (error) => {
          console.error('Socket error:', error);
          socket.disconnect();
          if (retryCount > 0) {
            console.log(`Retrying... (${retryCount} retries left)`);
            setTimeout(() => startRecording(retryCount - 1), 2000);
          } else {
            console.error('Failed to connect after multiple attempts.');
          }
        });
  
        socket.on('dg-connection-error', (message) => {
          console.error('Event - transcription service connection error:' + socket.id, message);
          socket.disconnect();
        });
  
        socket.on('dg-reconnect-failed', (message) => {
          console.error('Event - transcription service re-connection error:' + socket.id, message);
          showNotification({ color: 'red', message: message, autoClose: true });
        });
      } catch (error) {
        console.error('Error connecting to transcription service:', error);
        if (retryCount > 0) {
          console.log(`Retrying... (${retryCount} retries left)`);
          setTimeout(() => startRecording(retryCount - 1), 2000);
        } else {
          console.error('Failed to connect after multiple attempts.');
        }
      }
      const attemptGetUserMedia = async (retryCount = 0) => {
        try {
          // Initialize audio context and destination
          const audioContext = new AudioContext();
          audioContextRef.current = audioContext;
      
          const destination = audioContext.createMediaStreamDestination();
          destinationRef.current = destination;
      
          // Get user media
          const userStream = await navigator.mediaDevices.getUserMedia({ audio: true });
          userAudioStreamRef.current = userStream;
      
          const userSource = audioContext.createMediaStreamSource(userStream);
          userSource.connect(destination);
      
          console.log('User audio added to mix');
      
          // Initialize MediaRecorder
          const mediaRecorder = new MediaRecorder(destination.stream);
          mediaRecorderRef.current = mediaRecorder;
      
          // Handle 'can-open-mic' event from server
          socket.on('can-open-mic', () => {
            console.log('Event - Opening mic...');
      
            if (mediaRecorder.state === 'recording') {
              console.log('Already recording');
              return;
            }
      
            if (mediaRecorder.state === 'inactive') {
              mediaRecorder.start(200); // Start recording with chunks every 200ms
            } else if (mediaRecorder.state === 'paused') {
              mediaRecorder.resume();
            } else {
              mediaRecorder.start(200);
            }
            socket.emit('can-open-mic-ack');
          });
      
          // Handle 'dataavailable' event to send audio data to server
          mediaRecorder.ondataavailable = (e) => {
            if (e.data.size > 0) {
              socket.emit('microphone-stream', e.data);
              console.log('Sending mixed audio...');
            }
          };
      
          // Handle 'transcript-result' event from server
          socket.on('transcript-result', (socketId, jsonFromServer) => {
            console.log('JSON from server:', jsonFromServer);
            updateTranscripts(jsonFromServer);
          });
      
          // Handle errors during MediaRecorder processing
          mediaRecorder.addEventListener('error', (event) => {
            console.error('MediaRecorder error:', event);
            mediaRecorder.stop();
            if (mediaRecorder.stream) {
              mediaRecorder.stream.getTracks().forEach((track) => track.stop());
            }
          });
        } catch (error) {
          console.error('Error starting recording:', error);
          if (retryCount < 2) {
            setTimeout(() => attemptGetUserMedia(retryCount + 1), 2000);
          } else {
            showNotification({ color: 'red', message: 'Unable to access microphone after multiple attempts. Please check permission to microphone.', autoClose: true });
          }
  
        }
      }
      attemptGetUserMedia();
      return () => {
        // Unsubscribe from socket events when the component unmounts
        socket.off('can-open-mic');
        socket.off('close-mic');
        socket.off('transcript-result');
        socket.off('disconnect');
      };
  };
  
  // Update the transcript with the updated speaker count
  const updateTranscripts = (transcript: string) => {
    // Format the transcript with the updated speaker count
    setTranscript((prevTranscription: string) => {
      return prevTranscription + transcript;
    });
  };
  // Handle the subscribed track and add it to the mixed audio stream
  const handleTrackSubscribed = (track: any) => {
    room.remoteParticipants.forEach((participant) => {
      setJoinUser(participant?.name ?? '');
    });
    if (track.kind === 'audio') {
      console.log('Subscribed to audio track');
      const audioContext = audioContextRef.current;
      const destination = destinationRef.current;

      if (audioContext && destination) {
        const audioStream = new MediaStream([track.mediaStreamTrack]);
        const audioSource = audioContext.createMediaStreamSource(audioStream);

        // Connect the subscribed track to the mixed destination
        audioSource.connect(destination);
        console.log('Subscribed audio added to mix');
      } else {
        console.error('AudioContext or Destination is not initialized');
      }
    }
  };
  // Stop recording and process the audio stream
  const stopRecordingAndProcess = async () => {
    socketRef.current?.disconnect();
    socket?.disconnect();
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    if (userAudioStreamRef.current) {
      userAudioStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    if (mixedOutputStreamRef.current) {
      mixedOutputStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
    }
  };
  // get the patient join url
  const getPatientJoinUrl = (): string => {
    const config = getConfig();
    const baseUrl = config.baseUrl;

    if (props.appointment) {
      // Step 1: Get the value from the identifier
      const valueString = props.appointment?.identifier?.[0].value;

      // Step 2: Parse the JSON string
      const valueObject = JSON.parse(valueString ?? '{}');

      // Step 3: Extract the patient_join_url
      const patientJoinUrl = baseUrl + valueObject.patient_join_url;
      return patientJoinUrl;
    }

    return '';
  };

  // Handle the modal confirmation 
  const onConfirm = async () => {
    stopRecordingAndProcess();
    onEndSession();
    setIsSessionOn(false);
    setIsConfirmOpen(false);
    if(redirectionUrl === '/signin') {
      await medplum.signOut();
    }
    navigate(redirectionUrl);
  };

  return (
    <div style={{ height: '100%' }}>
      <Box pos="relative" w="100%" >
        <LoadingOverlay
          visible={isLoading}
          loaderProps={{ children: 'Please Wait...' }}
          h="100%"
        />
        <LiveKitRoom
          room={room}
          token={token}
          connectOptions={connectOptions}
          serverUrl={serverUrl}
          audio={true}
          video={true}
          style={{ height: '100%' }}
        >
          <VideoConference chatMessageFormatter={formatChatMessageLinks} style={{ height: '100%' }} />
          <RoomAudioRenderer />
        </LiveKitRoom>
      </Box>

      <Box bg="#F9F5FF" py={20} style={{ borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px' }}>
        <Box pl={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Text className="zoom-meeting-txt">Call Started</Text>
          <Box className="flex-center">
            <Box
              className="zoom-meeting-button btn_bg"
              style={{ cursor: 'pointer' }}
              onClick={async () => {
                await navigator.clipboard.writeText(getPatientJoinUrl() || '');
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
              }}
            >
              {copied ? <IconCheckbox color="white" size="18px" /> : <IconCopy color="white" size="18px" />}
              <Text className="zoom-meeting-text">Copy Meeting Link</Text>
            </Box>
            <Image
              width={24}
              height={24}
              mr={24}
              src={isRightSectionVisible ? '../../img/icons/hide-button.svg' : '../../img/icons/import.svg'}
              title={isRightSectionVisible ? 'Close Sidebar' : 'Open Side Bar'}
              onClick={() => setIsRightSectionVisible && setIsRightSectionVisible(!isRightSectionVisible)}
              style={{ cursor: 'pointer' }}
            />
          </Box>
        </Box>
      </Box>
      {isConfirmOpen && (
        <ConfirmationModal
          setIsConfirmationModalOpen={() => {
             setRedirectionUrl('');
             setIsConfirmOpen(false);
          }}
          onConfirm={onConfirm}
          isConfirmationModalOpen={isConfirmOpen}
          content={
            'This action will discard unsaved changes. Do you wish to proceed?'
          }
        />
      )}
    </div>
  );
};

export default LiveKitMeeting;
