/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect, useState } from 'react';
import { useMedplum } from '@medplum/react';
import { Drawer, Divider } from '@mantine/core';
import { QuestionnaireResponse } from '@medplum/fhirtypes';
import AssessmentPreview from './AssessmentPreview';
import { getQuestionnaireResponseById } from '../../utils/CustomAPI';
import AssessmentInsight from './AssessmentInsight';

interface AssessmentQuestionsProps {
  opened: boolean;
  close: () => void;
  questionnaireResponseId: string;
  assessmentName?: string;
}

const AssessmentQuestionsDrawer = (props: AssessmentQuestionsProps) => {
  const medplum = useMedplum();
  const [questionnaireResponse, setQuestionnaireResponse] = useState<QuestionnaireResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const stepLabels = ['Assessment Response Overview','AI Insights'];
  const [activeStep, setActiveStep] = useState(0);
  const [hasData, setHasData] = useState<boolean>(true);
  const [assessmentCompletionDate, setAssessmentCompletionDate] = useState('');
  const [assessmentInsightsSummaryData, setAssessmentInsightsSummaryData] = useState<string[] | undefined>([]);
 

  useEffect(() => {
    if (props.opened) {
      setActiveStep(0); // Reset to default tab
      getQuestionnaireResponse(props.questionnaireResponseId);
    }
  }, [props.opened, props.questionnaireResponseId]);

  const getQuestionnaireResponse = (questionnaireResponseId: string) => {
    setLoading(true);
    getQuestionnaireResponseById(medplum, questionnaireResponseId)
      .then((response) => {
        setQuestionnaireResponse(response);
        setAssessmentCompletionDate(response?.authored || '');
        // Get AI insights
        const insights = response?.extension?.find((data: any) => data.url === 'http://ai-insights');
        if (insights) {
          const lines = insights?.valueString?.split('\n');
          setAssessmentInsightsSummaryData(lines);
          setHasData(true);
        } else {
          setHasData(false);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Drawer.Root offset={8} position="right" size="55%" radius="md" opened={props.opened} onClose={props.close}>
      <Drawer.Overlay />
      <Drawer.Content>
        <Drawer.Header>
          <Drawer.Title className="tw-font-medium tw-text-[16px]">
            <div className="tw-flex tw-justify-between tw-gap-5 tw-mb-5">
              {stepLabels.map((label, index) => (
                <button
                  key={label}
                  className="tw-text-[#344054] tw-p-2 tw-border-none tw-bg-[#fff] tw-cursor-pointer tw-text-[14px] tw-font-semibold tw-text-left"
                  style={{ borderBottom: index === activeStep ? '2px solid #2aaaaf' : 'none' }}
                  onClick={() => setActiveStep(index)}
                >
                  {label}
                </button>
              ))}
            </div>
          </Drawer.Title>
          <Drawer.CloseButton />
        </Drawer.Header>
        <Divider />
        <Drawer.Body>
          {activeStep === 0 ? (
            <AssessmentPreview
              questionnaireResponse={questionnaireResponse}
              loading={loading}
              assessmentName={props.assessmentName}
            />
          ) : (
            <div className="tw-bg-[#fff] tw-px-3 tw-pt-5 tw-rounded-b-lg tw-text-[#101828] tw-text-[18px] tw-font-semibold">
              <AssessmentInsight
                assessmentCompletionDate={assessmentCompletionDate}
                assessmentInsightsSummaryData={assessmentInsightsSummaryData}
                hasData={hasData}
              />
            </div>
          )}
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};

export default AssessmentQuestionsDrawer;
