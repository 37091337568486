/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { getSlots } from '../utils/CustomAPI';
import { useMedplum } from '@medplum/react';
import { SelectedSlot } from '../utils/interface';
import { formatDate } from '../utils/util';
import { useAppContext } from '../AppProvider';

interface SlotsProps {
  onSelectSlot: (slot: any) => void;
  serviceId?: string;
  startDate: string;
  formStartDate?: string;
  slotError: boolean;
  currentSlotId?: string;
}

const Slots: React.FC<SlotsProps> = (props: SlotsProps) => {
  const [activeSlot, setActiveSlot] = useState<string>(props.currentSlotId || '');
  const medplum = useMedplum();
  const [slots, setSlots] = useState<SelectedSlot[]>([]);
  const [isSlotsEmpty, setIsSlotsEmpty] = useState<boolean>(false);
  const { scheduleId } = useAppContext();

  const onClickSlot = (slot: SelectedSlot) => {
    setActiveSlot(slot?.id);
    props.onSelectSlot(slot);
  };

  useEffect(() => {
    if (props.currentSlotId) {
      setActiveSlot(props.currentSlotId);
    }
  }, [props.currentSlotId]);

  useEffect(() => {
    if (props.startDate || props.formStartDate) {
      getSlotsList();
    }
  }, [props.serviceId, props.startDate, props.formStartDate, medplum]);

  const getSlotsList = () => {
    const payload = {
      scheduleId: scheduleId,
      startDate: formatDate(new Date(props.startDate), 'YYYY-MM-DD'),
      serviceId: props.serviceId,
    };
    getSlots(medplum, payload)
      .then((res) => {
        if (res.data.entry.length === 0) {
          setIsSlotsEmpty(true);
          setSlots([]);
        } else {
          setIsSlotsEmpty(false);
          setSlots(
            res.data.entry.map((slot: any) => {
              return {
                id: slot.resource.id,
                time: new Date(slot.resource.start).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
                startTime: slot.resource.start,
                endTime: slot.resource.end,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.error('Error fetching slots:', err);
        setIsSlotsEmpty(true);
        setSlots([]);
      });
  };

  return (
    <>
      {slots.length > 0 && (
        <>
          <label className="tw-text-[#000] tw-font-medium">Available Slots</label>
          <div className="slots_container">
            {slots.map((slot) => {
              const isActiveSlot = activeSlot === slot?.id;
              const slotStyle = {
                fontWeight: '300',
                backgroundColor: isActiveSlot ? '#4DB5BA' : 'white',
                color: isActiveSlot ? 'white' : 'black',
                cursor: 'pointer',
              };
              return (
                <div
                  key={slot?.id}
                  onClick={() => onClickSlot(slot)}
                  className="slots_btn tw-w-[23%]"
                  style={slotStyle}
                >
                  <span>{slot.time}</span>
                </div>
              );
            })}
            {props?.slotError && <span className="tw-font-medium tw-text-[red]">Please select slot</span>}
          </div>
        </>
      )}
      {isSlotsEmpty && (
        <>
          <label className="tw-text-[#000] tw-font-medium">Available Slots</label>
          <div className="tw-text-center tw-font-small tw-mt-10 tw-mb-10 tw-text-[16px]">No slots available.</div>
        </>
      )}
    </>
  );
};

export default Slots;
