/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useRef, useState } from 'react';
import { Text, Group, ScrollArea, Button, Divider, Skeleton, Box, Select } from '@mantine/core';
import { IconArrowNarrowLeft, IconRefresh, IconVideo } from '@tabler/icons-react';
import ChatMessage from './ChatMessage';
import { useNavigate, useParams } from 'react-router-dom';
import { ResourceAvatar, useMedplum } from '@medplum/react';
import { calculateAge, getDayLabel, getFullName } from '../../utils/util';
import { Communication, Patient } from '@medplum/fhirtypes';
import { showNotification } from '@mantine/notifications';
import ChatInput from './ChatInput';

interface ChatMessageType {
  id: string;
  sender: 'provider' | 'patient';
  message: string;
  time: string;
  date?: string;
  day?: string;
  subject?: { reference: string; display: string };
}

const Messages: React.FC = () => {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const { id } = useParams();
  const [messages, setMessages] = useState<ChatMessageType[]>([]);
  const [communication, setCommunication] = useState<Communication>();
  const [patientData, setPatientData] = useState<Patient>();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<string>('');

  // Add this inside your component
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  // Add this effect to scroll to bottom when messages change
  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTo({
        top: scrollAreaRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [messages]);

  useEffect(() => {
    // Initial load of messages
    getMessages();

    // Set up interval for auto-updating
    const interval = setInterval(() => {
      getMessages();
    }, 5000); // 5 seconds

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  // get patient details
  const getPatientDetails = (resource: Communication) => {
    medplum
      .readResource('Patient', resource?.subject?.reference?.split('/')?.[1] || '')
      .then((resource: Patient) => {
        setPatientData(resource);
      })
      .catch((err: Error) => {
        console.log(err);
      });
  };

  // get messages from communication resource and set messages state
  const getMessages = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    await medplum
      .readResource('Communication', id || '')
      .then((resource: Communication) => {
        setLoading(false);
        getPatientDetails(resource);
        setCommunication(resource);
        setStatus(resource.status || '');
        const parsedMessages: ChatMessageType[] = (resource.payload || [])
          ?.map((item) => {
            const parsed = JSON.parse(item.contentString || '{}');
            const date = new Date(parsed.date);
            return Object.entries(parsed)
              .filter(([key]) => key === 'patient' || key === 'provider')
              .map(([key, value]) => ({
                id: parsed.id + '-' + key,
                sender: key as 'provider' | 'patient',
                message: value as string,
                time: date.toLocaleTimeString(),
                day: getDayLabel(parsed.date),
                date: parsed.date,
                subject: resource.subject
                  ? { reference: resource.subject.reference || '', display: resource.subject.display || '' }
                  : undefined,
              }));
          })
          .flat()
          .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()); // Sort by time

        // Compare if messages have changed - more reliable than just length comparison
        const hasNewMessages = parsedMessages.length !== messages.length;

        if (hasNewMessages) {
          setMessages(parsedMessages);
        }
      })
      .catch((err: Error) => {
        console.log(err);
      });
  };

  const sendMessage = (text: string) => {
    if (!text.trim()) {
      return;
    }

    const newMsg: ChatMessageType = {
      id: Date.now().toString(),
      sender: 'provider',
      message: text,
      time: 'Just now',
      day: getDayLabel(new Date().toISOString()),
      date: new Date().toISOString(),
    };
    setMessages((prev) => [...prev, newMsg]);
    const ProviderName = medplum?.getProfile()?.name?.[0];

    // First, get the latest version of the resource
    medplum.readResource('Communication', id as string).then((latestResource: Communication) => {
      // Create the new payload entry
      const newPayloadEntry = {
        contentString: JSON.stringify({
          date: new Date().toISOString(),
          provider: text,
          id: newMsg.id,
        }),
      };

      // updateResource communication resource with new message
      return medplum.updateResource<Communication>({
        resourceType: 'Communication',
        id: id || '',
        status: 'in-progress',
        sender: {
          reference: `Practitioner/${medplum.getProfile()?.id}`,
          display: ProviderName ? getFullName(ProviderName) : 'Unknown',
        },
        category: communication?.category,
        subject: communication?.subject,
        recipient: [{ reference: communication?.subject?.reference, display: communication?.subject?.display }],
        sent: communication?.sent,
        payload: [...(latestResource.payload || []), newPayloadEntry],
      });
    });
  };

  const updateCommunicationStatus = (status: string) => {
    setStatus(status);
    if (communication) {
      communication.status = status as 'in-progress' | 'completed' | 'on-hold';
      medplum
        .updateResource<Communication>(communication)
        .then(() => {
          showNotification({ message: 'Status updated successfully', color: 'blue' });
        })
        .catch((err : Error) => {
          console.log(err);
        });
    }
  };

  return (
    <Box px={15}>
      {/* Header */}
      <Group py="md" justify="space-between" style={{ borderBottom: '1px solid #e0e0e0' }}>
        <Group align="center">
          <button
            className="tw-rounded-[50%] tw-border tw-border-[#D0D5DD] tw-p-1.5 right_card tw-cursor-pointer"
            onClick={() => navigate(`/Communication`)}
          >
            <IconArrowNarrowLeft size={20} stroke={1.5} color="#344054" />
          </button>
          <ResourceAvatar radius="xl" size="lg" value={communication?.subject} />
          <div>
            <Text size="lg" fw={600}>
              {communication?.subject?.display}
            </Text>
            {patientData && (
              <Text size="sm" color="gray">
                {calculateAge(patientData?.birthDate || '') || ''}, {patientData?.gender}
              </Text>
            )}
          </div>
        </Group>
        <Group className="message-header-right">
          <button
            className="tw-rounded-lg tw-border tw-border-[#D0D5DD] tw-p-1.5 right_card tw-cursor-pointer"
            onClick={getMessages}
          >
            <IconRefresh size={20} stroke={1.5} color="#344054" />
          </button>
          <button
            className="tw-rounded-lg tw-border tw-border-[#D0D5DD] tw-p-1.5 right_card tw-cursor-pointer"
            onClick={() => navigate(`/Telehealth`)}
          >
            <IconVideo size={20} stroke={1.5} color="#344054" />
          </button>
          <Select
            placeholder="Select Status"
            value={status}
            onChange={(value) => updateCommunicationStatus(value as string)}
            radius="md"
            data={[
              { value: 'in-progress', label: 'In Progress' },
              { value: 'completed', label: 'Completed' },
              { value: 'on-hold', label: 'On Hold' },
            ]}
          />
          <Button
            className={`addnew-button-default button-default btn_bg`}
            onClick={() => window.open(`/Patient/${patientData?.id}/details`, '_blank')}
          >
            View Profile
          </Button>
        </Group>
      </Group>

      {/* Chat Area */}
      <ScrollArea h={550} py="md" viewportRef={scrollAreaRef}>
        {loading ? (
          <Box mb="sm">
            <Skeleton height={40} mt={6} width="30%" radius="md" mb={20} />
            <Group justify="flex-end">
              <Skeleton height={40} mt={6} width="20%" radius="md" style={{ display: 'block', float: 'right' }} />
            </Group>
            <Skeleton height={40} mt={6} width="25%" radius="md" mb={20} />
            <Group justify="flex-end">
              <Skeleton height={40} mt={6} width="40%" radius="md" style={{ display: 'block', float: 'right' }} />
            </Group>
            <Skeleton height={40} mt={6} width="35%" radius="md" mb={20} />
            <Group justify="flex-end">
              <Skeleton height={40} mt={6} width="25%" radius="md" style={{ display: 'block', float: 'right' }} />
            </Group>
          </Box>
        ) : (
          messages.map((msg, index) => {
            const showDivider = index === 0 || msg.day !== messages[index - 1].day;
            return (
              <React.Fragment key={msg.id}>
                {showDivider && <Divider label={msg.day} labelPosition="center" my="xs" />}
                <ChatMessage sender={msg.sender} message={msg.message} time={msg.time} subject={msg.subject} />
              </React.Fragment>
            );
          })
        )}
      </ScrollArea>

      {/* Message Input */}
      <ChatInput onSendMessage={sendMessage} />
    </Box>
  );
};

export default Messages;
