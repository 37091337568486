import { Center, Table, Text } from '@mantine/core';
import { formatDate } from '../../utils/util';
import { Notification } from '../../utils/interface';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../AppProvider';
import { Appointment } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';

interface NotificationTableProps {
  notifications: Notification[];
  loading: boolean;
}

const NotificationTable = ({ notifications, loading }: NotificationTableProps): JSX.Element => {
  const navigate = useNavigate();
   const { setIsJoinCallDrawerOpen, setSelectedAppointment } = useAppContext();
   const medplum = useMedplum();

  const handleClick = async (notification: Notification): Promise<void> => {
    if (notification?.id) {
      if (notification?.type === 'Telehealth') {
        setIsJoinCallDrawerOpen(true);
        const appointment: Appointment = await medplum.readResource(
          'Appointment',
          notification?.references?.[0]?.reference.split('/')[1] as string
        );
        setSelectedAppointment(appointment);
      } else if (notification?.type === 'Assessment') {
        navigate(`/Assessments`);
      }
    }
  };

  const rows = notifications.map((notification) => (
    <Table.Tr key={notification?.id} fz={'14px'} lh={'40px'} fw={400} c={'#475467'}>
      <Table.Td w={200} fw={500} fz={'14px'} lh={'20px'} c={'#101828'}>
        {notification.patientName || '-'}
      </Table.Td>
      <Table.Td w={250}>{notification?.title || 'No title'}</Table.Td>
      <Table.Td
        w={500}
        onClick={() => {
          handleClick(notification);
        }}
        title={`Go to ${notification?.title}`}
      >
        {notification?.description?.replace(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/g, (date) =>
          formatDate(new Date(date), 'hh:mm a')
        ) || 'No description'}
      </Table.Td>
      <Table.Td>{notification.eventAt ? formatDate(new Date(notification.eventAt), 'MMMM dd, yyyy') : '-'}</Table.Td>
    </Table.Tr>
  ));

  const tableLoading = (
    <Table.Tr>
      <Table.Td colSpan={4}>
        <Center>
          <Text>Loading...</Text>
        </Center>
      </Table.Td>
    </Table.Tr>
  );

  return (
    <Table className="table-container">
      <Table.Thead className="table-header">
        <Table.Tr>
          <Table.Th>Patient Name</Table.Th>
          <Table.Th>Title</Table.Th>
          <Table.Th>Description</Table.Th>
          <Table.Th>Date</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{loading ? tableLoading : rows}</Table.Tbody>
    </Table>
  );
};

export default NotificationTable;
