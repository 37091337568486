/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { MedplumClient } from '@medplum/core';
import { HumanName } from '@medplum/fhirtypes';

export const checkInputValue = (name: string, value: string) => {
  const nonAlphabeticFields = [
    'name',
    'email',
    'address',
    'phone',
    'npi',
    'brandMobile',
    'brandEmail',
    'password',
    'confirm_password',
    'postalCode',
    'providerMobile',
    'providerEmail',
    'street',
    'city',
    'state',
    'service',
    'firstName',
    'lastName',
    'summary',
    'first_name',
    'last_name',
    'suiteApt',
    'timezone',
    'insurance',
    'mobile',
    'insuranceNumber',
    'startTime',
    'endTime',
  ];
  const limitedLengthFields = ['phone', 'brandMobile', 'providerMobile', 'npi', 'postalCode', 'mobile'];
  const numericFields = [
    'phone',
    'brandMobile',
    'providerMobile',
    'npi',
    'postalCode',
    'price',
    'insuranceNumber',
    'mobile',
  ];

  const isAlphabetic = /^[a-zA-Z\s]*$/.test(value);
  const isNumeric = /^[0-9]*$/.test(value);

  if (!isAlphabetic && !nonAlphabeticFields.includes(name)) {
    return null; // Ignore non-alphabetic input
  }

  if (numericFields.includes(name) && !isNumeric) {
    return null; // Ignore non-numeric input for numeric fields
  }

  if (limitedLengthFields.includes(name) && value.length > 10) {
    return value.slice(0, 10);
  }

  return value;
};

export function getFullName(name: HumanName): string {
  if (!name) {
    return '';
  }
  return `${name.given?.join(' ') || ''} ${name.family || ''}`.trim();
}

export const formatDate = (date: Date, format: string = 'yyyy-MM-dd'): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const weekdayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  // AM/PM calculation
  const period = hours >= 12 ? 'PM' : 'AM';
  const hour12 = hours % 12 || 12;

  const replacements: Record<string, string> = {
    yyyy: year.toString(),
    YYYY: year.toString(), // Support uppercase
    yy: year.toString().slice(-2),
    YY: year.toString().slice(-2), // Support uppercase
    MMMM: monthNames[date.getMonth()],
    MMM: monthNames[date.getMonth()].slice(0, 3),
    MM: month,
    M: (date.getMonth() + 1).toString(),
    dd: day,
    DD: day, // Support uppercase
    d: date.getDate().toString(),
    EEEE: weekdayNames[date.getDay()],
    EEE: weekdayNames[date.getDay()].slice(0, 3),
    hh: String(hour12).padStart(2, '0'),
    h: hour12.toString(),
    mm: minutes,
    MM_MINUTES: minutes, // Prevent overlap with MM (month)
    a: period,
  };

  // Handle text in single quotes (e.g., 'Week of')
  const escapeRegex = /'(.*?)'/g;
  const escapedText: string[] = [];
  format = format.replace(escapeRegex, (_, text) => {
    escapedText.push(text);
    return `@@${escapedText.length - 1}@@`; // Placeholder for escaped text
  });

  // Replace date tokens with actual values (case-insensitive matching)
  format = format.replace(/yyyy|YYYY|yy|YY|MMMM|MMM|MM|M|dd|DD|d|EEEE|EEE|hh|h|mm|a/g, (match) => {
    // Return the appropriate replacement or the token itself if no match is found
    return replacements[match] || match;
  });

  // Restore escaped text
  format = format.replace(/@@(\d+)@@/g, (_, index) => escapedText[Number(index)]);

  return format;
};

export const getCurrentWeek = () => {
  const currentDate = new Date();
  const firstDay = currentDate.getDate() - currentDate.getDay(); // Sunday
  const lastDay = firstDay + 6; // Saturday
  const start = new Date(currentDate.setDate(firstDay));
  const end = new Date(currentDate.setDate(lastDay));
  return { start, end };
};

export const handleReadNotification = async (id: string, medplum: MedplumClient): Promise<void> => {
  if (id) {
    try {
      const existingAuditEvent = await medplum.readResource('AuditEvent', id);
      if (existingAuditEvent && existingAuditEvent?.action !== 'R') {
        await medplum.updateResource({
          ...existingAuditEvent,
          action: 'R',
          outcome: '0',
        });
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  }
};

// convert date into today, yesterday format
export const getDayLabel = (dateString: string): string => {
  const messageDate = new Date(dateString);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  if (messageDate.toDateString() === today.toDateString()) {
    return "Today";
  }
  if (messageDate.toDateString() === yesterday.toDateString()) {
    return "Yesterday";
  }
  return messageDate.toLocaleDateString();
};

// calculate age from date of birth
export const calculateAge = (birthDate: string): number => {
  const birth = new Date(birthDate);
  const today = new Date();
  let age = today.getFullYear() - birth.getFullYear();
  const monthDifference = today.getMonth() - birth.getMonth();
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
    age--;
  }
  return age;
};