/* eslint-disable @typescript-eslint/explicit-function-return-type */
const AnimateLoader = () => {
  return (
    <span className="loading">
      <span className="loading__dot"></span>
      <span className="loading__dot"></span>
      <span className="loading__dot"></span>
    </span>
  );
};

export default AnimateLoader;
