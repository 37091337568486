/* eslint-disable no-nested-ternary */
import {
  Table,
  Text,
  Group,
  NativeSelect,
  Rating,
  Paper,
  Box,
  Badge,
  Divider,
  Flex,
  ActionIcon,
  Center,
} from '@mantine/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMedplum } from '@medplum/react';
import { IconChevronDown, IconChevronUp, IconEye } from '@tabler/icons-react';
import './Feedback.css';
import CustomPagination from '../../components/CustomPagination';
import { CommunicationRequest } from '@medplum/fhirtypes';
import { getFeedbackList } from '../../utils/CustomAPI';
import PatientPractitionerSelect from '../../components/PatientPractitionerSelect';
import { GetFeedbackListPayload } from '../../utils/interface';

interface FeedbackData {
  fullUrl: string;
  resource: CommunicationRequest;
}

interface FeedbackResponse {
  resourceType: string;
  type: string;
  entry: FeedbackData[];
  total: number;
  link: {
    relation: string;
    url: string;
  }[];
}

function Feedback(): JSX.Element {
  const [activity, setActivity] = useState<string>('Rating');
  const medplum = useMedplum();
  const [feedbackData, setFeedbackData] = useState<FeedbackData[]>([]);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [resourceData, setResourceData] = useState<FeedbackResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [offset, setOffset] = useState<number>(0);
  const skipEffect = useRef(false);
  const itemsPerPage = 10;

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const fetchFeedback = useCallback(
    async (id?: string | null) => {
      setIsLoading(true);
      try {
        const payload: GetFeedbackListPayload = {
          searchType: 'patient',
          category: activity,
          offset: offset,
          id: id ?? '',
          itemsPerPage: itemsPerPage,
          filterByCurrentUser: true,
        };

        const data: FeedbackResponse = await getFeedbackList(medplum, payload);
        setResourceData(data);
        setIsLoading(false);
        setFeedbackData(data.entry || []);
      } catch (error) {
        console.error('Error fetching feedback:', error);
        setFeedbackData([]);
      }
    },
    [activity, medplum, offset, selectedId]
  );

  useEffect(() => {
    if (skipEffect.current) {
      skipEffect.current = false;
      return;
    }
    fetchFeedback(selectedId).catch((err) => console.error(err));
  }, [activity, medplum, offset, fetchFeedback, selectedId]);

  const renderValue = (data: FeedbackData, field: string): string | JSX.Element => {
    const resource = data.resource;
    switch (field) {
      case 'practitionerName':
        return resource?.requester?.display || '-';
      case 'patientName':
        return resource?.subject?.display || '-';
      case 'session': {
        const session = resource?.extension?.find((ext) => ext.url === '/intg/structure/extensions/sessionName');
        return session?.valueString || '-';
      }
      case 'noteType': {
        const noteTypeExtension = resource?.extension?.find((ext) => ext.url === 'http://note-type');
        return noteTypeExtension?.valueString || '-';
      }
      case 'dateTime':
        return formatDateTime(resource?.authoredOn as string) || '-';
      case 'rating':
        return <Rating value={Number(resource?.category?.[0].coding?.[0].display)} size="xs" readOnly />;
      case 'viewSession': {
        const redirectLinkId = resource?.extension?.find((ext) => ext.url === '/intg/structure/extensions/sessionId');
        const sessionId = redirectLinkId?.valueString;
        return sessionId ? (
          <ActionIcon
            color="#fff"
            ml={8}
            title="View Session"
            onClick={() => window.open(`Telehealth/${sessionId}/meeting`, '_blank')}
          >
            <IconEye size={26} color="#475467" stroke={1.5} />
          </ActionIcon>
        ) : (
          '-'
        );
      }
      default:
        return '-';
    }
  };

  const formatDateTime = (dateTimeString: string): string => {
    if (!dateTimeString) {
      return '-';
    }

    const date = new Date(dateTimeString);

    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = `0${date.getMinutes()}`.slice(-2);

    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours || 12;
    const formattedHours = `0${hours}`.slice(-2);

    return `${month}-${day}-${year} ${formattedHours}:${minutes} ${ampm}`;
  };

  const fields = ['practitionerName', 'patientName', 'session', 'noteType', 'rating', 'dateTime', 'viewSession'];

  const handlePageChange = (page: number): void => {
    setOffset((page - 1) * itemsPerPage);
  };

  const handleResourceSelect = async (resource: { value: string; label: string } | undefined): Promise<void> => {
    if (resource) {
      setSelectedId(resource.value);
    } else {
      setSelectedId('');
    }
  };

  return (
    <Paper className="feedback-table-container" mt={20}>
      <Box className="feedback-header" m="md" mb={0}>
        <Text display={'flex'} fw={600} py={'10px'} fz={'18px'} c="#101828" lh={'28px'}>
          Feedback
        </Text>
        <Badge className="badge-style">{resourceData?.total} records</Badge>
      </Box>
      <Divider my="sm" color="#EAECF0" />
      <Flex justify={'space-between'} align={'center'}>
        <Box ml={10}>
          <PatientPractitionerSelect onResourceSelect={handleResourceSelect} label={true} />
        </Box>
        <NativeSelect
          data={[
            { value: 'Rating', label: 'All Rating' },
            { value: '1', label: '1 Star' },
            { value: '2', label: '2 Stars' },
            { value: '3', label: '3 Stars' },
            { value: '4', label: '4 Stars' },
            { value: '5', label: '5 Stars' },
          ]}
          value={activity}
          onChange={(event) => setActivity(event.currentTarget.value)}
          mb="md"
          m={20}
        />
      </Flex>
      <Group>
        <Table className="feedback-table">
          <thead>
            <tr className="feedback-table-header">
              {fields.map((field) => (
                <th key={field} className="feedback-table-head">
                  <Group>
                    <Text fw={500} size="sm" c={'#344054'} fz={'14px'} lh={'18px'}>
                      {(() => {
                        switch (field) {
                          case 'practitionerName':
                            return 'Practitioner Name';
                          case 'patientName':
                            return 'Patient Name';
                          case 'session':
                            return 'Session';
                          case 'noteType':
                            return 'Note Type';
                          case 'dateTime':
                            return 'Date & Time';
                          case 'viewSession':
                            return 'View Session';
                          case 'rating':
                            return 'Rating';
                          default:
                            return '';
                        }
                      })()}
                    </Text>
                  </Group>
                </th>
              ))}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {feedbackData && feedbackData.length > 0 ? (
              feedbackData.map((resource: any) => (
                <React.Fragment key={resource.resource.id}>
                  <tr
                    key={`row-${resource.resource.id}`}
                    className={`${expandedRow === resource.resource.id ? 'bg-color-class' : ''}`}
                    onClick={() => setExpandedRow(expandedRow === resource.resource.id ? null : resource.resource.id)}
                  >
                    {fields.map((field, i) => (
                      <td key={`cell-${resource.resource.id}-${i}`} style={{ padding: '1rem' }}>
                        {renderValue(resource, field)}
                      </td>
                    ))}
                    <td key={`expand-icon-${resource.resource.id}`}>
                      {(() => {
                        if (!resource?.resource?.note?.[0]?.text) {
                          return <div className="h-5 w-5" />;
                        }
                        return expandedRow === resource.resource.id ? (
                          <IconChevronUp className="h-5 w-5" />
                        ) : (
                          <IconChevronDown className="h-5 w-5" />
                        );
                      })()}
                    </td>
                  </tr>
                  {expandedRow === resource.resource.id && resource?.resource?.note?.[0]?.text && (
                    <tr key={`expanded-${resource.resource.id}`} style={{ border: '1px solid #E5E5E5' }}>
                      <td colSpan={fields.length} style={{ padding: '1rem' }}>
                        <div style={{ marginBottom: '5px' }}>
                          <span style={{ color: '#000', fontWeight: 500 }}>Feedback:</span>
                          <span style={{ paddingLeft: '5px' }}>
                            {resource?.resource?.note?.[0]?.text?.split(':')?.[0] || '-'}
                          </span>
                        </div>
                        <div>
                          <span style={{ color: '#000', fontWeight: 500 }}>Comment:</span>
                          <span style={{ paddingLeft: '5px' }}>
                            {resource?.resource?.note?.[0]?.text?.split(':')?.[1] || '-'}
                          </span>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : isLoading ? (
              <tr>
                <td colSpan={7}>
                  <Center mt={10}>Loading...</Center>
                </td>
              </tr>
            ) : (
              <td colSpan={7}>
                <Center mt={10}>No data available</Center>
              </td>
            )}
          </tbody>
        </Table>
        <CustomPagination
          total={Math.ceil((resourceData?.total ?? 0) / itemsPerPage)}
          onChange={handlePageChange}
          value={Math.floor(offset / itemsPerPage) + 1}
        />
      </Group>
    </Paper>
  );
}

export default Feedback;
