import { Box, Text } from '@mantine/core';
import './Components.css';

interface TelehealthTranscriptionProps {
  transcription: string;
}

const TelehealthTranscription = ({ transcription }: TelehealthTranscriptionProps): JSX.Element => {
  return (
    <Box w={'100%'}>
      <Box className="transcription-text">
        {transcription
          .split('\n')
          .map((line, index) => {
            const speakerMatch = line.match(/^(Speaker \d+(?:\s\([^)]+\))?):\s*(.*)$/);

            if (speakerMatch) {
              const [, speakerPrefix, restOfText] = speakerMatch;

              return (
                <Text c="#101828" className="transcription-text-line" key={index} >
                  <span className="speaker-prefix">{speakerPrefix}:</span> {restOfText}
                  <br />
                </Text>
              );
            }

            return null;
          })
          .filter(Boolean)}
      </Box>
    </Box>
  );
};

export default TelehealthTranscription;
