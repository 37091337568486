import { Avatar, Box, Flex, Paper, Text } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconBell } from '@tabler/icons-react';
import { Notification } from '../utils/interface';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../utils/util';
import { Appointment } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { useAppContext } from '../AppProvider';

interface NotificationItemProps {
  notification: Notification;
  closeNotification: () => void;
}

const NotificationItem = ({ notification, closeNotification }: NotificationItemProps): JSX.Element => {
  const { hovered, ref } = useHover();
  const medplum = useMedplum();
  const { setIsJoinCallDrawerOpen, setSelectedAppointment } = useAppContext();

  const navigate = useNavigate();

  const handleClick = async (notification: Notification): Promise<void> => {
    if (notification?.id) {
      if (notification?.type === 'Telehealth') {
        setIsJoinCallDrawerOpen(true);
        const appointment: Appointment = await medplum.readResource(
          'Appointment',
          notification?.references?.[0]?.reference.split('/')[1] as string
        );
        setSelectedAppointment(appointment);
        closeNotification();
      } else if (notification?.type === 'Assessment') {
        navigate(`/Assessments`);
      }
    }
  };

  const getNotificationArrivalTime = (notification: Notification): string => {
    if (notification?.createdAt) {
      const now = new Date().getTime();
      const createdAt = new Date(notification?.createdAt).getTime();
      const diff = now - createdAt;

      if (diff < 60000) {
        return 'Just now';
      } else if (diff < 3600000) {
        return `${Math.floor(diff / 60000)}m ago`;
      } else if (diff < 86400000) {
        return `${Math.floor(diff / 3600000)}h ago`;
      }

      return formatDate(new Date(notification?.createdAt), 'hh:mm a');
    } else {
      return '';
    }
  };

  return (
    <>
      <Paper
        title={`Go to ${notification?.title}`}
        onClick={() => {
          handleClick(notification).catch((error) => {
            console.error('Error handling click:', error);
          });
        }}
        bd={hovered ? '1px solid #d1d5db' : '1px solid transparent'}
        key={notification?.id}
        ref={ref}
        shadow={hovered ? '0px 0px 10px rgba(0, 0, 0, 0.15)' : 'none'}
        py={10}
        mb={10}
        style={{ cursor: 'pointer', transition: 'all 0.3s ease' }}
        className="tw-flex tw-items-center tw-justify-between tw-mt-3 tw-p-2 tw-rounded-lg tw-cursor-pointer tw-border tw-border-transparent hover:tw-border-gray-400 hover:tw-bg-[white] hover:tw-scale-[1.02]"
      >
        <Flex justify="space-between">
          <Avatar bg="#eaf6f745" size={27}>
            <IconBell size={16} color="#3CA5A9" />
          </Avatar>
          <Box ml={10}>
            <Flex justify={'space-between'} align="center">
              <Text size="14px" fw={600} lh={'28px'}>
                {notification?.title}
              </Text>
              <Text fw={400} size="10px" c={'var(--label-color-2)'} lh={'20px'}>
                {getNotificationArrivalTime(notification)}
              </Text>
            </Flex>
            <Text fw={400} size="12px" lh={'20px'} c={'var(--label-color-2)'}>
              {notification?.description?.replace(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/g, (date) =>
                formatDate(new Date(date), 'dd, MMM hh:mm a')
              ) || 'No description'}
            </Text>
          </Box>
        </Flex>
      </Paper>
    </>
  );
};

export default NotificationItem;
