import { ActionIcon, Avatar, Badge, Box, Divider, Flex, LoadingOverlay, Paper, Text, Tooltip } from '@mantine/core';
import { IconBell, IconRefresh, IconX } from '@tabler/icons-react';
import NotificationItem from './NotificationItem';
import { useHover } from '@mantine/hooks';
import { useAppContext } from '../AppProvider';
import { useMedplum } from '@medplum/react-hooks';
import { useEffect } from 'react';
import { handleReadNotification } from '../utils/util';

interface HeaderDropdownNotificationProps {
  onClose: () => void;
  fetchNotifications: () => Promise<void>;
  loading: boolean;
}
const HeaderDropdownNotification = ({
  onClose,
  fetchNotifications,
  loading,
}: HeaderDropdownNotificationProps): JSX.Element => {
  const { hovered, ref } = useHover();
  const { notifications, setUnreadNotifications, totalNotifications } = useAppContext();
  const medplum = useMedplum();

  useEffect(() => {
    const markNotificationsAsRead = async (): Promise<void> => {
      await Promise.all(
        notifications.slice(0, 4).map((notification) => handleReadNotification(notification?.id, medplum))
      );
      setUnreadNotifications(0);
    };

    markNotificationsAsRead().catch((error) => {
      console.error('Error marking notifications as read:', error);
    });
  }, [notifications]);

  return (
    <Paper radius="12px">
      <Box p={'1rem'} mih={'3rem'}>
        <Flex align={'center'} justify={'space-between'}>
          <Flex align={'center'} gap={'0.5rem'} ml={'0.45rem'}>
            <Avatar bg="var(--icon-bg)" size={30}>
              <IconBell size={18} color="var(--icon-color)" />
            </Avatar>
            <Text size="16px" fw={600} lh={'28px'}>
              Notifications
            </Text>
            <Badge variant="outline" bg={'#EFF8FF'} color="#B2DDFF">
              <Text c={'#175CD3'} size="12px" lh={'20px'}>
                {totalNotifications || 0}
              </Text>
            </Badge>
          </Flex>
          <Flex align={'center'} gap={'0.1rem'}>
            <Tooltip label="Refresh">
              <ActionIcon
                loading={loading}
                onClick={async () => {
                  await fetchNotifications();
                }}
                variant="subtle"
                aria-label="Refresh"
              >
                <IconRefresh size={18} stroke={2.5} color="#667085" />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Close">
              <ActionIcon onClick={onClose} variant="subtle" aria-label="Close">
                <IconX size={18} stroke={2.5} color="#667085" />
              </ActionIcon>
            </Tooltip>
          </Flex>
        </Flex>
        <Divider my={'1rem'} w={'100%'} />
        <Box pos="relative">
          <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: 'sm', blur: 2 }} />
          {notifications.slice(0, 4).map((notification) => {
            return <NotificationItem key={notification?.id} notification={notification} closeNotification={onClose} />;
          })}
        </Box>
        <Divider mb={'1rem'} w={'100%'} />
        <Flex align={'center'} justify={'center'}>
          <Text
            ref={ref}
            size="14px"
            c={hovered ? 'var(--icon-color)' : 'var(--main-dark)'}
            fw={500}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              window.location.href = '/Notifications';
            }}
          >
            View all
          </Text>
        </Flex>
      </Box>
    </Paper>
  );
};

export default HeaderDropdownNotification;
