import { Badge, Box, Divider, Paper, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import NotificationTable from './NotificationTable';
import { useAppContext } from '../../AppProvider';
import { getNotifications } from '../../utils/CustomAPI';
import { useMedplum } from '@medplum/react';
import CustomPagination from '../../components/CustomPagination';
import PatientPractitionerSelect from '../../components/PatientPractitionerSelect';
import CommonDrawer from '../../components/CommonDrawer';

const Notifications = (): JSX.Element => {
  const {
    notifications,
    setNotifications,
    setTotalNotifications,
    totalNotifications,
    selectedAppointment,
    isJoinCallDrawerOpen,
    setIsJoinCallDrawerOpen,
  } = useAppContext();
  const [loading, setLoading] = useState(true);
  const medplum = useMedplum();
  const [selectedPatient, setSelectedPatient] = useState<string>();
  const [offset, setOffset] = useState<number>(0);
  const itemsPerPage = 20;

  useEffect(() => {
    const fetchNotifications = async (): Promise<void> => {
      try {
        setLoading(true);
        const response = await getNotifications(medplum, selectedPatient, offset, itemsPerPage);
        setNotifications(response?.notifications || []);
        setTotalNotifications(response?.total || 0);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications().catch((err) => console.error(err));
  }, [medplum, offset, selectedPatient]);

  const handlePatientSelect = (resource: { value: string; label: string } | undefined): void => {
    setSelectedPatient(resource?.value);
    setOffset(0);
  };

  const handlePageChange = (page: number): void => {
    setOffset((page - 1) * itemsPerPage);
  };

  const currentPage = Math.floor(offset / itemsPerPage) + 1;
  const totalPages = Math.ceil(totalNotifications / itemsPerPage);

  return (
    <>
      {isJoinCallDrawerOpen && selectedAppointment && (
        <CommonDrawer
          type="JoinCall"
          title="Join The Call"
          subTitle={`Appointment details with ${selectedAppointment.participant?.[0]?.actor?.display || 'Patient'}`}
          opened={isJoinCallDrawerOpen}
          onClose={() => setIsJoinCallDrawerOpen(false)}
          appointment={selectedAppointment}
        />
      )}
      <div>
        <Paper radius={'12px'} className="page-container">
          <Box m="md" mb={'1.5rem'} className="page-header">
            <Text className="page-header-title">Notifications</Text>
            <Badge className="badge-style">{totalNotifications} Records</Badge>
          </Box>
          <Divider />
          <Box mt={'1rem'} mb={'1rem'} ml={'0.5rem'}>
            <PatientPractitionerSelect onResourceSelect={handlePatientSelect} label={true} />
          </Box>
          <NotificationTable notifications={notifications} loading={loading} />
          {totalNotifications > 0 && (
            <CustomPagination total={totalPages} value={currentPage} onChange={handlePageChange} />
          )}
        </Paper>
      </div>
    </>
  );
};

export default Notifications;
