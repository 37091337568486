import { Tabs } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { normalizeErrorString, Operator, SearchRequest } from '@medplum/core';
import { Patient, Resource } from '@medplum/fhirtypes';
import { Document, ResourceForm, ResourceHistoryTable, ResourceTable, SearchControl, useMedplum } from '@medplum/react';
import { IconCircleCheck, IconCircleOff } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { ClinicalImpressionDisplay } from './ClinicalImpressionDisplay';
import { PatientObservations } from './PatientObservations';
import { cleanResource } from '../../resource/utils';
import { EncounterTab } from '../../pages/patient/EncounterTab';
import { TimelineTab } from '../../pages/patient/TimelineTab';

interface PatientDetailsProps {
  patient: Patient;
  onChange: (patient: Patient) => void;
}

export function PatientDetails(props: PatientDetailsProps): JSX.Element {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const id = props.patient.id;

  const tabs = [
    ['details', 'Details'],
    ['timeline', 'Timeline'],
    ['edit', 'Edit'],
    ['history', 'History'],
    ['encounter', 'Encounters'],
    ['clinical', 'Clinical Impressions'],
    ['observations', 'Observations'],
    ['medications', 'Medications'],
    ['appointments', 'Appointments'],
    ['documents', 'Documents'],
    ['reports', 'Reports'],
    ['tasks', 'Tasks'],
  ];

  const searchRequests: { [key: string]: SearchRequest } = {
    medications: {
      resourceType: 'MedicationRequest',
      filters: [{ code: 'patient', operator: Operator.EQUALS, value: `Patient/${id}` }],
      fields: ['medication[x]', 'intent', 'status'],
    },
    appointments: {
      resourceType: 'Appointment',
      filters: [{ code: 'patient', operator: Operator.EQUALS, value: `Patient/${id}` }],
      fields: ['_lastUpdated', 'patient', 'status'],
    },
    documents: {
      resourceType: 'DocumentReference',
      filters: [{ code: 'patient', operator: Operator.EQUALS, value: `Patient/${id}` }],
      fields: ['_lastUpdated', 'category', 'type', 'status'],
    },
    reports: {
      resourceType: 'DiagnosticReport',
      filters: [{ code: 'patient', operator: Operator.EQUALS, value: `Patient/${id}` }],
      fields: ['_lastUpdated', 'category', 'code', 'status'],
    },
    tasks: {
      resourceType: 'Task',
      filters: [{ code: 'patient', operator: Operator.EQUALS, value: `Patient/${id}` }],
      fields: ['_lastUpdated', 'status', 'code', 'focus'],
    },
  };

  const tabComponents: { [key: string]: JSX.Element } = {
    details: <ResourceTable value={props.patient} />,
    timeline: <TimelineTab />,
    edit: <ResourceForm defaultValue={props.patient} onSubmit={handlePatientEdit} />,
    history: <ResourceHistoryTable resourceType="Patient" id={id} />,
    encounter: <EncounterTab />,
    clinical: <ClinicalImpressionDisplay patient={props.patient} />,
    observations: <PatientObservations patient={props.patient} />,
  };

  const tab = window.location.pathname.split('/').pop();
  const currentTab = tab && tabs.map((t) => t[0]).includes(tab) ? tab : tabs[0][0];

  function handleTabChange(newTab: string | null): void {
    navigate(`/Patient/${id}/${newTab ?? ''}`);
  }

  function handlePatientEdit(resource: Resource): void {
    medplum
      .updateResource(cleanResource(resource))
      .then((patient: any) => {
        props.onChange(patient as Patient);
        showNotification({
          icon: <IconCircleCheck />,
          title: 'Success',
          message: 'Patient edited',
        });
        navigate(`/Patient/${id}/details`);
        window.scrollTo(0, 0);
      })
      .catch((err: unknown) => {
        showNotification({
          color: 'red',
          icon: <IconCircleOff />,
          title: 'Error',
          message: normalizeErrorString(err),
        });
      });
  }

  return (
    <Document className="Patient_charting">
      <Tabs value={currentTab.toLowerCase()} onChange={handleTabChange}>
        <Tabs.List mb="xs">
          {tabs.map((tab) => (
            <Tabs.Tab value={tab[0]} key={tab[0]}>
              {tab[1]}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {tabs.map(([tabKey]) => (
          <Tabs.Panel value={tabKey} key={tabKey}>
            {tabComponents[tabKey] || (
              <SearchControl
                search={searchRequests[tabKey]}
                hideFilters={true}
                hideToolbar={true}
                onClick={(e: any) =>
                  navigate(`/${e.resource.resourceType}/${e.resource.id}`)
                }
              />
            )}
          </Tabs.Panel>
        ))}
      </Tabs>
    </Document>
  );
}