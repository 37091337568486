/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from "react";
import {Group, Button, Textarea } from "@mantine/core";

interface ChatInputProps {
  onSendMessage: (message: string) => void;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage }) => {
  const [message, setMessage] = useState("");

  const handleSend = () => {
    if (message.trim()) {
      onSendMessage(message);
      setMessage("");
    }
  };

  return (
    <Group
      px="md"
      py="sm"
      style={(theme) => ({
        borderTop: `1px solid ${theme.colors.gray[3]}`,
        backgroundColor: theme.white,
        justifyContent: "space-between",
        flexWrap: "nowrap",
        position: 'fixed', 
        bottom: 0, 
        background: '#fff',
        width: '-webkit-fill-available',
      })}
      className="chat-input"
    >
      <Textarea
        placeholder="Send a message"
        value={message}
        onChange={(e) => setMessage(e.currentTarget.value)}
        onKeyPress={(e) => e.key === "Enter" && handleSend()}
        minRows={2}
        autosize
        style={{
          flex: 1,
          ".mantine-TextInput-input": {
            paddingRight: "70px",
            paddingLeft: "20px",
            height: "80px",
          },
        }}
        rightSectionWidth={70}
        rightSection={
          <Group>
            <Button
              radius="md"
              className="btn_bg"
              onClick={handleSend}
              style={{ height: "35px", paddingInline: "15px" }}
            >
              Send
            </Button>
          </Group>
        }
      />
    </Group>
  );
};

export default ChatInput;
