import { Badge, Box, Button, Divider, Paper, Text } from '@mantine/core';
import AssessmentsTable from './AssessmentsTable';
import { useEffect, useState } from 'react';
import { useMedplum } from '@medplum/react';
import CustomPagination from '../../components/CustomPagination';
import { IconPlus } from '@tabler/icons-react';
import { getAssessmentList } from '../../utils/CustomAPI';
import PatientPractitionerSelect from '../../components/PatientPractitionerSelect';

export interface Assessment {
  patientName: string;
  assessmentName: string;
  title: string;
  lastUpdated: string;
  status: string;
  questionnaireResponseId: string | null;
  assessmentId: string;
}

export interface AssessmentResponse {
  assessments: Assessment[];
  total: number;
}

const Assessments = (): JSX.Element => {
  const medplum = useMedplum();
  const [assessments, setAssessments] = useState<Assessment[]>([]);
  const [selectedPatient, setSelectedPatient] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [offset, setOffset] = useState(0);
  const itemsPerPage = 20;

  useEffect(() => {
    const fetchAssessments = async (): Promise<void> => {
      try {
        setLoading(true);

        const response: AssessmentResponse | null = await getAssessmentList(
          medplum,
          selectedPatient,
          offset,
          itemsPerPage
        );

        setAssessments(response?.assessments || []);
        setTotalItems(response?.total || 0);
      } catch (error) {
        console.error('Error fetching assessments:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssessments().catch((err) => console.error(err));
  }, [medplum, offset, selectedPatient]); // Add selectedId to dependency array

  const handlePatientSelect = (resource: { value: string; label: string } | undefined): void => {
    setSelectedPatient(resource?.value);
    setOffset(0); // Reset pagination when patient changes
  };

  const handlePageChange = (page: number): void => {
    setOffset((page - 1) * itemsPerPage);
  };

  const currentPage = Math.floor(offset / itemsPerPage) + 1;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div>
      <Paper radius={'12px'} className="page-container">
        <Box m="md" mb={'1.5rem'} className="page-header">
          <Text className="page-header-title">Assessments</Text>
          <Badge className="badge-style">{totalItems} Records</Badge>
          <Button
            className={`addnew-button-default button-default btn_bg`}
            style={{ marginLeft: 'auto' }}
            onClick={() => {
              window.location.href = '/Questionnaire/new';
            }}
          >
            <IconPlus size={20} style={{ marginRight: '4px' }} />
            New
          </Button>
        </Box>
        <Divider />
        <Box mt={'1rem'} mb={'1rem'} ml={'0.5rem'}>
          <PatientPractitionerSelect onResourceSelect={handlePatientSelect} label={true} />
        </Box>
          <AssessmentsTable assessments={assessments} loading={loading} data={assessments} />
        {totalItems > 0 && <CustomPagination total={totalPages} value={currentPage} onChange={handlePageChange} />}
      </Paper>
    </div>
  );
};

export default Assessments;
