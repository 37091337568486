/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { Text, Switch, Drawer, Box } from '@mantine/core';
import InstantAppointmentForm from '../../scheduling/components/InstantAppointmentForm';
import { useLocation } from 'react-router-dom';
import { CreateAppointment } from '../../scheduling/components/actions/CreateAppointment';
import { useMedplum } from '@medplum/react';
import { Schedule } from '@medplum/fhirtypes';
import { useAppContext } from '../../AppProvider';
import { Event } from 'react-big-calendar';

interface TelehealthDrawerProps {
  event?: Event | undefined;
  isOpen: boolean;
  onClose: () => void;
  onStartCall: (id: string) => void;
  onSlotsUpdated?: () => void;
}

export const TelehealthDrawer: React.FC<TelehealthDrawerProps> = ({ isOpen, onClose, onStartCall, event, onSlotsUpdated }) => {
  const location = useLocation();
  const medplum = useMedplum();
  const { scheduleId } = useAppContext();
  useEffect(() => {
    if (location.pathname.includes('/Telehealth')) {
      setMeetingType('instant');
    } else if (location.pathname.includes('/Schedule')) {
      setMeetingType('schedule');
    }
  }, [location]);
  const [title, setTitle] = useState<string>('');
  const [practitioner, setPractitioner] = useState<string | undefined>(undefined);
  const [patient, setPatient] = useState<string | undefined>(undefined);
  const [meetingType, setMeetingType] = useState<'instant' | 'schedule'>('instant');
  const [patients, setPatients] = useState<{ value: string; label: string }[]>([]);
  const [isStarting, setIsStarting] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<Schedule>();
  const patientName = patients?.[0]?.label ?? undefined;

  // Function to close the dialog and reset states
  const closeDialog = (): void => {
    setIsStarting(false);
    setTitle('');
    setPractitioner(undefined);
    setPatient(undefined);
    onClose();
  };

  // First load the schedule
  useEffect(() => {
    if (scheduleId) {
      medplum
        .readResource('Schedule', scheduleId)
        .then((loadedSchedule) => {
          setSchedule(loadedSchedule);
        })
        .catch((error) => {
          console.error('Error loading schedule:', error);
        });
    }
  }, [medplum, scheduleId]);

  return (
    <Drawer
      className="drawer"
      position="right"
      opened={isOpen}
      onClose={closeDialog}
      title={
        <Box className="header">
          <Text className="header-txt">Create a Telehealth Meeting</Text>

          <Text className="sub-header-txt">
            Enter details to start a meeting with <span className="patient-name">{patientName ?? 'Patient'}</span>
          </Text>
        </Box>
      }
      size="40%"
    >
      <Box className="meeting-type-switch">
        <Text
          style={{
            fontWeight: meetingType === 'schedule' ? '500' : '400',
            fontSize: '14px',
            color: meetingType === 'schedule' ? '#4DB5BA' : '#A0A0A0',
          }}
        >
          Schedule
        </Text>
        <Switch
          defaultChecked={meetingType === 'instant'}
          className="checkbox"
          size="sm"
          onChange={() => setMeetingType(meetingType === 'instant' ? 'schedule' : 'instant')}
          my="md"
        />
        <Text
          style={{
            fontWeight: meetingType === 'instant' ? '500' : '400',
            fontSize: '14px',
            color: meetingType === 'instant' ? '#4DB5BA' : '#A0A0A0',
          }}
        >
          Instant
        </Text>
      </Box>
      {meetingType === 'instant' ? (
        <InstantAppointmentForm
          meetingType={meetingType}
          title={title}
          practitioner={practitioner}
          patient={patient}
          setPatient={setPatient}
          isStarting={isStarting}
          setIsStarting={setIsStarting}
          closeDialog={closeDialog}
          onStartCall={onStartCall}
          setTitle={setTitle}
          setPatients={setPatients}
          patients={patients}
        />
      ) : (
        <CreateAppointment event={event || undefined} schedule={schedule as Schedule} onSlotsUpdated={onSlotsUpdated} closeDialog={closeDialog} />
      )}
    </Drawer>
  );
};
