import { ActionIcon, Box, Center, Table } from '@mantine/core';
import { formatDate } from '../../utils/util';
import { IconEye } from '@tabler/icons-react';
import AssessmentQuestionsDrawer from './AssessmentQuestionsDrawer';
import { useState } from 'react';
import { StatusBadge } from '../../components/StatusBadge';
import { STATUS_COLORS } from '../../utils/constant';
import { Assessment } from './Assessments';

interface AssessmentsTableProps {
  assessments: Assessment[];
  loading: boolean;
  data: any;
}

const AssessmentsTable = ({ assessments, loading }: AssessmentsTableProps): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [questionnaireResponseId, setQuestionnaireResponseId] = useState('');

  const getStatusLabel = (status: string): string => {
    if (status === 'retired') {
      return 'Completed';
    } else {
      return status;
    }
  };

  const rows = assessments.map((assessment) => (
    <Table.Tr key={assessment.assessmentId} fz={'14px'} lh={'20px'} fw={400} c={'#475467'}>
      <Table.Td w={200} fw={500} fz={'14px'} lh={'20px'} c={'#101828'}>
        {assessment.patientName || '-'}
      </Table.Td>
      <Table.Td w={400}>{assessment.assessmentName || 'No title'}</Table.Td>
      <Table.Td>{assessment.title || '-'}</Table.Td>
      <Table.Td>
        {assessment.lastUpdated ? formatDate(new Date(assessment.lastUpdated), 'MMMM dd, yyyy') : '-'}
      </Table.Td>
      <Table.Td>
        <StatusBadge
          statusLabel={assessment?.status === 'active' ? 'New' : getStatusLabel(assessment?.status || '')}
          statusColor={STATUS_COLORS[assessment.status as keyof typeof STATUS_COLORS]}
          textColor={STATUS_COLORS[assessment.status as keyof typeof STATUS_COLORS]}
        />
      </Table.Td>
      <Table.Td>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <ActionIcon
            variant="subtle"
            title={
              assessment?.status === 'retired' || assessment?.status === 'completed'
                ? 'View Assessment'
                : 'Waiting for assessment to be completed'
            }
            disabled={!(assessment?.status === 'retired' || assessment?.status === 'completed')}
            onClick={() => {
              setIsDrawerOpen(true);
              setQuestionnaireResponseId(assessment?.questionnaireResponseId || '');
            }}
          >
            <IconEye stroke={1.4} color="#030303" />
          </ActionIcon>
        </Box>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <Table className="table-container">
        <Table.Thead className="table-header">
          <Table.Tr>
            <Table.Th>Patient Name</Table.Th>
            <Table.Th>Assessment Name</Table.Th>
            <Table.Th>Title</Table.Th>
            <Table.Th>Last Updated</Table.Th>
            <Table.Th>Status</Table.Th>
            <Table.Th>Assessment Response</Table.Th>
          </Table.Tr>
        </Table.Thead>
        {loading && (
          <tr>
            <td colSpan={8}>
              <Center>Loading...</Center>
            </td>
          </tr>
        )}
        {!loading && assessments.length === 0 && (
          <tr>
            <td colSpan={8}>
              <Center>No data found</Center>
            </td>
          </tr>
        )}
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
      <AssessmentQuestionsDrawer
        opened={isDrawerOpen}
        close={() => {
          setIsDrawerOpen(false);
        }}
        questionnaireResponseId={questionnaireResponseId}
        assessmentName={
          assessments.find((assessment) => assessment.questionnaireResponseId === questionnaireResponseId)
            ?.assessmentName
        }
      />
    </>
  );
};

export default AssessmentsTable;
