/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Paper } from '@mantine/core';
import { formatSearchQuery, parseSearchRequest, SearchRequest } from '@medplum/core';
import { Loading, useMedplum } from '@medplum/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from '../HomePage.module.css';
import { addSearchValues, RESOURCE_TYPE_CREATION_PATHS, saveLastSearch } from '../HomePage.utils';
import { MemoizedSearchControl } from './SearchControl';
import Appointments from './Appointments/Appointments';
import AddPatient from './AddPatient/AddPatient';
import CommonDrawer from '../components/CommonDrawer';

export function ListingPage(): JSX.Element {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState<SearchRequest>();
  const [isAppointment, setIsAppointment] = useState<boolean>(false);
  const [isAddPatient, setIsAddPatient] = useState(false);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  
  useEffect(() => {
    // Parse the search from the URL
    const parsedSearch = parseSearchRequest(location.pathname + location.search);

    // Fill in the search with default values
    const populatedSearch = addSearchValues(parsedSearch, medplum.getUserConfiguration());

    if (
      location.pathname === `/${populatedSearch.resourceType}` &&
      location.search === formatSearchQuery(populatedSearch)
    ) {
      // If the URL matches the parsed search, then save it and execute it
      saveLastSearch(populatedSearch);
      setSearch(populatedSearch);
    } else {
      // Otherwise, navigate to the desired URL
      navigate(`/${populatedSearch.resourceType}${formatSearchQuery(populatedSearch)}`);
    }
  }, [medplum, navigate, location]);

  const closeAppointment = (data: string) => {
    if (data === 'addNew') {
      setIsAddPatient(true);
    }
    setIsAppointment(false);
  }
  
  if (!search?.resourceType || !search.fields || search.fields.length === 0) {
    return <Loading />;
  }

  return (
    <Paper className={classes.paper}>
      <MemoizedSearchControl
        checkboxesEnabled={true}
        search={search}
        onClick={(e) => navigate(`/${e.resource.resourceType}/${e.resource.id}`)}
        onChange={(e) => {
          navigate(`/${search.resourceType}${formatSearchQuery(e.definition)}`);
        }}
        onNew={() => {
          if(location.pathname === '/Appointment') {
            setIsAppointment(true);
          } else if (location.pathname === '/Patient') {
            setIsAddPatient(true);
          } else if (search.resourceType === 'Communication') {
            setIsDrawerOpened(true);
          } else {
            navigate(RESOURCE_TYPE_CREATION_PATHS[search.resourceType] ?? `/${search.resourceType}/new`);
          }
        }}
      />
      {isAppointment && (
        <Appointments opened={isAppointment} close={(data) => closeAppointment(data)} appointmentId=""/>
      )}
      {isAddPatient && (
          <AddPatient opened={isAddPatient} patientClose={() => setIsAddPatient(false)} patientId="" patientData=''/>
      )}
      {isDrawerOpened && (
        <CommonDrawer opened={isDrawerOpened} onClose={() => setIsDrawerOpened(false)} type={search.resourceType} title="Connect with Your Patient." subTitle="Communicate securely and get real-time updates."/>
      )}
    </Paper>
  );
}
