import React, { useEffect, useRef } from "react";
import { Text, Group, Box } from "@mantine/core";
import { ResourceAvatar } from "@medplum/react";

interface ChatMessageProps {
  sender: "provider" | "patient";
  message: string;
  time: string;
  subject?: { reference: string; display: string };
}

const ChatMessage: React.FC<ChatMessageProps> = ({ sender, message, time, subject }) => {
  const messageEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <Group mt={15} align="flex-start" style={{ justifyContent: sender === "provider" ? "flex-end" : "flex-start" }} >
      {sender === "patient" && <ResourceAvatar radius="xl" size="md" value={subject} />}
      <div style={{ textAlign: sender === "provider" ? "right" : "left", maxWidth: "75%",
        width: message.length > 50 ? "35%" : "auto", wordBreak: "break-word", }}>
        <Text size="xs" color="dimmed">
          {sender === "provider" ? "You" : subject?.display} • {time}
        </Text>
        <Box
          p={10}
          mt="xs"
          ta="left"
          style={(theme) => ({
            backgroundColor: sender === "provider" ? '#4DB5BA' : '#F9FAFB',
            color: sender === "provider" ? theme.white : theme.black,
            border: sender === "provider" ? '1px solid #3CA5A9' : '1px solid #EAECF0',
            borderRadius: '10px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
          })}
        >
          {message}
        </Box>
      </div>
      <div ref={messageEndRef} />
    </Group>
  );
};

export default ChatMessage;
