import { Box, Select, Text } from '@mantine/core';
import { useCallback, useEffect, useState, useRef } from 'react';
import { fetchPatients } from '../utils/CustomAPI';
import { useMedplumContext } from '@medplum/react';

const defaultQuery = (type: 'PatientList' | 'PractitionerList', searchTerm: string): string => `{
  ${type}(name: "${searchTerm}", _count: 1000) {
    resourceType
    id
    name {
      family
      given
    }
  }
}`;

interface PatientPractitionerSelectProps {
  practitioner?: boolean;
  onResourceSelect?: (resource: { value: string; label: string } | undefined) => void;
  label?: boolean;
}

const PatientPractitionerSelect = ({
  practitioner = false,
  onResourceSelect,
  label,
}: PatientPractitionerSelectProps): JSX.Element => {
  const selectRef = useRef<HTMLInputElement>(null); // Add ref for the Select
  const [resources, setResources] = useState<{ value: string; label: string }[]>([]);
  const [selectedValue, setSelectedValue] = useState<string | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { medplum } = useMedplumContext();
  const selectLabel = practitioner ? 'Practitioner' : 'Patient';
  const resourceType = practitioner ? 'PractitionerList' : 'PatientList';

  // Function to fetch resources (patients or practitioners) based on search term
  const fetchResources = useCallback(
    (
      type: 'PatientList' | 'PractitionerList',
      searchTerm: string,
      setSelectedValue: React.Dispatch<React.SetStateAction<{ value: string; label: string }[]>>
    ) => {
      if (searchTerm.length >= 3) {
        fetchPatients(medplum, defaultQuery(type, searchTerm))
          .then(
            (response: { data: { [key: string]: { id: string; name: { given: string[]; family: string }[] }[] } }) => {
              const resourceList = response.data[type].map((resource) => {
                const givenName = resource?.name?.[0]?.given?.join(' ') || '';
                const familyName = resource?.name?.[0]?.family || '';
                return { value: resource.id, label: `${givenName} ${familyName}` };
              });
              setSelectedValue(resourceList);
            }
          )
          .catch((error: Error) => console.error(error));
      } else {
        setSelectedValue([]);
      }
    },
    [medplum]
  );

  // Function to handle resource selection
  const handleResourceSelect = (value: string | null): void => {
    const selectedResource = resources.find((resource) => resource.value === value) ?? undefined;
    setSelectedValue(value ?? undefined);
    setSearchTerm('');
    if (onResourceSelect) {
      onResourceSelect(selectedResource);
    }
    selectRef.current?.blur();
  };

  // Effect to fetch resources when search term changes
  useEffect(() => {
    if (searchTerm.length >= 3) {
      fetchResources(resourceType, searchTerm, setResources);
    }
  }, [searchTerm, fetchResources, resourceType]);

  return (
    <Box className="flex-container">
      {!label && (
        <Text span w={'35%'} className="title-txt">
          {`Select ${practitioner ? 'Practitioner' : 'Patient'}`}
          <Text span c={'red'}>
            {' '}
            *
          </Text>
        </Text>
      )}
      <Select
        ref={selectRef}
        placeholder={`Select ${selectLabel}`}
        className="meeting-title-input-box"
        data={resources}
        value={selectedValue}
        onChange={handleResourceSelect}
        searchValue={searchTerm}
        onSearchChange={setSearchTerm}
        searchable
        required
        clearable
        onClear={() => { // Add explicit onClear handler
          setSelectedValue(undefined);
          setSearchTerm('');
          if (onResourceSelect) {
            onResourceSelect(undefined);
          }
        }}
      />
    </Box>
  );
};

export default PatientPractitionerSelect;
