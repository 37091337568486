/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Box, Drawer, Text } from "@mantine/core";
import AddCommunication from "../dashboard/Messages/AddCommunication";
import JoinCall from "../dashboard/Telehealth/JoinCall";
import { Appointment } from "@medplum/fhirtypes";

interface CommonDrawerProps {
  opened: boolean;
  onClose: () => void;
  type: string;
  title: string;
  subTitle: string;
  appointment?: Appointment;
}

const CommonDrawer: React.FC<CommonDrawerProps> = ({ opened, onClose, type, title, subTitle, appointment }) => {
  const renderContent = () => {
    switch (type) {
      case "Communication":
        return <AddCommunication close={onClose}/>;
      case "JoinCall":
        return <JoinCall appointment={appointment as Appointment} />;
      default:
        return <div>Default Content</div>;
    }
  };

  return (
    <Drawer 
      opened={opened} 
      onClose={onClose} 
      title={
        <Box className="header">
          <Text className="header-txt">{title}</Text>
          <Text className="sub-header-txt">
            {subTitle}
          </Text>
        </Box>
      }
      offset={1} 
      radius="md"
      position="right"
      className="drawer"
      size="40%"
    >
      {renderContent()}
    </Drawer>
  );
};

export default CommonDrawer;
