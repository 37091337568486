/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ActionIcon, Box, Button, Loader, Textarea, Title } from '@mantine/core';
import { IconMicrophone, IconPlayerStopFilled } from '@tabler/icons-react';
import React, { useEffect, useRef, useState } from 'react';
import { updateChatHistoryTitle, getAiAssistantChatHistory, getAiAssistantPatientData } from '../../utils/CustomAPI';
import AutoCompleteSearch from '../AIAssistant/AutoCompleteSearch';
import './FhirBot.css';
import { FeatureDescriptions } from '../AIAssistant/FeatureDescriptions';

import {
  Chat,
  ConversationHistoryData,
  FhirBotDetail,
  Patient,
  PayloadItem,
  Resource,
} from '../AIAssistant/interfaces';
import FhirBotDetails from '../AIAssistant/FhirBotDetails';
import { useMedplum } from '@medplum/react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useAppContext } from '../../AppProvider';

const FhirBot = () => {
  const audioChunksRef = useRef<Blob[]>([]);
  const medplum = useMedplum();
  const [recording, setRecording] = React.useState(false);
  const [patients, setPatients] = useState<{ label: string; value: string }[]>([]);
  const [selectedPatient, setSelectedPatient] = useState<string | null>(null);
  const searchInputRef = useRef(null);
  const patientName: string = '';
  const aiModel = useRef<string | null>('chatgpt');
  const [loading, setLoading] = useState(false);
  const inputRef = React.createRef<HTMLTextAreaElement>();
  const intervalRef = useRef<number | null>(null);
  const [fhirBotDetails, setFhirBotDetails] = useState<FhirBotDetail[]>([]);
  const [isGetHistorySessionId, setIsGetHistorySessionId] = useState<boolean>(false);
  const [conversationSessionId, setConversationSessionId] = useState('');
  const [converstionHistoryList, setConverstionHistoryList] = useState<{ resource: Resource }[]>([]);
  const [updateQuestionTitle, setUpdateQuestionTitle] = useState<boolean>(false);
  const [isSelectingPatient, setIsSelectingPatient] = useState<boolean>(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [instruction, setInstruction] = useState('');
  const { fhirBotDetailsUpdated, setfhirBotDetailsUpdatedUpdated, sessionId, setSessionId } = useAppContext();

  useEffect(() => {
    if (selectedPatient && patients.length > 0 && !isGetHistorySessionId) {
      fetchData();
    }
  }, [selectedPatient]);

  const updateChatHistoryTitleAPI = async () => {
    await updateChatHistoryTitle(medplum);
  };

  const fetchData = async () => {
    setIsSelectingPatient(true);
    setFhirBotDetails([]);
    setfhirBotDetailsUpdatedUpdated([]);
    setSessionId('');
    const response = await getAiAssistantPatientData(
      medplum,
      selectedPatient as string,
      'Patient Static First Question',
      ''
    );
    const data = response?.data?.result;
    const parsedData = JSON.parse(data);
    setUpdateQuestionTitle(true);
    setConversationSessionId(response?.data?.sessionId);

    const patientName = patients?.find((patient: Patient) => patient.value === selectedPatient)?.label ?? '';

    setFhirBotDetails([
      {
        description: parsedData.question.replace(/_/g, ' '),
        loading: false,
        isSystemMessage: true,
        detectedIntent: response?.data?.detectedIntent,
        selectedPatient: selectedPatient ?? undefined,
        patientName: patientName,
        title: '',
      },
    ]);
    setIsSelectingPatient(false);
  };

  const resetChatHistory = async () => {
    fetchData();
    setFhirBotDetails([]);
    setInstruction('');
    setConversationSessionId('');

    await updateChatHistoryTitleAPI()
      .catch((error) => {
        console.error(error);
      })
      .then(() => {
        getConverstionsHistory().catch((error) => {
          console.error(error);
        });
      });
  };

  useEffect(() => {
    if (selectedPatient) {
      getConverstionsHistory().catch((error) => {
        console.error(error);
      });

      updateChatHistoryTitleAPI().catch((error) => {
        console.error(error);
      });
    }
  }, [selectedPatient]);

  const getConverstionsHistory = async () => {
    const data: ConversationHistoryData = await getAiAssistantChatHistory(medplum, selectedPatient as string);
    const filteredEntries = data.entry.filter((entry) => {
      const payloadLength = entry.resource.payload.length;
      return payloadLength > 1;
    });
    setConverstionHistoryList(filteredEntries);
  };

  const getHistorySessionId = async (sessionId: string) => {
    setConversationSessionId(sessionId);
    setIsGetHistorySessionId(true);
    const matchedResource: Chat | undefined = converstionHistoryList.find((chat: Chat) =>
      chat.resource.identifier.some((id) => id.system === 'http://chatgpt-session-id' && id.value === sessionId)
    );

    if (matchedResource) {
      const payloadData = matchedResource.resource.payload;
      setFhirBotDetails([]);
      const updatedData: FhirBotDetail[] = [];
      let patientID = '',
        patientName = '';

      await getConverstionsHistory();

      if (Array.isArray(payloadData)) {
        payloadData.forEach((payloadItem: PayloadItem) => {
          if (payloadItem.question && payloadItem.answer) {
            const question = payloadItem.question;
            let answer = payloadItem.answer;
            const detectedIntent = payloadItem.detectedIntent;
            const score = payloadItem.score;
            const comment = payloadItem.comment;
            const questionId = payloadItem.id;

            if (detectedIntent === 'RetrievePatientPDFRecords') {
              const parseAnswer = JSON.parse(answer);
              patientID = parseAnswer.patientId;
              patientName = parseAnswer.patientName;
            } else if (detectedIntent === 'NA') {
              const parseAnswer = JSON.parse(answer);
              answer = parseAnswer.question.replace(/_/g, ' ');
              patientID = parseAnswer.patientId;
              patientName = parseAnswer.patientName;
            }

            updatedData.push({
              title: question,
              description: answer,
              loading: false,
              detectedIntent: detectedIntent,
              patientName: patientName,
              selectedPatient: patientID,
              questionId: questionId,
              sessionId: sessionId,
              score: score,
              comment: comment,
            });
          }
        });
      }

      setFhirBotDetails(updatedData);
      if (updatedData.length > 0) {
        setSelectedPatient(`${updatedData[0].selectedPatient}`);
      }

      setTimeout(() => {
        setIsGetHistorySessionId(false);
      }, 1000);
    }
  };

  const getPDFHTMLData = async (e: React.KeyboardEvent | React.MouseEvent, userQuestion?: string) => {
    const isEnterKeyPress = (e as React.KeyboardEvent)?.key === 'Enter';
    const isClickEvent = (e as React.MouseEvent)?.type === 'click';

    if (isEnterKeyPress || isClickEvent) {
      if (!selectedPatient) {
        return;
      }
      if (fhirBotDetailsUpdated.length > 0) {
        setFhirBotDetails(fhirBotDetailsUpdated);
        if (sessionId) {
          setSessionId(sessionId);
        }
      }

      setFhirBotDetails((prevData) => [...prevData, { title: userQuestion || instruction, loading: true }]);
      setInstruction('');
      resetTranscript();
      setLoading(true);

      const sanitizedUserQuestion = userQuestion ? userQuestion.replace(/&/g, 'and') : instruction.replace(/&/g, 'and');

      const response = await getAiAssistantPatientData(
        medplum,
        selectedPatient as string,
        sanitizedUserQuestion,
        sessionId ? sessionId : conversationSessionId
      );

      const data = response?.data?.result;

      if (updateQuestionTitle) {
        await updateChatHistoryTitleAPI()
          .catch((error: Error) => {
            console.error(error);
          })
          .then(() => {
            setUpdateQuestionTitle(false);
            getConverstionsHistory().catch((error) => {
              console.error(error);
            });
          });
      }

      const patientName = patients?.find((patient: Patient) => patient.value === selectedPatient)?.label ?? '';
      setLoading(false);
      setFhirBotDetails((prevData) =>
        prevData.map((item) =>
          item.title === (userQuestion || instruction) && item.loading
            ? {
                ...item,
                description: data,
                loading: false,
                sessionId: response?.data?.sessionId,
                questionId: response?.data?.questionId,
                patientName: patientName,
                selectedPatient: selectedPatient,
                detectedIntent: response?.data?.detectedIntent,
              }
            : item
        )
      );
    }
  };

  const handleInputChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setInstruction(event.target.value);
    resetTranscript();
  };
  const startRecording = async () => {
    audioChunksRef.current = [];
    setInstruction('');
    SpeechRecognition.abortListening();
    await SpeechRecognition.startListening();
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            audioChunksRef.current.push(e.data);
          }
        };
        mediaRecorder.onstop = () => {
          audioChunksRef.current = [];
          if (inputRef.current) {
            inputRef.current.focus();
          }
        };

        audioChunksRef.current = [];
        mediaRecorder.start();
        setRecording(true);
        mediaRecorderRef.current = mediaRecorder;
      })
      .catch((error) => {
        console.error('Error accessing microphone:', error);
      });
  };

  const stopRecording = async () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
      setRecording(false);
      const audioStream = mediaRecorderRef.current.stream;
      audioStream.getTracks()[0].stop();
      await SpeechRecognition.stopListening();
      setInstruction(transcript);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [instruction, transcript]);

  const handleSend: React.MouseEventHandler<HTMLSpanElement> = async (e) => {
    e.preventDefault();
    if (instruction.trim() !== '' || transcript.trim() !== '') {
      const currentInstruction = instruction || transcript;

      const messageWithPatient = `${currentInstruction}`;

      if (aiModel.current === 'chatgpt') {
        getPDFHTMLData(e, messageWithPatient);
      }

      if (recording) {
        await stopRecording();
        resetTranscript();
      }
      setInstruction('');
      resetTranscript();
    }
  };

  return (
    <Box px="lg" pt="sm" className="fhir-bot">
      <Box
        className="aiAssistantBox"
        style={{
          backgroundColor: '#eaf6f745',
        }}
      >
        <Box mb="md" mt={15} ml={10}>
          <AutoCompleteSearch
            setPatients={setPatients}
            setSelectedPatient={setSelectedPatient}
            selectedPatient={selectedPatient || ''}
            patients={patients}
            searchInputRef={searchInputRef}
          />
        </Box>
      </Box>

      <FhirBotDetails
        fhirBotDetails={fhirBotDetails}
        setFhirBotDetails={setFhirBotDetails}
        instruction={instruction}
        loading={loading}
        features={FeatureDescriptions.getFeatures()}
        getPDFHTMLData={getPDFHTMLData}
        selectPatinetID={selectedPatient}
        patientName={patientName}
        resetChatHistory={resetChatHistory}
        converstionHistoryList={converstionHistoryList}
        getHistorySessionId={getHistorySessionId}
        searchInputRef={searchInputRef}
        getConverstionsHistory={getConverstionsHistory}
        isSelectingPatient={isSelectingPatient}
        setSelectedPatient={setSelectedPatient}
      />
      <div
        style={{
          backgroundColor: '#eaf6f791',
          padding: '15px',
          margin: '0px',
          borderRadius: '10px',
        }}
      >
        <Title order={4} mb={2} pt={0} style={{ fontWeight: '600', color: '#101828', fontSize: '18px' }}>
          Instructions
        </Title>
        <div className="instructionsBox">
          <div className="instructionsBox_icon">
            <img src="../img/user.svg" alt="Profile Icon" style={{ height: '20px', width: '20px' }} />
          </div>

          <Textarea
            variant="unstyled"
            className="textareaCustom"
            minRows={1}
            autosize
            placeholder="Write / Record your instruction"
            value={instruction || transcript}
            onChange={handleInputChange}
            ref={inputRef}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                if (!loading) {
                  e.preventDefault();
                  if (instruction.trim() !== '') {
                    getPDFHTMLData(e);
                  }
                }
              }
            }}
          />
          <div className="instuctionBoxInside">
            {!recording ? (
              <ActionIcon variant="subtle" onClick={() => startRecording()}>
                <IconMicrophone size={23} color="#667085" />
              </ActionIcon>
            ) : (
              <div style={{ display: 'flex', gap: '8px' }}>
                <ActionIcon variant="subtle" onClick={() => stopRecording()}>
                  <IconPlayerStopFilled size={23} />
                </ActionIcon>
                <ActionIcon variant="subtle">
                  <Loader size={23} color="black" type="dots" />
                </ActionIcon>{' '}
              </div>
            )}
            <Button
              component="span"
              className="sendButton btn_bg"
              disabled={loading}
              onClick={handleSend}
              style={{ width: loading ? '120px' : '100px' }}
            >
              <img src="../img/send_icon.svg" alt="Send Icon" className="sendIcon" />
              {loading ? 'Sending...' : 'Send'}
            </Button>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default FhirBot;
