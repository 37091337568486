/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Button, Group, AppShell as MantineAppShell, ScrollArea, Space, Text, UnstyledButton } from '@mantine/core';
import { useMedplum, useMedplumNavigate } from '@medplum/react-hooks';
import { IconArrowBadgeLeft, IconArrowBadgeRight, IconChevronDown, IconChevronUp, IconLifebuoy, IconLogout, IconPlus, IconSettings } from '@tabler/icons-react';
import cx from 'clsx';
import { Fragment, MouseEventHandler, ReactNode, SyntheticEvent, useState } from 'react';
import { BookmarkDialog } from '../BookmarkDialog/BookmarkDialog';
import { MedplumLink } from '../MedplumLink/MedplumLink';
import classes from './Navbar.module.css';
import { useAppContext } from '../../../app/src/AppProvider';

export interface NavbarLink {
  readonly icon?: JSX.Element;
  readonly label?: string;
  readonly href: string;
  readonly subLinks?: NavbarLink[];
}

export interface NavbarMenu {
  readonly title?: string;
  readonly links?: NavbarLink[];
}

export interface NavbarProps {
  readonly pathname?: string;
  readonly searchParams?: URLSearchParams;
  readonly menus?: NavbarMenu[];
  readonly closeNavbar: () => void;
  readonly displayAddBookmark?: boolean;
  readonly resourceTypeSearchDisabled?: boolean;
  readonly logo: ReactNode;
  readonly navbar: (props: any) => void;
}

export function Navbar(props: NavbarProps): JSX.Element {
  const navigate = useMedplumNavigate();
  const medplum = useMedplum();
  const { isSessionOn, setIsConfirmOpen, setRedirectionUrl } = useAppContext();
  const activeLink = getActiveLink(location.pathname, props.searchParams, props.menus);
  const [bookmarkDialogVisible, setBookmarkDialogVisible] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState<boolean>(true);
  const [activeSubMenu, setActiveSubMenu] = useState<string | null>(null);
  const [expandedMenus, setExpandedMenus] = useState<Set<string>>(new Set());

  function handleMenuClick(menuTitle: string, hasSubLinks: boolean) {
    if (hasSubLinks) {
      setExpandedMenus((prev) =>
        prev.has(menuTitle) ? new Set([...prev].filter((item) => item !== menuTitle)) : new Set(prev).add(menuTitle)
      );
      setActiveSubMenu(null);
    }
  }

  function onLinkClick(e: SyntheticEvent, to: string, hasSubLinks: boolean): void {
    e.stopPropagation();
    e.preventDefault();

    if (!hasSubLinks || !expandedMenus.has(to)) {
      setActiveSubMenu(null);
    }

    if (!hasSubLinks) {
      setExpandedMenus(new Set());
    }

    if (isSessionOn) {
      console.log('Session is on');
      setRedirectionUrl(`${to}`);
      setIsConfirmOpen(true);
    } else {
      navigate(to);
    }

    if (window.innerWidth < 768) {
      props.closeNavbar();
    }
  }

  function onSubLinkClick(e: SyntheticEvent, href: string): void {
    e.preventDefault();
    e.stopPropagation();
    setActiveSubMenu(href);
    if (isSessionOn) {
      console.log('Session is on');
      setRedirectionUrl(`${href}`);
      setIsConfirmOpen(true);
    } else {
      navigate(href);
    }
  }

  const navbarToggle = () => {
    setNavbarOpen(!navbarOpen);
    props.navbar(!navbarOpen);
  };

  return (
    <>
      <MantineAppShell.Navbar style={{ width: navbarOpen ? '250px' : '70px', transition: navbarOpen ? '' :'all 0.5s ease-in-out'}} className='navbar'>
        <Group gap="xs" mt={20} style={{ padding: `${navbarOpen ? '7px 10px' : '0'}`, justifyContent: `${navbarOpen ? 'space-between' : 'center'}`}}>
          {navbarOpen && (
            <UnstyledButton className={classes.logoButton} onClick={() => navigate('/Dashboard')}>
              <img src="../../img/logo-vertical-white.png" style={{width: '150px'}} alt='healthside Logo' />
            </UnstyledButton>
          )}
          {navbarOpen ? (
            <IconArrowBadgeLeft color='#fff' onClick={navbarToggle} style={{ cursor: 'pointer' }}/>
          ) : (
            <IconArrowBadgeRight color='#fff' onClick={navbarToggle} style={{ cursor: 'pointer' }}/>
          )}
        </Group>
        <ScrollArea pt="xs" pb="xs">
          <MantineAppShell.Section grow pl="xs" pr="xs">
            {props.menus?.map((menu, index) => (
              <Fragment key={index}>
                <Text className={classes.menuTitle}>{menu.title}</Text>
                {menu.links?.map((link, index) => (
                  <div className='navbar-link-container' key={index} 
                    onClick={(e) => {
                      if (link.subLinks && link.subLinks.length > 0) {
                        handleMenuClick(link.href, (link?.subLinks ?? []).length > 0);
                      } else {
                        onLinkClick(e, link.href, !!link.subLinks);
                      }
                    }} 
                    style={{ backgroundColor: link.href === activeLink?.href ? '#00344B' : '' }}
                  >
                    <NavbarLink
                      key={link.href}
                      to={link.href}
                      active={link.href === activeLink?.href}
                      onClick={(e) => {
                        if (link.subLinks && link.subLinks.length > 0) {
                          handleMenuClick(link.href, (link?.subLinks ?? []).length > 0);
                        } else {
                          onLinkClick(e, link.href, !!link.subLinks);
                        }
                      }}
                      nav={navbarOpen}
                    >
                      <NavLinkIcon to={link.href} icon={link.icon} />
                      {navbarOpen && <span>{link.label}</span> }
                      {link.subLinks && link.subLinks.length > 0 && (
                        <span className={classes.movementIcons}>
                          {expandedMenus.has(link.href) ? <IconChevronUp size={15} /> : <IconChevronDown size={15} />}
                        </span>
                      )}
                    </NavbarLink>
                    {link?.subLinks && link.subLinks.length > 0 && expandedMenus.has(link.href) && (
                      <ul className={classes.subMenu}>
                        {link.subLinks.map((subLink, index) => (
                          <li key={`${subLink.href}-${index}`}>
                            <NavbarLink
                              key={link.href}
                              to={subLink.href || '#'}
                              active={link.href === activeSubMenu}
                              onClick={(e) => onSubLinkClick(e, subLink.href)}
                              className={cx({ [classes.subMenuLinkActive]: subLink.href === activeSubMenu })}
                              nav={navbarOpen}
                            >
                              <NavLinkIcon to={subLink.href} icon={subLink.icon} />
                              {navbarOpen && <span>{subLink.label || link.label}</span>}
                            </NavbarLink>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </Fragment>
            ))}
            {props.displayAddBookmark && (
              <Button
                variant="subtle"
                size="xs"
                mt="xl"
                leftSection={<IconPlus size="0.75rem" />}
                onClick={() => setBookmarkDialogVisible(true)}
              >
                Add Bookmark
              </Button>
            )}
          </MantineAppShell.Section>
        </ScrollArea>
        <MantineAppShell.Section pl="xs" pr="xs" style={{ marginTop: '30px'}}>
          <div className='navbar-link-bottom'>
            <NavbarLink
              to='#'
              active={false}
              onClick={() => {}}
              nav={navbarOpen}
            >
              <NavLinkIcon to='#' icon={<IconLifebuoy />} />
              {navbarOpen && <span>Support</span>}
            </NavbarLink>
            <NavbarLink
              to='#'
              active={false}
              onClick={() => {}}
              nav={navbarOpen}
            >
              <NavLinkIcon to='#' icon={<IconSettings />} />
              {navbarOpen && <span>Settings</span> }
            </NavbarLink>
            <hr style={{ borderColor: '#3A5967'}}/>
            <NavbarLink
              to='#'
              active={false}
              onClick={async () => {
                if (isSessionOn) {
                  console.log('Session is on');
                  setRedirectionUrl('/signin');
                  setIsConfirmOpen(true);
                } else {
                  await medplum.signOut();
                  navigate('/signin');
                }
              }}
              nav={navbarOpen}
            >
              <div>
                <NavLinkIcon to='#' icon={<IconLogout />} />
              </div>
              {navbarOpen && (
                <div>
                  <Text size="sm" style={{ color: '#8FA6B0', fontWeight: '600'}}>
                      {medplum.getProfile()?.name?.[0].given?.[0] + ' ' + medplum.getProfile()?.name?.[0].family}
                  </Text>
                  <Text size="sm" style={{ color: '#475467'}}>{medplum.getProfile()?.telecom?.[0].value}</Text>
                </div>
              ) }
            </NavbarLink>
          </div>
        </MantineAppShell.Section>
      </MantineAppShell.Navbar>
      
      {props.pathname && props.searchParams && (
        <BookmarkDialog
          pathname={props.pathname}
          searchParams={props.searchParams}
          visible={bookmarkDialogVisible}
          onOk={() => setBookmarkDialogVisible(false)}
          onCancel={() => setBookmarkDialogVisible(false)}
        />
      )}
    </>
  );
}

interface NavbarLinkProps {
  readonly to: string;
  readonly active: boolean;
  readonly onClick: MouseEventHandler;
  readonly children: ReactNode;
  readonly nav: boolean;
  readonly className?: string;
}

function NavbarLink(props: NavbarLinkProps): JSX.Element {
  return (
    <MedplumLink
      onClick={props.onClick}
      to={props.to}
      className={cx(classes.link, props.className, { [classes.linkActive]: props.active })}
      style={{ display: `${props.nav ? 'flex' : 'block'}`}}
    >
      {props.children}
    </MedplumLink>
  );
}

interface NavLinkIconProps {
  readonly to: string;
  readonly icon?: JSX.Element;
}

function NavLinkIcon(props: NavLinkIconProps): JSX.Element {
  if (props.icon) {
    return props.icon;
  }
  return <Space w={30} />;
}

/**
 * Returns the best "active" link for the menu.
 * In most cases, the navbar links are simple, and an exact match can determine which link is active.
 * However, we ignore some search parameters to support pagination.
 * But we cannot ignore all search parameters, to support separate links based on search filters.
 * So in the end, we use a simple scoring system based on the number of matching query search params.
 * @param currentPathname - The web browser current pathname.
 * @param currentSearchParams - The web browser current search parameters.
 * @param menus - Collection of navbar menus and links.
 * @returns The active link if one is found.
 */
function getActiveLink(
  currentPathname: string | undefined,
  currentSearchParams: URLSearchParams | undefined,
  menus: NavbarMenu[] | undefined
): NavbarLink | undefined {
  if (!currentPathname || !currentSearchParams || !menus) {
    return undefined;
  }

  let bestLink = undefined;
  let bestScore = 0;

  for (const menu of menus) {
    if (menu.links) {
      for (const link of menu.links) {
        const score = getLinkScore(currentPathname, currentSearchParams, link.href);
        if (score > bestScore) {
          bestScore = score;
          bestLink = link;
        }
      }
    }
  }

  return bestLink;
}

/**
 * Calculates a score for a link.
 * Zero means "does not match at all".
 * One means "matches the pathname only".
 * Additional increases for each matching search parameter.
 * Ignores pagination parameters "_count" and "_offset".
 * @param currentPathname - The web browser current pathname.
 * @param currentSearchParams - The web browser current search parameters.
 * @param linkHref - A candidate link href.
 * @returns The link score.
 */
function getLinkScore(currentPathname: string, currentSearchParams: URLSearchParams, linkHref: string): number {
  const linkUrl = new URL(linkHref, 'https://example.com');
  if (currentPathname !== linkUrl.pathname) {
    return 0;
  }
  const ignoredParams = ['_count', '_offset'];
  for (const [key, value] of linkUrl.searchParams.entries()) {
    if (ignoredParams.includes(key)) {
      continue;
    }
    if (currentSearchParams.get(key) !== value) {
      return 0;
    }
  }
  let count = 1;
  for (const [key, value] of currentSearchParams.entries()) {
    if (ignoredParams.includes(key)) {
      continue;
    }
    if (linkUrl.searchParams.get(key) === value) {
      count++;
    }
  }
  return count;
}
