/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Box, Card, Center, Grid, Loader, Text } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import React, { useEffect, useRef, useState } from 'react';
import { exportMedicalRecord, updateAIAssistantChat } from '../../utils/CustomAPI';
import ReactMarkdown from 'react-markdown';
import classes from './FhirBotDetails.module.css';
import remarkGfm from 'remark-gfm';
import ChatHistory from '../AIAssistant/ChatHistory';
import { Chat, PayloadItem, FhirBotDetail } from './interfaces';
import { FeatureDescriptions } from './FeatureDescriptions';
import { useAppContext } from '../../AppProvider';
import { showNotification } from '@mantine/notifications';
import AnimateLoader from '../../components/AnimateLoader';
import ActionButtons from './ActionButtons';
interface Feature {
  icon: React.ReactNode;
  title: string;
}

interface FhirBotProps {
  fhirBotDetails: FhirBotDetail[];
  setFhirBotDetails: (details: FhirBotDetail[]) => void;
  instruction: string;
  loading: boolean;
  features: Feature[];
  getPDFHTMLData: (e: React.KeyboardEvent | React.MouseEvent, userQuestion?: string) => Promise<void>;
  selectPatinetID: string | null;
  patientName: string;
  resetChatHistory: () => Promise<void>;
  converstionHistoryList: Chat[];
  getHistorySessionId: (sessionId: string) => Promise<void>;
  searchInputRef: React.RefObject<HTMLInputElement>;
  getConverstionsHistory: () => Promise<void>;
  isSelectingPatient: boolean;
  setSelectedPatient: React.Dispatch<React.SetStateAction<string | null>>;
}

const FhirBotDetails: React.FC<FhirBotProps> = (props: FhirBotProps) => {
  const bottomRef = useRef<HTMLDivElement>(null);
  const medplum = useMedplum();
  const isDefaultMessage = (description: string): boolean => {
    return description.includes('How can I help you with');
  };
  const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [likedMessages, setLikedMessages] = useState<Set<string>>(new Set());
  const [dislikedMessages, setDislikedMessages] = useState<Set<string>>(new Set());
  const [copiedMessages, setCopiedMessages] = useState<Set<string>>(new Set());
  const [selectedSessionId, setSelectedSessionId] = useState<string | null>(null);
  const [isSessionLoading, setIsSessionLoading] = useState(false);
  const [isNewClicked, setIsNewClicked] = useState(true);
  const [isHistoryClicked, setIsHistoryClicked] = useState(false);
  const [isHistoryData, setHistoryData] = useState(props.fhirBotDetails);
  const { setfhirBotDetailsUpdatedUpdated, setSessionId } = useAppContext();

  useEffect(() => {
    setHistoryData(props.fhirBotDetails);
  }, [setHistoryData, props.fhirBotDetails]);

  const handleNewButtonClick = () => {
    setfhirBotDetailsUpdatedUpdated([]);
    setSessionId('');
    props.resetChatHistory().catch((err) => {
      console.error(err);
    });
    setIsHistoryVisible(false);
    setIsNewClicked(true);
    setIsHistoryClicked(false);
    window.speechSynthesis.cancel();
    setIsSpeaking(false);
  };

  const [showAll, setShowAll] = useState(false);
  const displayedFeatures = showAll
    ? props.features
    : [
        ...props.features.slice(0, 4),
        {
          title: 'More',
          icon: <img src="../img/plus.svg" alt="More" style={{ width: 20, height: 20 }} />,
        },
      ];

  const handleFeatureClick = (e: any, featureTitle: string) => {
    if (featureTitle === 'More') {
      setShowAll(true);
    } else {
      if (!props.selectPatinetID) {
        showNotification({ color: 'red', message: 'Please select a patient' });
        return;
      }
      const description = FeatureDescriptions.getDescription(featureTitle);
      if (description) {
        props.getPDFHTMLData(e, description).catch((err) => {
          console.error('Error fetching PDF HTML data:', err);
        });
      }
    }
  };

  const handleDataFromChild = (data: FhirBotDetail[]) => {
    setHistoryData(data);
    props.setSelectedPatient(data[0].selectedPatient ?? null);
  };

  useEffect(() => {
    if (isHistoryData.length > 1) {
      setIsHistoryVisible(true);
    }
    bottomRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isHistoryData]);

  const setLikeUnlike = (sessionId: string): void => {
    const matchedResource: Chat | undefined = props.converstionHistoryList.find((chat: Chat) =>
      chat.resource.identifier.some((id) => id.system === 'http://chatgpt-session-id' && id.value === sessionId)
    );
    const newSet = new Set<string>();
    const newDislikedSet = new Set<string>();
    matchedResource?.resource.payload.forEach((detail: PayloadItem) => {
      const { id, comment } = detail;
      if (comment === 'Liked') {
        newSet.add(id);
      }
      if (comment === 'Disliked') {
        newDislikedSet.add(id);
      }
    });
    setLikedMessages(newSet);
    setDislikedMessages(newDislikedSet);
  };

  const handleClick = async (item: string, questionId: string): Promise<void> => {
    const contentDiv = document.createElement('div');
    contentDiv.innerHTML = item;

    const tables = contentDiv.querySelectorAll('table');

    let clipboardContent = '';
    const textNodes = Array.from(contentDiv.childNodes)
      .filter(
        (node) => node.nodeType === Node.TEXT_NODE || (node.nodeType === Node.ELEMENT_NODE && node.nodeName !== 'TABLE')
      )
      .map((node) => {
        if (node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'BR') {
          return '\n';
        }
        return node.textContent?.trim();
      })
      .filter((text) => text && text.length > 0);

    clipboardContent += textNodes.join('\n');
    tables.forEach((table, index) => {
      if (index > 0 || textNodes.length > 0) {
        clipboardContent += '\n\n';
      }

      const rows = Array.from(table.querySelectorAll('tr'));
      const processedRows = rows.map((row) => {
        const cells = Array.from(row.querySelectorAll('th, td'));
        return cells?.map((cell) => cell?.textContent?.trim() || '');
      });

      const columnWidths = processedRows[0].map((_, colIndex) =>
        Math.max(...processedRows.map((row) => row[colIndex]?.length))
      );

      const formattedTable = processedRows.map(
        (row) => '| ' + row.map((cell, cellIndex) => cell.padEnd(columnWidths[cellIndex])).join(' | ') + ' |'
      );

      formattedTable.splice(1, 0, '|' + columnWidths.map((width) => '-'.repeat(width + 2)).join('|') + '|');
      clipboardContent += formattedTable.join('\n');
    });

    clipboardContent = clipboardContent.replace(/\*\*/g, '').replace(/#/g, '').trim();

    try {
      await navigator.clipboard.writeText(clipboardContent);
      setCopiedMessages((prev) => new Set(prev).add(questionId));
      setTimeout(() => {
        setCopiedMessages((prev) => {
          const newSet = new Set(prev);
          newSet.delete(questionId);
          return newSet;
        });
      }, 2000);
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  const speakContent = (content: string): void => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(content);
      utterance.onend = () => setIsSpeaking(false);
      utterance.onerror = () => setIsSpeaking(false);
      setIsSpeaking(true);
      window.speechSynthesis.speak(utterance);
    } else {
      console.error('Speech synthesis not supported');
    }
  };

  const handleSpeak = (item: FhirBotDetail): void => {
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      const contentToSpeak = (item.description || '')
        .replace(/\*\*/g, '')
        .replace(/- /g, '')
        .replace(/-/g, ' ')
        .replace(/#/g, '')
        .trim();
      speakContent(contentToSpeak);
    }
  };

  const handleLike = async (questionId: string, sessionId: string, patientId: string): Promise<void> => {
    setLikedMessages((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(questionId)) {
        newSet.delete(questionId);
      } else {
        newSet.add(questionId);
        setDislikedMessages((prevDisliked) => {
          const newDislikedSet = new Set(prevDisliked);
          newDislikedSet.delete(questionId);
          return newDislikedSet;
        });
      }
      return newSet;
    });

    try {
      const payload = {
        questionId,
        sessionId,
        patientId,
        score: '1',
        comment: 'Liked',
      };
      await updateAIAssistantChat(medplum, payload);
      await props?.getConverstionsHistory();
    } catch (error) {
      console.error('Error updating like status:', error);
    }
  };

  const handleDislike = async (questionId: string, sessionId: string, patientId: string): Promise<void> => {
    setDislikedMessages((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(questionId)) {
        newSet.delete(questionId);
      } else {
        newSet.add(questionId);
        setLikedMessages((prevLiked) => {
          const newLikedSet = new Set(prevLiked);
          newLikedSet.delete(questionId);
          return newLikedSet;
        });
      }
      return newSet;
    });

    try {
      const payload = {
        questionId,
        sessionId,
        patientId,
        score: '0',
        comment: 'Disliked',
      };
      await updateAIAssistantChat(medplum, payload);
      await props.getConverstionsHistory();
    } catch (error) {
      console.error('Error updating dislike status:', error);
    }
  };

  const downloadMedicalRecordPdf = async (
    patientName: string,
    patientID: string,
    exportType: string,
    isPII: boolean
  ): Promise<void> => {
    const resourcesToExport: string[] = [];

    try {
      const response = await exportMedicalRecord(
        medplum,
        patientID,
        resourcesToExport.join(','),
        true,
        exportType,
        isPII
      );
      if (response?.data) {
        let fileExtension;
        let mimeType;

        if (exportType === 'PDF') {
          mimeType = 'application/pdf';
          fileExtension = 'pdf';
        }

        const blob = new Blob([response?.data], { type: mimeType });
        const url = window.URL.createObjectURL(blob);
        if (exportType === 'PDF') {
          const a = document.createElement('a');
          a.href = url;
          a.download = `${patientName.replaceAll(' ', '_')}_Medical_Records.${fileExtension}`;
          a.click();
          window.URL.revokeObjectURL(url);
        }
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error('Response is null or does not contain data');
      }
    } catch (error) {
      console.error('Error fetching medical record:', error);
    }
  };
  return (
    <Box px="sm">
      <Grid mb="12px">
        <Grid.Col span={12} style={{ padding: 0 }}>
          <Box
            className={`${classes.flex_between} ${classes.aiAssistantBox}`}
            style={{
              backgroundColor: '#eaf6f791',
            }}
          >
            <div>
              <Text className={classes.aiTitle}>
                AI Assistant
              </Text>

              <Text className={classes.aiDescription}>
                Providing AI-powered insights—always consult your healthcare provider for medical decisions.
              </Text>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {props?.converstionHistoryList?.length >= 0 && (
                <button
                  className={`${classes.new_button} ${isHistoryClicked ? classes.new_button_default : ''}`}
                  style={{
                    backgroundColor: isHistoryClicked ? '#FFFFFF' : 'var(--btn-color)',
                    color: isHistoryClicked ? '#344054' : 'white',
                    width: '80px',
                  }}
                  onClick={handleNewButtonClick}
                >
                  <img
                    src={`../img/${isHistoryClicked ? 'edit_icon_black' : 'edit_icon'}.svg`}
                    alt="Edit Icon"
                    className={classes.edit_icon}
                  />
                  <span className={classes.newIconStyle}>
                    New
                  </span>
                </button>
              )}
              <button
                className={`${classes.history_button} ${
                  isHistoryClicked ? classes.new_button_clicked : classes.new_button_default
                } `}
                style={{
                  backgroundColor: isHistoryClicked ? 'var(--btn-color)' : '#FFFFFF',
                }}
                onClick={() => {
                  setIsHistoryVisible((prevState) => !prevState);
                  setIsHistoryClicked(true);
                  setIsNewClicked(false);
                }}
              >
                <span className={classes.historyIconStyle}>History</span>
                <img
                  src={`../img/${isNewClicked ? 'history_arrow_black' : 'history_arrow_white'}.svg`}
                  alt="History Icon"
                  className={classes.history_icon}
                />
              </button>
            </div>
          </Box>
        </Grid.Col>
        <Grid.Col span={isHistoryVisible ? 9 : 12} className={classes.grid_col_style}>
          <Card className={classes.card} radius="sm" p="sm">
            {isSessionLoading ? (
              <Loader color="blue" type="dots" />
            ) : isHistoryData.length <= 1 ? (
              <>
                <div className={classes.loader}>
                  {props.loading ? (
                    <AnimateLoader />
                  ) : (
                    <div>
                      {isHistoryData && isHistoryData.length > 0 ? (
                        isHistoryData.map((item: FhirBotDetail, index: number) => (
                          <div key={index} className={classes.centered_text_how_can_help}>
                            <span className={classes.how_can_help_loader_text}>
                              How can I help you{' '}
                              <span className={classes.clickableText} style={{ color: 'var(--primary-color)' }}>
                                {item.patientName ? (
                                  <>
                                    {item.patientName.replace(/_/g, ' ')}
                                    <span className={classes.how_can_help_loader_text}>?</span>
                                  </>
                                ) : (
                                  ''
                                )}
                              </span>
                            </span>
                          </div>
                        ))
                      ) : (
                        <div className={classes.centered_text_how_can_help}>
                          <span className={classes.how_can_help_loader_text}>How can I help with?</span>
                        </div>
                      )}

                      <div className={`${classes.grid} ${showAll ? classes.gridExpanded : classes.gridInitial}`}>
                        {displayedFeatures.map((feature) => (
                          <Card
                            key={feature.title}
                            className={classes.featureCard}
                            withBorder
                            onClick={(e) => handleFeatureClick(e, feature.title)}
                          >
                            <div className={classes.cardsContainer}>
                              <div className={classes.cardsInnerBox}>{feature.icon}</div>
                              <Text className={classes.cards_text_style}>{feature.title}</Text>
                            </div>
                          </Card>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                {props.isSelectingPatient && (
                  <Center>
                    <Loader color="blue" type="dots" />
                  </Center>
                )}
                {isHistoryData.map((item: FhirBotDetail, index: number) => (
                  <div className="inner-card" key={index}>
                    {item?.title && item?.detectedIntent !== 'NA' && (
                      <div
                        style={{
                          marginTop: '25px',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Grid>
                          <div>
                            <Text className={classes.flex_container}>{item.title}</Text>
                          </div>
                        </Grid>
                      </div>
                    )}
                    <Grid>
                      <div className="d-flex align-start" style={{ display: 'flex' }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <div className={classes.ai_bot_icon}>
                            <img src="../img/chat_icon.svg" style={{ width: '24px', height: '24px' }} />
                            <div className={classes.green_dot} />
                          </div>
                        </div>
                        <div className={classes.text_margin}>
                          {item?.description ? (
                            item.detectedIntent === 'RetrievePatientPDFRecords' ? (
                              <div className={classes.how_can_respone_text}>
                                <Text>You can download the generated PDF file using the link below.</Text>
                                <div>
                                  <span
                                    onClick={() =>
                                      downloadMedicalRecordPdf(
                                        item.patientName ?? '',
                                        item.selectedPatient ?? '',
                                        'PDF',
                                        true
                                      )
                                    }
                                    className={classes.download_records}
                                  >
                                    {`Download ${
                                      item?.patientName ? item?.patientName?.replaceAll(' ', '_') : 'Patient'
                                    }_Medical_Records.pdf`}
                                  </span>
                                </div>
                              </div>
                            ) : item?.detectedIntent === 'NA' ? (
                              <div>
                                <div className={classes.how_can_respone_text}>
                                  <span className={classes.centered_justified_text}>How can I help you with </span>
                                  <span className={classes.clickable_text} style={{ color: 'var(--primary-color)' }}>
                                    {item.patientName?.replace(/_/g, ' ')}
                                  </span>
                                </div>
                              </div>
                            ) : item.detectedIntent === 'RetrieveClinicalNotes' ? (
                              <div className={classes.how_can_respone_text}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.description,
                                  }}
                                />
                              </div>
                            ) : (
                              <div className={classes.ai_response_text}>
                                <ReactMarkdown children={item.description} remarkPlugins={[remarkGfm]} />
                              </div>
                            )
                          ) : (
                            <AnimateLoader />
                          )}
                        </div>
                      </div>
                    </Grid>
                    {item.description && !isDefaultMessage(item.description) && (
                      <ActionButtons 
                        item={item} 
                        likedMessages={likedMessages} 
                        dislikedMessages={dislikedMessages}
                        copiedMessages={copiedMessages}
                        isSpeaking={isSpeaking}
                        handleLike={handleLike}
                        handleDislike={handleDislike}
                        handleClick={handleClick}
                        handleSpeak={handleSpeak}
                        getPDFHTMLData={props.getPDFHTMLData}
                      />
                    )}
                  </div>
                ))}
                <div ref={bottomRef} />
              </>
            )}
          </Card>
        </Grid.Col>
        {isHistoryVisible && (
          <Grid.Col span={3} mih={'113%'}>
            <ChatHistory
              converstionHistoryList={props.converstionHistoryList}
              selectedSessionId={selectedSessionId}
              setSelectedSessionId={setSelectedSessionId}
              setIsSessionLoading={setIsSessionLoading}
              setLikeUnlike={setLikeUnlike}
              setFhirBotDetails={handleDataFromChild}
              setHistoryData={setHistoryData}
            />
          </Grid.Col>
        )}
      </Grid>
    </Box>
  );
};

export default FhirBotDetails;
