import { Group, Box, Text } from '@mantine/core';

const legendItems = [
  { label: 'Booked', color: '#def6de' },
  { label: 'Available', color: '#b0deea' },
  { label: 'Blocked', color: '#f9e1e3' },
];

export function EventLegend(): JSX.Element {
  return (
    <Group gap="lg">
      {legendItems.map(({ label, color }) => (
        <Group key={label} gap="xs">
          <Box w={10} h={10} style={{ backgroundColor: color, borderRadius: '50%' }} />
          <Text size="xs" c="dimmed">{label}</Text>
        </Group>
      ))}
    </Group>
  );
}