import { Box, Button, Select, Text, TextInput } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useMedplumContext } from '@medplum/react';
import { IconChevronDown } from '@tabler/icons-react';
import React, { useCallback, useEffect, useState } from 'react';
import { createTelehealthSession, fetchPatients } from '../../utils/CustomAPI';

interface InstantAppointmentFormProps {
  meetingType?: string;
  title?: string;
  practitioner?: string;
  patient?: string;
  setIsStarting: React.Dispatch<React.SetStateAction<boolean>>;
  onStartCall: (id: string) => void;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setPatient: React.Dispatch<React.SetStateAction<string | undefined>>;
  isStarting: boolean | undefined;
  closeDialog: () => void;
  patients: { value: string; label: string }[];
  setPatients: React.Dispatch<React.SetStateAction<{ value: string; label: string }[]>>;
}

const defaultQuery = (type: 'PatientList' | 'PractitionerList', searchTerm: string): string => `{
  ${type}(name: "${searchTerm}", _count: 1000) {
    resourceType
    id
    name {
      family
      given
    }
  }
}`;

const InstantAppointmentForm = ({
  meetingType,
  title,
  practitioner,
  patient,
  isStarting,
  setIsStarting,
  onStartCall,
  setTitle,
  setPatient,
  closeDialog,
  patients,
  setPatients,
}: InstantAppointmentFormProps): JSX.Element => {
  const { medplum, profile } = useMedplumContext();
  const practitionerName = medplum.getActiveLogin()?.profile?.display;
  const [sdkType, setSdkType] = useState<string>('liveKit');
  const [patientSearchTerm, setPatientSearchTerm] = useState<string>('');
  const activeUserType = profile?.resourceType;
  const sdkOptions = [
    { value: 'liveKit', label: 'LiveKit', img: '/img/ic-live-kit.svg' },
    { value: 'zoom', label: 'Zoom', img: '/img/ic-zoom.svg' },
  ];
  const icon = <img alt="" src={sdkOptions.find((sdk) => sdk.value === sdkType)?.img} width="20" />;

  // Function to fetch resources (patients or practitioners) based on search term
  const fetchResources = useCallback(
    (
      type: 'PatientList' | 'PractitionerList',
      searchTerm: string,
      setResource: React.Dispatch<React.SetStateAction<{ value: string; label: string }[]>>
    ) => {
      if (searchTerm.length >= 3) {
        fetchPatients(medplum, defaultQuery(type, searchTerm))
          .then(
            (response: { data: { [key: string]: { id: string; name: { given: string[]; family: string }[] }[] } }) => {
              const resourceList = response?.data[type]?.map((resource) => {
                const givenName = resource?.name?.[0]?.given?.join(' ') || '';
                const familyName = resource?.name?.[0]?.family || '';
                return { value: resource.id, label: `${givenName} ${familyName}` };
              });
              setResource(resourceList);
            }
          )
          .catch((error: Error) => console.error(error));
      } else {
        setResource([]);
      }
    },
    [medplum]
  );

  // Effect to fetch patients when search term changes
  useEffect(() => {
    if (patientSearchTerm.length >= 3) {
      fetchResources('PatientList', patientSearchTerm, setPatients);
    }
  }, [patientSearchTerm, fetchResources]);

  // Function to handle starting a call
  const handleStartCall = async (): Promise<void> => {
    if (
      !meetingType ||
      (activeUserType === 'Patient' && !practitioner) ||
      (activeUserType === 'Practitioner' && !patient)
    ) {
      showNotification({
        message: 'Please select the required fields',
        color: 'red',
      });
      return;
    }

    setIsStarting(true);

    const body = {
      meetingType,
      title,
      practitionerId: activeUserType === 'Patient' ? practitioner : profile?.id,
      patientId: activeUserType === 'Practitioner' ? patient : profile?.id,
      sdkType,
    };

    try {
      const response = await createTelehealthSession(medplum, body);
      if (response) {
        if (meetingType === 'instant') {
          onStartCall(response?.appointmentId);
        } else {
          closeDialog();
        }
      }
    } catch (error) {
      setIsStarting(false);
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <Box className="flex-container">
        <Text span w={'30%'} className="title-txt">
          Provider{' '}
          <Text span c={'red'}>
            {' '}
            *
          </Text>
        </Text>
        <TextInput
          placeholder="Enter a title"
          className="meeting-title-input-box"
          disabled
          value={practitionerName}
          styles={(theme) => ({
            label: { marginBottom: theme.spacing.xs },
          })}
          mb={12}
        />
      </Box>
      <Box className="flex-container">
        <Text span w={'30%'} className="title-txt">
          Title (Optional)
        </Text>
        <TextInput
          placeholder="Enter a title"
          className="meeting-title-input-box"
          value={title}
          onChange={(e) => setTitle(e.currentTarget.value)}
          styles={(theme) => ({
            label: { marginBottom: theme.spacing.xs },
          })}
          mb={12}
        />
      </Box>

      {activeUserType === 'Practitioner' && (
        <Box className="flex-container">
          <Text span w={'30%'} className="title-txt">
            Patient
            <Text span c={'red'}>
              {' '}
              *
            </Text>
          </Text>
          <Select
            placeholder="Select"
            className="meeting-title-input-box"
            data={patients}
            value={patient}
            onChange={(value) => setPatient(value ?? '')}
            searchValue={patientSearchTerm}
            onSearchChange={setPatientSearchTerm}
            rightSection={<IconChevronDown size={14} />}
            styles={(theme) => ({
              label: { marginBottom: theme.spacing.xs },
            })}
            mb="12"
            searchable
            required
          />
        </Box>
      )}
      <Box className="flex-container">
        <Text span w={'30%'} className="title-txt">
          Add video call
        </Text>
        <Select
          value={sdkType}
          className="meeting-title-input-box"
          placeholder="Select"
          data={sdkOptions}
          leftSection={icon}
          onChange={(value) => setSdkType(value ?? '')}
          rightSection={<IconChevronDown size={14} />}
          styles={(theme) => ({
            label: { marginBottom: theme.spacing.xs },
          })}
        />
      </Box>
      <Box className="telehealth-create-meeting-btn-container">
        <Button
          size="md"
          onClick={closeDialog}
          bg="white"
          mt={10}
          radius={4}
          variant="default"
          fullWidth
          className="telehealth-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          className="telehealth-start-vedio-btn"
          onClick={handleStartCall}
          radius={4}
          mt={10}
          disabled={isStarting || !meetingType}
          fullWidth
        >
          {isStarting ? <Text>Starting call...</Text> : 'Start video call'}
        </Button>
      </Box>
    </div>
  );
};

export default InstantAppointmentForm;
