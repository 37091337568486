import { Badge, Group, AppShell as MantineAppShell, Menu, UnstyledButton } from '@mantine/core';
import { IconBell, IconSettings, IconUser } from '@tabler/icons-react';
import cx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import classes from '../../../react/src/AppShell/Header.module.css';
import { HeaderDropdown } from '../../../react/src/AppShell/HeaderDropdown';
import HeaderDropdownNotification from './HeaderDropdownNotification';
import { useAppContext } from '../AppProvider';
import { useMedplum } from '@medplum/react-hooks';
import { getNotifications } from '../utils/CustomAPI';
import { Notification } from '../utils/interface';
import CommonDrawer from './CommonDrawer';

export function Header(): JSX.Element {
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const [notificationMenuOpened, setNotficationOpened] = useState(false);
  const {
    setNotifications,
    unreadNotifications,
    setUnreadNotifications,
    setTotalNotifications,
    selectedAppointment,
    isJoinCallDrawerOpen,
    setIsJoinCallDrawerOpen,
  } = useAppContext();
  const [loading, setLoading] = useState(false);
  const medplum = useMedplum();

  const fetchNotifications = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await getNotifications(medplum);
      setNotifications(response?.notifications || []);
      setTotalNotifications(response?.total || 0);
      let unread = 0;

      response?.notifications.slice(0, 4).forEach((notification: Notification) => {
        if (notification?.status !== 'R') {
          unread++;
        }
      });

      setUnreadNotifications(unread);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setLoading(false);
    }
  }, [medplum, setNotifications]);

  useEffect(() => {
    const interval = setInterval(fetchNotifications, 5 * 60 * 1000);

    fetchNotifications().catch((error) => {
      console.error('Error fetching notifications:', error);
    });

    return () => clearInterval(interval);
  }, [fetchNotifications]);

  return (
    <>
      {isJoinCallDrawerOpen && selectedAppointment && (
        <CommonDrawer
          type="JoinCall"
          title="Join The Call"
          subTitle={`Appointment details with ${selectedAppointment.participant?.[0]?.actor?.display || 'Patient'}`}
          opened={isJoinCallDrawerOpen}
          onClose={() => setIsJoinCallDrawerOpen(false)}
          appointment={selectedAppointment}
        />
      )}
      <MantineAppShell.Header p={8} mt={10} style={{ border: 'none', position: 'relative' }}>
        <Group justify="end">
          <Group gap="lg" pr="sm">
            <div className="tw-flex tw-gap-4">
              <button
                type="button"
                className="tw-relative tw-inline-flex tw-items-center tw-p-1 tw-text-sm tw-font-medium tw-text-center tw-text-white tw-rounded-lg"
              >
                <IconSettings color="#667085" />
              </button>

              <Menu
                width={400}
                shadow="xl"
                position="bottom-end"
                transitionProps={{ transition: 'pop-top-right' }}
                opened={notificationMenuOpened}
                onClose={() => setNotficationOpened(false)}
                radius={8}
              >
                <Menu.Target>
                  <UnstyledButton onClick={() => setNotficationOpened((o) => !o)}>
                    {unreadNotifications > 0 && (
                      <Badge color="red" size="sm" pos={'absolute'} top={7} right={74}>
                        {unreadNotifications}
                      </Badge>
                    )}
                    <IconBell color="#667085" />
                  </UnstyledButton>
                </Menu.Target>
                <Menu.Dropdown>
                  <HeaderDropdownNotification
                    onClose={() => setNotficationOpened(false)}
                    fetchNotifications={fetchNotifications}
                    loading={loading}
                  />
                </Menu.Dropdown>
              </Menu>
            </div>
            <Menu
              width={200}
              shadow="xl"
              position="bottom-end"
              transitionProps={{ transition: 'pop-top-right' }}
              opened={userMenuOpened}
              onClose={() => setUserMenuOpened(false)}
            >
              <Menu.Target>
                <UnstyledButton
                  className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                  onClick={() => setUserMenuOpened((o) => !o)}
                  style={{ backgroundColor: '#EAF6F7', borderRadius: '50%', padding: '10px' }}
                >
                  <Group gap={7}>
                    {/* <Avatar src="" alt="profile" m="auto" size="md"/> */}
                    <IconUser size={23} color="#4DB5BA" />
                  </Group>
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown>
                <HeaderDropdown version="" />
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Group>
      </MantineAppShell.Header>
    </>
  );
}
