import { Box, Button, Divider, Group, Paper, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { getReferenceString } from '@medplum/core';
import { Appointment, Practitioner, Schedule, Slot } from '@medplum/fhirtypes';
import { useMedplum, useMedplumProfile } from '@medplum/react';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { Calendar, dayjsLocalizer, Event, EventPropGetter } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useNavigate } from 'react-router-dom';
import { BlockAvailability } from '../components/actions/BlockAvailability';
import { CreateUpdateSlot } from '../components/actions/CreateUpdateSlot';
import { SetAvailability } from '../components/actions/SetAvailability';
import { SlotDetails } from '../components/SlotDetails';
import { IconCalendarEvent } from '@tabler/icons-react';
import { getCurrentWeek } from '../../utils/util';
import './Calendar.css';
import { CustomToolbar } from '../components/CustomToolbar';
import { TelehealthDrawer } from '../../dashboard/Telehealth/TelehealthDrawer';

/**
 * Schedule page that displays the practitioner's schedule.
 * Allows the practitioner to set availability, block availability, create/update slots, and create
 * appointments.
 * @returns A React component that displays the schedule page.
 */
export function SchedulePage(): JSX.Element {
  const navigate = useNavigate();
  const medplum = useMedplum();

  const [blockAvailabilityOpened, blockAvailabilityHandlers] = useDisclosure(false);
  const [setAvailabilityOpened, setAvailabilityHandlers] = useDisclosure(false);
  const [slotDetailsOpened, slotDetailsHandlers] = useDisclosure(false);
  const [createUpdateSlotOpened, createUpdateSlotHandlers] = useDisclosure(false);
  const [createAppointmentOpened, createAppointmentHandlers] = useDisclosure(false);

  const [selectedEvent, setSelectedEvent] = useState<Event>();
  const scheduleId = window.location.pathname.split('/')[2];
  const [schedule, setSchedule] = useState<Schedule>();
  const profile = useMedplumProfile() as Practitioner;

  const [shouldRefreshCalender, setShouldRefreshCalender] = useState(false);
  const [slots, setSlots] = useState<Slot[]>();
  const [appointments, setAppointments] = useState<Appointment[]>();
  const [dateRange, setDateRange] = useState<{ start: Date; end: Date }>(getCurrentWeek());
  const [currentView, setCurrentView] = useState('week');

  // First load the schedule
  useEffect(() => {
    if (scheduleId) {
      medplum
        .readResource('Schedule', scheduleId)
        .then((loadedSchedule) => {
          setSchedule(loadedSchedule);
          setShouldRefreshCalender(true);
        })
        .catch((error) => {
          console.error('Error loading schedule:', error);
        });
    }
  }, [medplum, scheduleId]);

  const eventPropGetter: EventPropGetter<Event> = (event) => {
    const newStyle = {
      backgroundColor: '#b0deea',
      border: '1px solid #b0deea',
      color: 'black',
      hover: '#D0EEF1',
    };

    if (event.resource.tooltip === 'Blocked') {
      newStyle.backgroundColor = '#f9e1e3';
      newStyle.border = '1px solid #f9e1e3';
      newStyle.color = 'black';
    }

    if (event.resource.tooltip === 'Booked') {
      newStyle.backgroundColor = '#def6de';
      newStyle.border = '1px solid #def6de';
      newStyle.color = 'black';
    }

    return {
      className: '',
      style: newStyle,
    };
  };

  // After schedule is loaded, handle data fetching
  useEffect(() => {
    async function searchSlots(): Promise<void> {
      if (!schedule) {
        return;
      }

      const slots = await medplum.searchResources(
        'Slot',
        {
          schedule: `Schedule/${scheduleId}`,
          start: `ge${dateRange.start.toISOString()}`,
          _count: '100',
        },
        { cache: 'no-cache' }
      );
      setSlots(slots);
    }

    async function searchAppointments(): Promise<void> {
      if (!profile) {
        return;
      }

      const appointments = await medplum.searchResources(
        'Appointment',
        {
          actor: getReferenceString(profile),
          start: `ge${dateRange.start.toISOString()}`,
        },
        { cache: 'no-cache' }
      );
      setAppointments(appointments);
    }

    if (shouldRefreshCalender) {
      Promise.allSettled([searchSlots(), searchAppointments()])
        .then(() => setShouldRefreshCalender(false))
        .catch(console.error);
    }
  }, [medplum, schedule, profile, shouldRefreshCalender, dateRange]);

  // Converts Slot resources to big-calendar Event objects
  // Only show free and busy-unavailable slots
  const slotEvents: Event[] = (slots ?? [])
    .filter((slot) => slot.status === 'free' || slot.status === 'busy-unavailable' || slot.status === 'busy')
    .map((slot) => {
      let tooltip = ''; // Tooltip content
      let title = '';

      if (slot.status === 'busy') {
        tooltip = title = 'Booked';
      } else if (slot.status === 'busy-unavailable') {
        tooltip = title = 'Blocked';
      } else if (slot.status === 'free') {
        tooltip = title = 'Available';
      }

      return {
        title: currentView === 'agenda' ? title : '',
        start: new Date(slot.start),
        end: new Date(slot.end),
        resource: { ...slot, tooltip }, // Store tooltip data
      };
    });

  // Converts Appointment resources to big-calendar Event objects
  // Exclude cancelled appointments to prevent them from overlapping free slots during rendering
  const appointmentEvents: Event[] = (appointments ?? [])
    .filter((appointment) => appointment.status !== 'cancelled')
    .map((appointment) => {
      // Find the patient among the participants to use as title
      const patientParticipant = appointment?.participant?.find((p) => p.actor?.reference?.startsWith('Patient/'));
      const status = !['booked', 'arrived', 'fulfilled'].includes(appointment.status as string)
        ? ` (${appointment.status})`
        : '';

      const tooltip = `${patientParticipant?.actor?.display} ${status}`;

      return {
        title: currentView === 'agenda' ? tooltip : '',
        start: new Date(appointment.start as string),
        end: new Date(appointment.end as string),
        resource: {
          ...appointment,
          tooltip,
        },
      };
    });

  /**
   * When a date/time range is selected, set the event object and open the create slot modal
   */
  const handleSelectSlot = useCallback(
    (event: Event & { action?: string }) => {
      if (event.action === 'select' || event.action === 'doubleClick') {
        setSelectedEvent(event);
        createUpdateSlotHandlers.open();
      }
    },
    [createUpdateSlotHandlers]
  );

  /**
   * When an existing event (slot/appointment) is selected, set the event object and open the
   * appropriate modal.
   * - If the event is a free slot, open the create appointment modal.
   * - If the event is a busy-unavailable slot, open the slot details modal.
   * - If the event is an appointment, navigate to the appointment page.
   */
  const handleSelectEvent = useCallback(
    (event: Event) => {
      const { resourceType, status, id } = event.resource;

      function handleSlot(): void {
        setSelectedEvent(event);
        if (status === 'free') {
          createAppointmentHandlers.open();
        } else {
          slotDetailsHandlers.open();
        }
      }

      function handleAppointment(): void {
        navigate(`/Appointment/${id}`);
      }

      if (resourceType === 'Slot') {
        handleSlot();
        return;
      }

      if (resourceType === 'Appointment') {
        handleAppointment();
      }
    },
    [slotDetailsHandlers, createAppointmentHandlers, navigate]
  );

  const handleRangeChange = useCallback((range: Date[] | { start: Date; end: Date }) => {
    if (Array.isArray(range)) {
      const start = new Date(range[0]);
      const end = new Date(range[range.length - 1]);
      console.log(start, end);
      setDateRange({ start, end });
    } else {
      const start = new Date(range.start);
      const end = new Date(range.end);
      console.log(start, end);
      setDateRange({ start, end });
    }
    setShouldRefreshCalender(true);
  }, []);

  const handleStartCall = (appointmentId: string): void => {
    if (!appointmentId) {
      console.error('Appointment ID not found');
      return;
    }
    createAppointmentHandlers.close();
    navigate(`/Telehealth/${appointmentId}/meeting`);
  };

  return (
    <Paper radius={'12px'} className="page_container" p={'lg'} mt={'3%'} mih={'100vh'}>
      <Box display={'flex'} mb={'lg'}>
        <Group ml={'lg'} display={'flex'}>
          <IconCalendarEvent color={'var(--btn-color)'} size={'40'} />
          <Box>
            <Text style={{ fontWeight: 'bold', fontSize: '18px' }}>My Schedule</Text>
          </Box>
        </Group>
        <Group ml={'auto'} mr={'lg'}>
          <Button size="sm" radius="8px" color={'var(--btn-color)'} onClick={() => setAvailabilityHandlers.open()}>
            Set Availability
          </Button>
          <Button
            size="sm"
            radius="8px"
            variant="outline"
            color={'var(--btn-color)'}
            onClick={() => blockAvailabilityHandlers.open()}
          >
            Block Availability
          </Button>
        </Group>
      </Box>
      <Divider mb={'lg'} />
      <Box>
        <Paper radius={'12px'}>
          <Calendar
            defaultView="week"
            views={['month', 'week', 'day', 'agenda']}
            localizer={dayjsLocalizer(dayjs)}
            events={[...appointmentEvents, ...slotEvents]} // Combine appointmentEvents and slotEvents
            onSelectSlot={handleSelectSlot}
            onSelectEvent={handleSelectEvent}
            onRangeChange={handleRangeChange}
            tooltipAccessor={(event) => event.resource?.tooltip || ''}
            onView={(view) => setCurrentView(view)}
            scrollToTime={new Date()} // Default scroll to current time
            selectable
            eventPropGetter={eventPropGetter}
            style={{ height: '85rem', minHeight: '100vh' }}
            components={{
              toolbar: CustomToolbar,
            }}
            className={currentView === 'week' ? 'week-view-active' : ''}
          />
        </Paper>
      </Box>
      {/* {/ Modals /} */}
      <SetAvailability
        opened={setAvailabilityOpened}
        handlers={setAvailabilityHandlers}
        schedule={schedule as Schedule}
      />
      <BlockAvailability
        opened={blockAvailabilityOpened}
        handlers={blockAvailabilityHandlers}
        schedule={schedule as Schedule}
      />
      <CreateUpdateSlot
        event={selectedEvent}
        opened={createUpdateSlotOpened}
        handlers={createUpdateSlotHandlers}
        onSlotsUpdated={() => setShouldRefreshCalender(true)}
        schedule={schedule as Schedule}
      />
      <SlotDetails
        event={selectedEvent}
        opened={slotDetailsOpened}
        handlers={slotDetailsHandlers}
        onSlotsUpdated={() => setShouldRefreshCalender(true)}
        schedule={schedule as Schedule}
      />
      {/* <CreateAppointment handlers={createAppointmentHandlers} schedule={schedule as Schedule} /> */}

      <TelehealthDrawer
        event={selectedEvent}
        isOpen={createAppointmentOpened}
        onClose={() => createAppointmentHandlers.close()}
        onSlotsUpdated={() => setShouldRefreshCalender(true)}
        onStartCall={(value: string) => handleStartCall(value)}
      />
    </Paper>
  );
}
