/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect, useContext, useState, useCallback, useReducer, useMemo } from 'react';
import ZoomVideo, { ConnectionState, Participant, ReconnectReason } from '@zoom/videosdk';
import { Loading, useMedplumContext, useMedplumNavigate } from '@medplum/react';
import produce from 'immer';
import { showNotification } from '@mantine/notifications';
import ZoomMediaContext from '../context/media-context';
import ZoomContext from '../context/zoom-context';
import { Box, Group, Image, Text } from '@mantine/core';
import VideoSingle from './video-single';
import { LiveTranscriptionClient, MediaStream, ZoomClient } from '../index-types';
import { IconCheckbox, IconCopy, IconMinimize } from '@tabler/icons-react';
import { Appointment } from '@medplum/fhirtypes';
import { getConfig } from '../../config';
import { useAppContext } from '../../AppProvider';
import ConfirmationModal from '../../components/ConfirmationModal';
import { submitTranscription } from '../../utils/CustomAPI';
import { useParams } from 'react-router-dom';

// Define your context
interface ZoomMeetingProps {
  meetingArgs: {
    topic: string;
    signature: string;
    name: string;
    password?: string;
    webEndpoint?: string;
    enforceGalleryView?: string;
    enforceVB?: string;
    customerJoinId?: string;
    lang?: string;
    useVideoPlayer?: string;
    setIsRightSectionVisible?: (visible: boolean) => void;
    isRightSectionVisible?: boolean;
  };
  setTranscription: (transcription: string) => void;
  appointment?: Appointment;
  setClinicalNotes: (notes: string) => void;
  participants: Participant[];
  setParticipants: (participants: Participant[]) => void;
  setDocumentId?: (documentId: string) => void;
  isEndingSession: boolean;
  setIsEndingSession: (isEnding: boolean) => void;
  setIsSessionEnded: (isSessionEnded: boolean) => void;
  transcription?: string;
}

// Media shape and reducer for managing media state
const mediaShape = {
  audio: { encode: false, decode: false },
  video: { encode: false, decode: false },
  share: { encode: false, decode: false },
};

const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case 'audio-encode':
      draft.audio.encode = action.payload;
      break;
    case 'audio-decode':
      draft.audio.decode = action.payload;
      break;
    case 'video-encode':
      draft.video.encode = action.payload;
      break;
    case 'video-decode':
      draft.video.decode = action.payload;
      break;
    case 'share-encode':
      draft.share.encode = action.payload;
      break;
    case 'share-decode':
      draft.share.decode = action.payload;
      break;
    case 'reset-media':
      Object.assign(draft, { ...mediaShape });
      break;
    default:
      break;
  }
}, mediaShape);

declare global {
  interface Window {
    webEndpoint: string | undefined;
    zmClient: ZoomClient | undefined;
    mediaStream: MediaStream | undefined;
    crossOriginIsolated: boolean;
    ltClient: LiveTranscriptionClient | undefined;
    logClient: ZoomClient | undefined;
  }
}

function ZoomMeeting(props: ZoomMeetingProps): JSX.Element {
  const {
    meetingArgs: {
      topic,
      signature,
      name,
      webEndpoint: webEndpointArg,
      enforceGalleryView,
      enforceVB,
      // useVideoPlayer,
      setIsRightSectionVisible,
      isRightSectionVisible,
    },
    setTranscription,
    appointment,
    setClinicalNotes,
    participants,
    setParticipants,
    setDocumentId,
    isEndingSession,
    setIsEndingSession,
    setIsSessionEnded,
    transcription,
  } = props;
  const { medplum } = useMedplumContext();
  const { id: appointmentId } = useParams<{ id: string }>();
  const navigate = useMedplumNavigate();
  const { setIsSessionOn, isSessionOn, isConfirmOpen, setIsConfirmOpen, redirectionUrl, setRedirectionUrl } =
    useAppContext();
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('');
  const [isFailover, setIsFailover] = useState(false);
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  const zmClient = useContext(ZoomContext);
  const webEndpoint = webEndpointArg || 'zoom.us';

  const mediaContext = useMemo(() => ({ ...mediaState, mediaStream }), [mediaState, mediaStream]);
  const galleryViewWithoutSAB = Number(enforceGalleryView) === 1 && !window.crossOriginIsolated;
  const vbWithoutSAB = Number(enforceVB) === 1 && !window.crossOriginIsolated;
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [participantCount, setParticipantCount] = useState(0);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const init = async (): Promise<void> => {
      try {
        await zmClient.init('en-US', `Global`, {
          webEndpoint,
          enforceMultipleVideos: galleryViewWithoutSAB,
          enforceVirtualBackground: vbWithoutSAB,
          stayAwake: true,
          patchJsMedia: true,
          leaveOnPageUnload: false,
        });

        // setLoadingText('Joining the session...');
        await zmClient.join(topic, signature, name);
        const stream: any = zmClient.getMediaStream();
        setMediaStream(stream);

        // **Start Video & Audio Automatically**
        setTimeout(async () => {
          const startVideoOptions = {
            hd: true,
            fullHd: true,
            ptz: mediaStream?.isBrowserSupportPTZ(),
            originalRatio: true,
          };
          
            await stream.startVideo(startVideoOptions);
            await stream.startAudio({ highBitrate: true });
        }, 1000); // **Delay for 1 second**

      } catch (error: any) {
        showNotification({
          title: 'Error',
          message: `Failed to join meeting: ${error.reason || error}`,
          color: 'red',
        });
        console.error('Failed to join meeting:', error);
      } finally {
        setLoading(false);
      }
    };
    init().catch((error) => {
      console.error('Initialization failed:', error);
    });

    return () => {
      ZoomVideo.destroyClient();
    };
  }, [signature]);

  useEffect(() => {
    console.log(isSessionOn);
    const handleBeforeUnload = (event: any) => {
      if (isSessionOn) {
        const message = 'Are you sure you want to leave? Your changes may not be saved.';
        event.preventDefault();
        event.returnValue = message;
        return message;
      }
    };
    if (isSessionOn) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isSessionOn]);

  const onConnectionChange = useCallback(
    (payload: any) => {
      if (payload.state === ConnectionState.Connected) {
        setIsSessionOn(true);
      }
      switch (payload.state) {
        case ConnectionState.Reconnecting:
          setLoading(true);
          setIsFailover(true);
          setLoadingText(
            payload.reason === ReconnectReason.Failover
              ? 'Session Disconnected, Try to reconnect'
              : `Joining ${payload.subsessionName}...`
          );
          break;
        case ConnectionState.Connected:
          setLoading(false);
          window.zmClient = zmClient;
          window.mediaStream = zmClient.getMediaStream();
          console.log('getSessionInfo', zmClient.getSessionInfo());
          break;
        case ConnectionState.Closed:
          dispatch({ type: 'reset-media' });
          if (payload.reason === 'ended by host') {
            showNotification({
              title: 'Meeting ended',
              message: 'This meeting has been ended by the host',
              color: 'red',
            });
          }
          break;
        default:
          break;
      }
    },
    [isFailover, zmClient]
  );

  const onMediaSDKChange = useCallback((payload: any) => {
    const { action, type, result } = payload;
    dispatch({ type: `${type}-${action}`, payload: result === 'success' });
  }, []);

  useEffect(() => {
    zmClient.on('connection-change', onConnectionChange);
    zmClient.on('media-sdk-change', onMediaSDKChange);
    return () => {
      zmClient.off('connection-change', onConnectionChange);
      zmClient.off('media-sdk-change', onMediaSDKChange);
    };
  }, [zmClient, onConnectionChange, onMediaSDKChange]);

  const toggleFullScreen = useCallback(async () => {
    if (!document.fullscreenElement) {
      await document.documentElement.requestFullscreen();
      setIsFullScreen(true);
    } else {
      await document.exitFullscreen();
      setIsFullScreen(false);
    }
  }, []);

  const getPatientJoinUrl = (): string => {
    const config = getConfig();
    const baseUrl = config.baseUrl;

    if (appointment) {
      // Step 1: Get the value from the identifier
      const valueString = appointment?.identifier?.[0].value;

      // Step 2: Parse the JSON string
      const valueObject = JSON.parse(valueString || '{}');

      // Step 3: Extract the patient_join_url
      const patientJoinUrl = baseUrl + valueObject.patient_join_url;
      return patientJoinUrl;
    }

    return '';
  };

  const onConfirm = async () => {
    const body = {
      appointmentId: appointmentId,
      transcript: transcription ?? '',
    };
    submitTranscription(medplum, body)
      .then(() => {
        console.log('Transcription submitted');
      })
      .catch((error) => {
        console.error('Failed to submit transcription:', error);
      });
    setIsSessionOn(false);
    setIsConfirmOpen(false);
    if (redirectionUrl === '/signin') {
      await medplum.signOut();
    }
    navigate(redirectionUrl);
  };
  console.log(transcription);
  return (
    <Box>
      {loading ? (
        <Group>
          <Loading /> {loadingText}
        </Group>
      ) : (
        <>
          <Box
            bg="#F9F5FF"
            py={20}
            style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
            className="flex-between"
          >
            <Box className="zoom-meeting-header-btn">
              <Image width={18} height={18} mr={9} src={'../../img/icons/person-icon.svg'}></Image>
              <Text className="zoom-meeting-header-btn-txt flex-center">{participantCount} Persons</Text>
            </Box>
            {!isFullScreen ? (
              <Image
                width={20}
                height={20}
                mr={20}
                src={'../../img/icons/maximize-icon.svg'}
                title="Maximize"
                style={{ cursor: 'pointer' }}
                onClick={toggleFullScreen}
              ></Image>
            ) : (
              <IconMinimize style={{ marginRight: '25px' }} size={25} color="#667085" onClick={toggleFullScreen} />
            )}
          </Box>
          <ZoomMediaContext.Provider value={mediaContext && mediaContext}>
            <VideoSingle
              setIsFullScreen={setIsFullScreen}
              toggleFullScreen={toggleFullScreen}
              isFullScreen={isFullScreen}
              setTranscription={setTranscription}
              setParticipantCount={setParticipantCount}
              setClinicalNotes={setClinicalNotes}
              participants={participants}
              setParticipants={setParticipants}
              setDocumentId={setDocumentId}
              isEndingSession={isEndingSession}
              setIsEndingSession={setIsEndingSession}
              setIsSessionEnded={setIsSessionEnded}
              transcription={transcription}
            />
          </ZoomMediaContext.Provider>
          <Box bg="#F9F5FF" py={20} style={{ borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px' }}>
            <Box pl={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Text className="zoom-meeting-txt">Call Started</Text>
              <Box className="flex-center">
                <Box
                  className="zoom-meeting-button btn_bg"
                  style={{ cursor: 'pointer' }}
                  onClick={async () => {
                    await navigator.clipboard.writeText(getPatientJoinUrl() || '');
                    setCopied(true);
                    setTimeout(() => setCopied(false), 2000);
                  }}
                >
                  {copied ? <IconCheckbox color="white" size="18px" /> : <IconCopy color="white" size="18px" />}
                  <Text className="zoom-meeting-text">Copy Meeting Link</Text>
                </Box>
                <Image
                  width={24}
                  height={24}
                  mr={24}
                  src={isRightSectionVisible ? '../../img/icons/hide-button.svg' : '../../img/icons/import.svg'}
                  title={isRightSectionVisible ? 'Close Sidebar' : 'Open Side Bar'}
                  onClick={() => setIsRightSectionVisible && setIsRightSectionVisible(!isRightSectionVisible)}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Box>
          </Box>
          {isConfirmOpen && (
            <ConfirmationModal
              setIsConfirmationModalOpen={() => {
                setRedirectionUrl('');
                setIsConfirmOpen(false);
              }}
              onConfirm={onConfirm}
              isConfirmationModalOpen={isConfirmOpen}
              content={'This action will discard unsaved changes. Do you wish to proceed?'}
            />
          )}
        </>
      )}
    </Box>
  );
}

export default ZoomMeeting;
