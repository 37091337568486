/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { Box, Button, Divider, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../utils/util';
import { Appointment } from '@medplum/fhirtypes';
import { useAppContext } from '../../AppProvider';

interface JoinCallProps {
  appointment: Appointment;
}

const JoinCall = ({ appointment }: JoinCallProps) => {
  const navigate = useNavigate();
    const {
      setIsJoinCallDrawerOpen,
    } = useAppContext();
  const providerName = appointment.participant?.find(
    (participant: any) =>
      participant.actor?.reference?.includes('Practitioner') || participant.actor?.reference?.includes('Parctitioner')
  )?.actor?.display;

  const patientLabel = appointment.participant?.find((participant: any) =>
    participant.actor?.reference?.includes('Patient')
  )?.actor?.display;

  return (
    <Box px="md" py="lg">
      <Box display="flex" >
        <Box display="flex" w={'50%'} style={{ flexDirection: 'column', gap: '2rem' }}>
          <Text fw={500} size="sm" c="#000000">
            Provider
          </Text>
          <Text fw={500} size="sm" c="#000000">
            Patient Name
          </Text>
          <Text fw={500} size="sm" c="#000000">
            Appointment Date & Time
          </Text>
          <Text fw={500} size="sm" c="#000000">
            Duration
          </Text>
        </Box>
        <Box display="flex" w={'50%'} style={{ flexDirection: 'column', gap: '2rem' }}>
          <Text fw={400} size="sm" c="#344054">
            {providerName || '-'}
          </Text>
          <Text fw={400} size="sm" c="#344054">
            {patientLabel || 'Not available'}
          </Text>
          <Text fw={400} size="sm" c="#344054">
            {formatDate(new Date(appointment?.start as string), 'MMM dd yyyy hh:mm a')}
          </Text>
          <Text fw={400} size="sm" c="#344054">
            {appointment?.minutesDuration ? `${appointment.minutesDuration} min` : '-'}
          </Text>
        </Box>
      </Box>

      <Divider my="md" />

      <Box display="flex" style={{ gap: '16px', justifyContent: 'flex-end' }}>
        <Button
          variant="default"
          bg={'white'}
          radius={'8px'}
          size="md"
          onClick={() => setIsJoinCallDrawerOpen(false)}
          className="telehealth-cancel-btn"
        >
          Cancel
        </Button>

        <Button
          variant="filled"
          color="var(--btn-color)"
          radius={'8px'}
          size="md"
          w={'40%'}
          onClick={() => navigate(`/Telehealth/${appointment.id}/meeting`)}
        >
          Join Video Call
        </Button>
      </Box>
    </Box>
  );
};

export default JoinCall;
